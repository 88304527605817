import { CARD_EFFECT_TYPES } from 'game/consts/types/card-effect.js';

import { BaseCardEffect } from '../base.js';

export class DrawCardsCardEffect extends BaseCardEffect {
  constructor(params) {
    super(params);

    this.type = CARD_EFFECT_TYPES.DRAW_CARDS;
  }

  execute({ kingdom, card }) {
    const { cards } = card.getStats();
    kingdom.orderDrawingCards(cards);
  }
}
