import { TOOLTIP } from 'game/consts/tooltip.js';
import { CARD_EFFECT_TYPES } from 'game/consts/types/card-effect.js';

import { BaseCardEffect } from '../base.js';

export class IncreaseOwnSpreadCardEffect extends BaseCardEffect {
  constructor(params) {
    super(params);

    this.type = CARD_EFFECT_TYPES.INCREASE_OWN_SPREAD;
  }

  getTooltipTypes() {
    return [TOOLTIP.TYPES.SPREAD];
  }

  execute({ card }) {
    const { spreadIncrease } = card.getStats();
    card.increaseStat('spread', spreadIncrease);
  }
}
