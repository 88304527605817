import { uniqueString } from 'game/helpers/data.js';

export const TOOLTIP = {
  CATEGORIES: {
    EFFECT: uniqueString(),
    BUILDING: uniqueString(),
    CARD: uniqueString(),
  },
  TYPES: {
    POISON: uniqueString(),
    VOID: uniqueString(),
    LIGHTWEIGHT: uniqueString(),
    POWER_STATION: uniqueString(),
    DRAIN: uniqueString(),
    SPREAD: uniqueString(),
    SNIPER: uniqueString(),
    FORTIFICATIONS: uniqueString(),
    BASIC_CANNON: uniqueString(),
    SNIPER_CANNON: uniqueString(),
    FORGE: uniqueString(),
    MINE: uniqueString(),
    RANDOM_CANNON: uniqueString(),
    POWER: uniqueString(),
    PIERCING: uniqueString(),
    DEFENDER: uniqueString(),
    SHORT_RANGE_CANNON: uniqueString(),
    FRAGILE: uniqueString(),
    REINFORCEMENT: uniqueString(),
    WEAKEST_FIELD: uniqueString(),
    INACCURACY: uniqueString(),
    PERSISTENT: uniqueString(),
    CANNONS: uniqueString(),
    ACTIVATE: uniqueString(),
    WAREHOUSE: uniqueString(),
    MULTISHOT: uniqueString(),
    REFILL: uniqueString(),
  },
};
