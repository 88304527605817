import { CARD_EFFECT_TYPES } from 'game/consts/types/card-effect.js';

import { BaseCardEffect } from '../base.js';

export class TrashCardCardEffect extends BaseCardEffect {
  constructor(params) {
    super(params);

    this.type = CARD_EFFECT_TYPES.TRASH_CARD;
  }

  execute = async ({ targets }) => {
    const targetCard = this.game.entitiesController.findById(targets.cardId);
    targetCard.orderTrashing();
  };

  pickTarget({ kingdom }) {
    return kingdom.orderPickingHandCardToTrash();
  }

  canBePlayed({ kingdom, card }) {
    const cards = kingdom.getHand();

    const persistentCards = cards.filter((filteredCard) => filteredCard.getId() !== card.getId());

    if (persistentCards.length === 0) {
      return this.getCant('No cards to pick!');
    }

    return this.getCan();
  }
}
