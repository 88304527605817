import { TOOLTIP } from 'game/consts/tooltip.js';
import { CARD_EFFECT_TYPES } from 'game/consts/types/card-effect.js';
import { KINGDOM_EFFECT_TYPES } from 'game/consts/types/kingdom-effect.js';

import { BaseCardEffect } from '../base.js';

export class VoidToOthersCardEffect extends BaseCardEffect {
  constructor(params) {
    super(params);

    this.type = CARD_EFFECT_TYPES.VOID_TO_OTHERS;
  }

  getTooltipTypes() {
    return [TOOLTIP.TYPES.VOID];
  }
  execute({ kingdom, card }) {
    const { voidPower } = card.getStats();

    const enemyKingdoms = this.game.kingdomsController.getOtherActiveThan(kingdom);
    enemyKingdoms.forEach((kingdom) => {
      kingdom.addEffect({ type: KINGDOM_EFFECT_TYPES.VOID, power: voidPower });
    });
  }
}
