import { IMAGES } from 'game/consts/types/images.js';
import { Z_INDEX } from 'game/consts/z-index.js';
import { between } from 'game/helpers/converters.js';
import { InterfaceElement } from 'game/interface/elements/interface-element.js';
import { withLeftClick } from 'game/utils/with-left-click.js';
import * as PIXI from 'pixi.js';

const EDGE_WIDTH = 10;
const HANDLE_HEIGHT = 25;
const SLIDING_HANDLE_MARGIN = 10;

export class Slider extends InterfaceElement {
  constructor(params) {
    super(params);

    this.value = params.startingValue || 0;
    this.onChange = params.onChange;

    this.width = params.width || 300;
    this.height = HANDLE_HEIGHT;
    this.barColor = params.barColor || 'red';
    this.handleColor = params.handleColor || 'darkred';
    this.horizontalMargins = params.horizontalMargins || 10;

    this.workingWidth = this.width - this.horizontalMargins * 2;
    this.slidingWidth = this.workingWidth - SLIDING_HANDLE_MARGIN * 2;

    this.isSliding = false;

    this.createSprites();
    this.updateInterface();
    this.setVisibility(params.visible || false);
  }

  createSprites() {
    this.createContainer();
    this.createBar();
    this.createCancelOverlay();
  }

  createBar() {
    this.createBarStart();
    this.createFilledBarPart();
    this.createEmptyBarPart();
    this.createHandle();
    this.createBarEnd();
  }

  createBarStart() {
    this.sprites.barStart = this.game.texturesManager.createSprite(IMAGES.INTERFACE.SLIDER.EDGE);
    this.sprites.barStart.anchor.set(0, 0.5);

    this.sprites.barStart.x = -this.workingWidth / 2;

    this.sprites.barStart.tint = this.barColor;

    this.sprites.container.addChild(this.sprites.barStart);
  }

  createFilledBarPart() {
    this.sprites.filledBar = this.game.texturesManager.createTillingSprite(IMAGES.INTERFACE.SLIDER.FILLING, 0, 20);

    this.sprites.filledBar.anchor.set(0, 0.5);

    this.sprites.filledBar.x = -this.workingWidth / 2 + EDGE_WIDTH;

    this.sprites.filledBar.tint = this.barColor;

    this.sprites.container.addChild(this.sprites.filledBar);
  }

  createEmptyBarPart() {
    this.sprites.emptyBar = this.game.texturesManager.createTillingSprite(IMAGES.INTERFACE.SLIDER.FILLING, 0, 20);
    this.sprites.emptyBar.anchor.set(1, 0.5);

    this.sprites.emptyBar.x = this.workingWidth / 2 - EDGE_WIDTH;

    this.sprites.container.addChild(this.sprites.emptyBar);
  }

  createHandle() {
    this.sprites.handle = this.game.texturesManager.createSprite(IMAGES.INTERFACE.SLIDER.HANDLE);
    this.sprites.handle.anchor.set(0.5);

    this.sprites.handle.x = this.workingWidth * (this.value - 0.5);

    this.sprites.handle.zIndex = 1; // just over the bars - local context

    this.sprites.handle.tint = this.handleColor;

    this.sprites.handle.eventMode = 'static';
    this.sprites.handle.cursor = 'pointer';

    this.sprites.handle.on('pointerdown', this.onMouseDownOnHandle);

    this.sprites.container.addChild(this.sprites.handle);
  }

  createBarEnd() {
    this.sprites.barEnd = this.game.texturesManager.createSprite(IMAGES.INTERFACE.SLIDER.EDGE);
    this.sprites.barEnd.anchor.set(0, 0.5);

    this.sprites.barEnd.x = this.workingWidth / 2;

    this.sprites.barEnd.angle = 180;

    this.sprites.container.addChild(this.sprites.barEnd);
  }

  createCancelOverlay() {
    this.sprites.cancelOverlay = new PIXI.Sprite();
    this.sprites.cancelOverlay.eventMode = 'static';
    this.sprites.cancelOverlay.cursor = 'pointer';
    this.sprites.cancelOverlay.visible = false;
    this.sprites.cancelOverlay.zIndex = Z_INDEX.CANCEL_OVERLAY;

    this.sprites.cancelOverlay.on('pointerup', withLeftClick(this.onCancelOverlayMouseUp));
    this.sprites.cancelOverlay.on('pointerout', withLeftClick(this.onCancelOverlayMouseOut));

    this.updateCancelOverlay();

    this.registerSprite(this.sprites.cancelOverlay);
  }

  updateCancelOverlay() {
    this.sprites.cancelOverlay.hitArea = new PIXI.Rectangle(0, 0, window.innerWidth, window.innerHeight);
  }

  onCancelOverlayMouseOut = () => {
    this.cancelSetting();
  };

  onCancelOverlayMouseUp = () => {
    this.cancelSetting();
  };

  setValue(value) {
    this.value = value;
    this.updateInterface();
  }

  updateInterface() {
    this.sprites.handle.x = this.slidingWidth * (this.value - 0.5);
    this.sprites.filledBar.width = this.value * this.workingWidth - EDGE_WIDTH;
    this.sprites.emptyBar.width = (1 - this.value) * this.workingWidth - EDGE_WIDTH;
  }

  onMouseDownOnHandle = () => {
    this.sprites.cancelOverlay.visible = true;
    this.game.eventsController.addListener('mouseMove', this);
    this.isSliding = true;
  };

  cancelSetting() {
    if (!this.isSliding) return false;

    this.isSliding = false;

    this.sprites.cancelOverlay.visible = false;
    this.game.eventsController.removeListener('mouseMove', this);

    if (this.onChange) this.onChange(this.value);
  }

  onMouseMove = (params) => {
    const { position } = params;

    const offset = position.x - this.sprites.container.x + this.slidingWidth / 2;
    const value = between(0, offset / this.slidingWidth, 1);

    this.setValue(value);
  };

  afterResize() {
    this.updateCancelOverlay();
  }
}
