import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BackButton } from 'components/common/back-button';
import { Button } from 'components/common/button';
import { CentredContainer } from 'components/common/centred-container';
import { LoadingOverview } from 'components/common/loading-overview';
import { Title } from 'components/common/title';

import { openView } from 'store/temporary/slice';

import { PreparationsList } from './preparations-list';

import styles from './styles.module.css';

export function PreparationsView() {
  const dispatch = useDispatch();

  const connection = useSelector((state) => state.websocket.connection);

  const [preparations, setPreparations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const onGetList = (response) => {
    setIsLoading(false);
    setPreparations(response.preparations);
  };

  const onCreatePreparation = (response) => {
    const { preparation } = response;
    setPreparations([preparation, ...preparations]);
  };

  const onUpdatePreparation = (response) => {
    const { preparation } = response;
    const { id } = preparation;

    const index = preparations.findIndex((searchedPreparation) => searchedPreparation.id === id);

    if (index < 0) return;

    const newPreparations = [...preparations];

    newPreparations[index] = preparation;

    setPreparations(newPreparations);
  };

  const onDeletePreparation = (response) => {
    const { id } = response;

    const newPreparations = preparations.filter((preparation) => preparation.id !== id);

    setPreparations(newPreparations);
  };

  useEffect(() => {
    connection.emit('game:subscribeToPreparations');
    connection.emit('game:getPreparationsList', onGetList);

    return () => {
      connection.emit('game:unsubscribeToPreparations');
    };
  }, []);

  useEffect(() => {
    connection.on('server:createPreparation', onCreatePreparation);
    connection.on('server:updatePreparation', onUpdatePreparation);
    connection.on('server:deletePreparation', onDeletePreparation);

    return () => {
      connection.off('server:createPreparation', onCreatePreparation);
      connection.off('server:updatePreparation', onUpdatePreparation);
      connection.off('server:deletePreparation', onDeletePreparation);
    };
  }, [preparations]);

  const onCreateClick = () => {
    dispatch(openView({ viewName: 'preparation-creator' }));
  };

  return (
    <CentredContainer>
      {isLoading && <LoadingOverview />}
      {!isLoading && (
        <>
          <Title>List of matches</Title>

          <div className={styles.container}>
            <div className={styles.buttonsContainer}>
              <Button onClick={onCreateClick}>Create game</Button>
              <BackButton />
            </div>
            <PreparationsList preparations={preparations} />
          </div>
        </>
      )}
    </CentredContainer>
  );
}
