import { Z_INDEX } from 'game/consts/z-index.js';
import { Box } from 'game/interface/elements/box.js';
import { Button } from 'game/interface/elements/button.js';
import { Overlay } from 'game/interface/elements/overlay.js';
import { Text } from 'game/interface/elements/text.js';
import { CentredInterfaceGroup } from 'game/interface/groups/centred-interface-group.js';
import { TEXT_TYPES } from 'game/languages/text-types.js';

const BUTTON_TOP_MARGIN = 40;
const GAP = 5;
const PADDING = 10;

const TITLE_SIZE = 40;

const WIDTH = 450;

export class PauseMenu extends CentredInterfaceGroup {
  constructor(params) {
    super(params);

    this.width = WIDTH;

    this.gap = GAP;
    this.padding = PADDING;

    this.createSubElements();
    this.setTexts();
  }

  toggle() {
    this.visible ? this.close() : this.show();
  }

  open() {
    this.setElementsPositions();
    this.setBoxSize();
    this.setVisibility(true);
  }

  close() {
    this.setVisibility(false);
  }

  createSubElements() {
    this.registerElement(
      'overlay',
      new Overlay({
        game: this.game,
        zIndex: Z_INDEX.MESSAGE_BOX,
      })
    );

    this.registerElement(
      'box',
      new Box({
        game: this.game,
        zIndex: Z_INDEX.MESSAGE_BOX,
      })
    );

    this.registerElement(
      'title',
      new Text({
        game: this.game,
        zIndex: Z_INDEX.MESSAGE_BOX,
        width: this.width,
        fontSize: TITLE_SIZE,
      })
    );

    this.registerElement(
      'resumeButton',
      new Button({
        callback: this.onResume,
        game: this.game,
        zIndex: Z_INDEX.MESSAGE_BOX,
        marginTop: BUTTON_TOP_MARGIN,
      })
    );

    this.registerElement(
      'settingsButton',
      new Button({
        callback: this.onSettings,
        game: this.game,
        zIndex: Z_INDEX.MESSAGE_BOX,
      })
    );

    this.registerElement(
      'quitButton',
      new Button({
        callback: this.onQuit,
        game: this.game,
        zIndex: Z_INDEX.MESSAGE_BOX,
      })
    );
  }

  setTexts() {
    this.elements.title.setText(this.game.textsController.getInterfaceText(TEXT_TYPES.PAUSE));
    this.elements.resumeButton.setText(this.game.textsController.getInterfaceText(TEXT_TYPES.RESUME));
    this.elements.settingsButton.setText(this.game.textsController.getInterfaceText(TEXT_TYPES.SETTINGS));
    this.elements.quitButton.setText(this.game.textsController.getInterfaceText(TEXT_TYPES.QUIT));
  }

  setBoxSize() {
    this.elements.box.setSize(this.width, this.height);
  }

  onResume = () => {
    this.close();
    this.game.interfaceController.unpauseGame();
  };

  onSettings = () => {
    this.close();
    this.game.interfaceController.settingsMenu.open();
  };

  onQuit = () => {
    setTimeout(this.game.destroy, 1);
  };

  afterResize() {
    if (!this.visible) return false;

    this.setElementsPositions();
  }

  regenerateTexts() {
    this.setTexts();
  }
}
