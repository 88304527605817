import { IMAGES } from 'game/consts/types/images.js';
import { Z_INDEX } from 'game/consts/z-index.js';

import { HexButtonSprite } from './hex.js';

export class HeadquarterButton extends HexButtonSprite {
  constructor(params) {
    super({ ...params, iconType: IMAGES.INTERFACE.BUTTONS.ICONS.HEADQUARTER, zIndex: Z_INDEX.UTILITY_BUTTONS });

    this.setupSprites(params);
  }

  onClick = () => {
    this.game.eventsController.runEvent('focusOnMainHeadquarter');
  };

  getTintColor() {
    return this.game.kingdomsController.getMainKingdom().getColor();
  }

  updatePosition = () => {
    const x = window.innerWidth - this.getWidth();
    const y = window.innerHeight / 2 + this.getHeight() * 0.6;

    this.setPosition(x, y);
  };
}
