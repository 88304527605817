import { BASE_CANNONS_INACCURACY } from 'game/consts/structures.js';
import { TOOLTIP } from 'game/consts/tooltip.js';
import { CARD_EFFECT_TYPES } from 'game/consts/types/card-effect.js';

import { BaseCardEffect } from '../base.js';

export class ShotCardEffect extends BaseCardEffect {
  constructor(params) {
    super(params);

    this.type = CARD_EFFECT_TYPES.SHOT;
  }

  getDescription(stats) {
    return this.game.textsController.getCardEffectDescription(this.type, this.defaultizeStats(stats));
  }

  getTooltipTypes(stats) {
    const { spread, power, piercing, inaccuracy } = this.defaultizeStats(stats);

    const tooltipTypes = [];

    if (power > 1) tooltipTypes.push(TOOLTIP.TYPES.POWER);
    if (spread > 1) tooltipTypes.push(TOOLTIP.TYPES.SPREAD);
    if (piercing > 1) tooltipTypes.push(TOOLTIP.TYPES.PIERCING);
    if (inaccuracy === 0) tooltipTypes.push(TOOLTIP.TYPES.SNIPER);
    if (inaccuracy !== 0 && inaccuracy !== BASE_CANNONS_INACCURACY) tooltipTypes.push(TOOLTIP.TYPES.INACCURACY);

    return tooltipTypes;
  }

  execute({ kingdom, card, targets }) {
    const { spread, power, piercing, inaccuracy, shots } = this.defaultizeStats(card.getStats());

    const field = this.game.entitiesController.findById(targets.fieldId);
    kingdom.getHeadquarter().fireBulletsTask({ shots, inaccuracy, piercing, power, spread, targets: [field.getPosition()] });
  }

  pickTarget({ kingdom }) {
    return kingdom.orderPickingShotTargetField();
  }

  defaultizeStats(stats) {
    const { spread = 1, power = 1, piercing = 1, inaccuracy = BASE_CANNONS_INACCURACY, shots = 1 } = stats;
    return { ...stats, spread, power, piercing, inaccuracy, shots };
  }
}
