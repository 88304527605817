import { SOUNDS } from 'game/consts/sounds.js';
import { ENTITIES } from 'game/consts/types/entities.js';
import { IMAGES } from 'game/consts/types/images.js';
import { STRUCTURES } from 'game/consts/types/structures.js';
import { Z_INDEX } from 'game/consts/z-index.js';
import { perFrameTimer } from 'game/helpers/converters.js';
import { withLeftClick } from 'game/utils/with-left-click.js';
import * as PIXI from 'pixi.js';

import { InterfaceBase } from '../interface-base.js';

const SOUND_DELAY = perFrameTimer(0.05);

export class FieldSelector extends InterfaceBase {
  constructor(params) {
    super(params);

    this.kingdom = params.kingdom;
    this.parent = params.parent;

    this.active = false;
    this.seletorVisible = false;
    this.isCorrectField = true;

    this.currentField = null;

    this.conditions = {};

    this.soundTimer = 0;

    this.createSprite();
    this.updateSpritesPosition();
  }

  createSprite() {
    this.selectionSprite = this.game.texturesManager.createStandardSprite(IMAGES.ENTITIES.FIELD_SELECTOR);
    this.selectionSprite.x = 0;
    this.selectionSprite.y = 0;

    this.selectionSprite.zIndex = Z_INDEX.FIELD_SELECTOR;
    this.selectionSprite.visible = false;

    this.registerMapInterfaceSprite(this.selectionSprite);

    this.selectingOverlay = new PIXI.Container();
    this.selectingOverlay.hitArea = new PIXI.Rectangle(0, 0, window.innerWidth, window.innerHeight);
    this.selectingOverlay.eventMode = 'static';
    this.selectingOverlay.zIndex = Z_INDEX.SELECTION_OVERLAY;
    this.selectingOverlay.visible = false;
    this.selectingOverlay.on('pointerdown', withLeftClick(this.onOverlayClick));
    this.selectingOverlay.on('rightdown', this.mouseRightDown);

    this.registerSprite(this.selectingOverlay);
  }

  updateSpritesPosition() {
    if (!this.currentField) return false;
    const position = this.currentField.getPosition();

    this.selectionSprite.x = position.x;
    this.selectionSprite.y = position.y;
  }

  onOverlayClick = () => {
    if (!this.active) return false;
    if (!this.isCorrectField) return false;

    this.parent.onFieldSelect({ fieldId: this.currentField.id });
  };

  mouseRightDown = () => {
    this.game.eventsController.runEvent('redirectedMouseRightDown');
  };

  destroy() {
    super.destroy();
    this.removeMapInterfaceSprite(this.selectionSprite);
    this.removeSprite(this.selectingOverlay);
  }

  activate(delta) {
    this.moveToMouseHex();
    this.changeCursorForOverlay();
    this.checkSpriteChange();
    this.calculateSoundTimer(delta);
  }

  calculateSoundTimer(delta) {
    if (this.soundTimer <= 0) return;

    this.soundTimer -= delta;

    if (this.soundTimer > 0) return;

    this.playSound();
  }

  playSound() {
    this.game.soundsController.playSound(SOUNDS.SELECTING_FIELD);
  }

  playDelayedSound() {
    this.soundTimer = SOUND_DELAY;
  }

  moveToMouseHex() {
    if (!this.active) return false;

    const mouseMapPosition = this.game.inputsController.getMouseMapPosition().clone();
    const hexPosition = mouseMapPosition.toHex();

    const field = this.game.mapController.getFieldByHex(hexPosition);

    if (!field || field.isType(ENTITIES.WALL)) {
      this.currentField = null;
      this.setSelectorVisibility(false);
      return false;
    }

    if (this.currentField && this.currentField.id === field.id) return false;

    this.currentField = field;

    this.updateSpritesPosition();
    this.setSelectorVisibility(true);
    this.setOverlayVisibility(true);

    this.playDelayedSound();
  }

  changeCursorForOverlay() {
    if (!this.active) return false;

    const correctSelection = this.currentField && this.isCorrectField;

    this.selectingOverlay.cursor = correctSelection ? 'pointer' : 'default';
  }

  setOverlayVisibility(value) {
    this.selectingOverlay.visible = value;
  }

  setSelectorVisibility(value) {
    if (this.seletorVisible === value) return;

    this.seletorVisible = value;

    this.selectionSprite.visible = value;
  }

  setCorrectSprite() {
    this.selectionSprite.tint = this.isCorrectField ? 0xffffff : 0xff0000;
  }

  checkSpriteChange() {
    const isCorrectField = this.checkFieldIfCorrect(this.currentField);

    if (this.isCorrectField === isCorrectField) return false;

    this.isCorrectField = isCorrectField;

    this.setCorrectSprite();
  }

  checkFieldIfCorrect(field) {
    const { own, empty, withBuilding, withoutHeadquarter } = this.conditions;

    if (!this.currentField) return false;
    if (own && !field.ownedBy(this.kingdom)) return false;
    if (empty && !field.empty()) return false;
    if (withBuilding && field.empty()) return false;
    if (withoutHeadquarter && field.structure && field.structure.type === STRUCTURES.HEADQUARTER) return false;

    return true;
  }

  setActive(value) {
    this.active = value;
  }

  pickField = (conditions = {}) => {
    this.pickedField = null;

    this.conditions = conditions;

    this.setActive(true);
    this.setOverlayVisibility(true);
  };

  hide() {
    this.setActive(false);
    this.setSelectorVisibility(false);
    this.setOverlayVisibility(false);
  }

  afterResize = () => {
    this.selectingOverlay.hitArea = new PIXI.Rectangle(0, 0, window.innerWidth, window.innerHeight);
  };
}
