import { InterfaceElement } from 'game/interface/elements/interface-element.js';
import * as PIXI from 'pixi.js';

export class Box extends InterfaceElement {
  constructor(params) {
    super(params);

    this.height = params.height || 300;
    this.width = params.width || 400;
    this.borderWidth = params.borderWidth || 5;
    this.borderRadius = params.borderRadius || 0;
    this.opacity = params.opacity || 1;
    this.padding = params.padding || 0;

    this.centredPositioning = true;

    this.createSprites();
    this.setVisibility(params.visible || false);
  }

  setSize(width, height) {
    this.width = width;
    this.height = height;

    this.updateBox();
  }

  createSprites() {
    this.createContainer();
    this.createBox();
  }

  createBox() {
    this.sprites.box = new PIXI.Graphics();

    this.updateBox();

    this.sprites.container.addChild(this.sprites.box);
  }

  updateBox() {
    this.sprites.box
      .clear()
      .roundRect(
        -this.width / 2 - this.padding,
        -this.height / 2 - this.padding,
        this.width + this.padding * 2,
        this.height + this.padding * 2,
        this.borderRadius
      )
      .fill({ color: 0xaaaaaa, alpha: this.opacity })
      .stroke({ width: this.borderWidth, color: 0x000000 });
  }
}
