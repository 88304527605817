import { RESOURCES_BOTTOM, RESOURCES_GAP, RESOURCES_LEFT, RESOURCE_ICON_SIZE, RESOURCE_TEXT_SIZE } from 'game/consts/layout.js';
import { IMAGES } from 'game/consts/types/images.js';
import { Z_INDEX } from 'game/consts/z-index.js';
import { Point } from 'game/data-types/point.js';
import { CanvasTextMetrics, Container, Text, TextStyle } from 'pixi.js';

import { InterfaceBase } from '../interface-base.js';

const SCALE = 0.4;
const POSITION_OFFSET = RESOURCE_ICON_SIZE + RESOURCES_GAP;

const primaryStyles = new TextStyle({
  fontFamily: 'Arial',
  fontSize: RESOURCE_TEXT_SIZE,
  fill: 0xffffff,
  dropShadow: true,
  dropShadowBlur: 6,
  dropShadowDistance: 0,
  lineJoin: 'round',
});

const secondaryStyles = new TextStyle({
  fontFamily: 'Arial',
  fontSize: RESOURCE_TEXT_SIZE * 0.75,
  fill: 0xffffff,
  fontStyle: 'italic',
  dropShadow: true,
  dropShadowBlur: 6,
  dropShadowDistance: 0,
});

export class Resources extends InterfaceBase {
  constructor(params = {}) {
    super(params);

    this.kingdom = params.kingdom;

    this.createSprites();

    this.updatePosition();

    this.updateEnergyTexts();
  }

  createSprites() {
    this.createSpritesContainer();

    this.createEnergySprites();
  }

  createSpritesContainer() {
    this.spritesContainer = new Container();
    this.spritesContainer.zIndex = Z_INDEX.RESOURCES;

    this.registerSprite(this.spritesContainer);
  }

  createEnergySprites() {
    this.energyIconSprite = this.game.texturesManager.createSprite(IMAGES.ICONS.ENERGY);
    this.energyIconSprite.anchor.set(0, 0.5);
    this.energyIconSprite.x = 0;
    this.energyIconSprite.y = POSITION_OFFSET * 1.5;
    this.energyIconSprite.scale.set(SCALE);

    this.energyQuantityText = new Text({ text: '', style: primaryStyles });
    this.energyQuantityText.x = RESOURCES_LEFT + RESOURCE_ICON_SIZE;
    this.energyQuantityText.y = POSITION_OFFSET * 1.5;
    this.energyQuantityText.anchor.set(0, 0.5);

    this.energyProdutionText = new Text({ text: '', style: secondaryStyles });
    this.energyProdutionText.x = 0;
    this.energyProdutionText.y = POSITION_OFFSET * 1.5;
    this.energyProdutionText.anchor.set(0, 0.5);

    this.spritesContainer.addChild(this.energyIconSprite);
    this.spritesContainer.addChild(this.energyQuantityText);
    this.spritesContainer.addChild(this.energyProdutionText);
  }

  updatePosition() {
    this.position = new Point(RESOURCES_LEFT, window.innerHeight - RESOURCES_BOTTOM - RESOURCE_ICON_SIZE * 2 - RESOURCES_GAP * 1.5);
    this.spritesContainer.x = this.position.x;
    this.spritesContainer.y = this.position.y;
  }

  onEnergyChange() {
    this.updateEnergyTexts();
  }

  updateEnergyTexts() {
    const energy = this.kingdom.getEnergy();
    const production = this.kingdom.getEnergyProduction();

    this.energyQuantityText.text = energy;
    this.energyProdutionText.text = ` + ${production}`;
    this.energyProdutionText.visible = production > 0;
    this.energyProdutionText.x =
      RESOURCES_LEFT + RESOURCE_ICON_SIZE + RESOURCES_GAP / 2 + CanvasTextMetrics.measureText(energy.toString(), primaryStyles).width;
  }

  afterResize() {
    this.updatePosition();
  }

  destroy() {
    super.destroy();
    this.removeSprite(this.spritesContainer);
  }
}
