import { IMAGES } from 'game/consts/types/images.js';
import { STRUCTURES } from 'game/consts/types/structures.js';

import { BaseBuilding } from '../base.js';

export class WarehouseBuilding extends BaseBuilding {
  constructor(params) {
    super(params);

    this.type = STRUCTURES.WAREHOUSE;
  }

  getSpriteName() {
    return IMAGES.STRUCTURES.WAREHOUSE;
  }
}
