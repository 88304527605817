import { Z_INDEX } from 'game/consts/z-index.js';
import { perFrameTimer } from 'game/helpers/converters.js';
import { normalizeFraction } from 'game/helpers/number.js';
import { InterfaceBase } from 'game/interface/interface-base.js';
import * as PIXI from 'pixi.js';

const FADE_OUT_TIME = perFrameTimer(0.5);

export class InfoText extends InterfaceBase {
  constructor(params) {
    super(params);

    this.size = params.size;
    this.zIndex = params.zIndex || Z_INDEX.INFO_TEXT;

    this.createSprites();

    this.showingTimer = 0;
    this.fadeOutTimer = 0;

    this.setPosition();
  }

  showMessage(message, time = 1) {
    this.sprites.message.text = message;

    this.showingTimer = time ? perFrameTimer(time) : 0;
    this.fadeOutTimer = 0;

    this.sprites.container.visible = true;
    this.setOpacity(1);
  }

  fadeOutMessage() {
    this.fadeOutTimer = FADE_OUT_TIME;
  }

  show() {
    this.showingTimer = 0;
    this.fadeOutTimer = 0;
    this.sprites.container.visible = true;
    this.setOpacity(1);
  }

  hide() {
    this.showingTimer = 0;
    this.fadeOutTimer = 0;
    this.sprites.container.visible = false;
  }

  setPosition() {
    this.sprites.container.x = 0;
    this.sprites.container.y = 0;
  }

  createSprites() {
    this.sprites.container = new PIXI.Container();

    this.sprites.message = new PIXI.Text({
      text: '',
      style: {
        fontFamily: 'Arial',
        fontSize: this.size,
        fill: 0xffffff,
        dropShadow: true,
        dropShadowBlur: 6,
        dropShadowDistance: 0,
      },
    });
    this.sprites.message.x = 0;
    this.sprites.message.y = 0;
    this.sprites.message.anchor.set(0.5);

    this.sprites.container.addChild(this.sprites.message);

    this.sprites.container.visible = false;
    this.sprites.container.zIndex = this.zIndex;

    this.registerSprite(this.sprites.container);
  }

  activate(delta) {
    this.calculateFadeOutTimer(delta);
    this.calculateShowingTimer(delta);
  }

  calculateFadeOutTimer(delta) {
    if (this.fadeOutTimer <= 0) return;

    this.fadeOutTimer -= delta;

    this.setOpacity(normalizeFraction(this.fadeOutTimer / FADE_OUT_TIME));

    if (this.fadeOutTimer > 0) return;

    this.hide();
  }

  calculateShowingTimer(delta) {
    if (this.showingTimer <= 0) return;

    this.showingTimer -= delta;

    if (this.showingTimer > 0) return;

    this.fadeOutMessage();
  }

  setOpacity(opacity) {
    this.sprites.container.alpha = opacity;
  }

  afterResize() {
    this.setPosition();
  }
}
