import { uniqueString } from 'game/helpers/data.js';

export const TEXT_TYPES = {
  CANCEL: uniqueString(),
  PICK_FIELD: uniqueString(),
  PICK_CARD: uniqueString(),
  CHOOSE_NEW_TARGET: uniqueString(),
  OVER_HARD_CARD_LIMIT: uniqueString(),
  YOU_WON: uniqueString(),
  WELL_DONE: uniqueString(),
  GAME_OVER: uniqueString(),
  ELIMINATED: uniqueString(),
  WATCH: uniqueString(),
  QUIT: uniqueString(),
  PAUSE: uniqueString(),
  RESUME: uniqueString(),
  SETTINGS: uniqueString(),
  SOUND_VOLUME: uniqueString(),
  BACK: uniqueString(),
  LANGUAGE: uniqueString(),
};
