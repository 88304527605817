import { ACTIVE_BOTTOM, ACTIVE_HEIGHT, CARD_WIDTH, HAND_MARGINS, HIDDEN_HAND_OFFSET, MARGIN } from 'game/consts/layout.js';
import { Z_INDEX } from 'game/consts/z-index.js';
import { Point } from 'game/data-types/point.js';

import { InterfaceBase } from '../interface-base.js';

export class Active extends InterfaceBase {
  constructor(params) {
    super(params);

    this.kingdom = params.kingdom;
    this.parent = params.parent;

    this.updatePosition();
  }

  updatePosition() {
    const hiddenMode = this.parent.getFocusedOnBoard();

    const hiddenOffset = hiddenMode ? HIDDEN_HAND_OFFSET : 0;

    this.position = new Point(window.innerWidth / 2, window.innerHeight - ACTIVE_BOTTOM - ACTIVE_HEIGHT / 2 + hiddenOffset);
  }

  onFocusedOnBoardChange = () => {
    this.updatePosition();
    this.recalculateCardsPositions(this.kingdom.getActive());
  };

  onChange() {
    const cards = this.kingdom.getActive();

    this.updateCardsInterfaces(cards);
    this.recalculateCardsPositions(cards);
    this.recalculateCardsZIndexes(cards);
  }

  updateCardsInterfaces(cards) {
    cards.forEach((card) => {
      card.setContainer(this);
      card.setInteractive(false);
    });
  }

  recalculateCardsPositions(cards, params = {}) {
    const cardsQuantity = cards.length;

    if (cardsQuantity < 1) return false;

    const lastIndex = cardsQuantity - 1;

    let width = window.innerWidth - HAND_MARGINS;
    width = Math.min(width, cardsQuantity * CARD_WIDTH + lastIndex * (MARGIN / 3));
    width -= CARD_WIDTH; // cards positions should be centred

    const offset = width / lastIndex || 0;

    for (let i = lastIndex; i >= 0; i--) {
      const card = cards[lastIndex - i];

      const newPosition = this.position.clone().addX(i * offset - width / 2);

      if (params.onInitialize) {
        card.setPosition(newPosition);
      } else if (params.onResize) {
        card.dynamicSetPosition(newPosition);
      } else {
        card.setDestination(newPosition);
      }
    }
  }

  recalculateCardsZIndexes(cards) {
    const zIndexOffset = 1 / cards.length;

    cards.forEach((card, index) => {
      card.setZIndex(Z_INDEX.ACTIVE + index * zIndexOffset);
    });
  }

  afterResize() {
    const cards = this.kingdom.getActive();

    this.updatePosition();
    this.recalculateCardsPositions(cards, { onResize: true });
  }

  onModeChange() {
    const cards = this.kingdom.getActive();

    this.updatePosition();
    this.recalculateCardsPositions(cards);
  }
}
