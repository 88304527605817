import { SOUNDS } from 'game/consts/sounds.js';
import { InterfaceBase } from 'game/interface/interface-base.js';
import { TEXT_TYPES } from 'game/languages/text-types.js';

import { HeadquarterButton } from './buttons/headquarter.js';
import { TargetButton } from './buttons/target.js';
import { Active } from './kingdom/active.js';
import { CancelButton } from './kingdom/cancel-button.js';
import { Deck } from './kingdom/deck.js';
import { Discard } from './kingdom/discard.js';
import { FieldSelector } from './kingdom/field-selector.js';
import { Hand } from './kingdom/hand.js';
import { Receive } from './kingdom/receive.js';
import { RedrawIcons } from './kingdom/redraw-icons.js';
import { Resources } from './kingdom/resources.js';
import { Target } from './kingdom/target.js';

const ACTIONS = {
  IDLE: 'IDLE',
  CHANGING_TARGET: 'CHANGING_TARGET',
  PICKING_FIELD: 'PICKING_FIELD',
  PICKING_HAND_CARD: 'PICKING_HAND_CARD',
};

export class ControlledKingdomInterface extends InterfaceBase {
  constructor(params) {
    super(params);

    this.kingdom = params.kingdom;

    this.focusedOnBoard = false;

    this.currentAction = ACTIONS.IDLE;

    this.currentlyPlayingCard = null;
    this.pickingOrders = [];
    this.cardTargets = {};

    this.createSprites();
    this.pinIntoKingdomCallbacks();
    this.registerEventListeners();
    this.setupCardsSprites();
  }

  createSprites() {
    this.hand = new Hand({ kingdom: this.kingdom, game: this.game, parent: this });
    this.discard = new Discard({ kingdom: this.kingdom, game: this.game, parent: this });
    this.deck = new Deck({ kingdom: this.kingdom, game: this.game, parent: this });
    this.active = new Active({ kingdom: this.kingdom, game: this.game, parent: this });
    this.receive = new Receive({ kingdom: this.kingdom, game: this.game, parent: this });

    this.fieldSelector = new FieldSelector({ game: this.game, kingdom: this.kingdom, parent: this });
    this.target = new Target({ game: this.game, kingdom: this.kingdom, parent: this });
    this.cancelButton = new CancelButton({ game: this.game, parent: this });
    this.redrawIcons = new RedrawIcons({ game: this.game, kingdom: this.kingdom });
    this.resources = new Resources({ game: this.game, kingdom: this.kingdom });

    this.targetButton = new TargetButton({ game: this.game });
    this.headquarterButton = new HeadquarterButton({ game: this.game });
  }

  setupCardsSprites() {
    this.hand.onInitialize();
    this.discard.onInitialize();
    this.deck.onInitialize();
  }

  setFocusedOnBoard(value) {
    if (value === this.focusedOnBoard) return;

    this.focusedOnBoard = value;

    this.hand.onFocusedOnBoardChange();
    this.active.onFocusedOnBoardChange();
    this.cancelButton.onFocusedOnBoardChange();
  }

  getFocusedOnBoard() {
    return this.focusedOnBoard;
  }

  pinIntoKingdomCallbacks() {
    this.kingdom.onHandChange = this.onHandChange;
    this.kingdom.onDiscardChange = this.onDiscardChange;
    this.kingdom.onDeckChange = this.onDeckChange;
    this.kingdom.onActiveChange = this.onActiveChange;
    this.kingdom.onReceiveChange = this.onReceiveChange;
    this.kingdom.onRedrawChange = this.onRedrawChange;
    this.kingdom.onEnergyChange = this.onEnergyChange;
    this.kingdom.onShufflingStart = this.onShufflingStart;
    this.kingdom.onDrawingStart = this.onDrawingStart;
    this.kingdom.onDrawingCard = this.onDrawingCard;
    this.kingdom.onDrawingEnd = this.onDrawingEnd;
    this.kingdom.onTargetChange = this.onTargetChange;
    this.kingdom.onEliminate = this.onEliminate;
    this.kingdom.onOverdraw = this.onOverdraw;
  }

  registerEventListeners = () => {
    this.game.eventsController.addListener('marketCardClick', this);
    this.game.eventsController.addListener('targetButtonClick', this);
    this.game.eventsController.addListener('escKeyDown', this);
    this.game.eventsController.addListener('openMarket', this);
    this.game.eventsController.addListener('closeMarket', this);
  };

  unregisterEventListeners = () => {
    this.game.eventsController.removeListener('marketCardClick', this);
    this.game.eventsController.removeListener('targetButtonClick', this);
    this.game.eventsController.removeListener('escKeyDown', this);
    this.game.eventsController.removeListener('openMarket', this);
    this.game.eventsController.removeListener('closeMarket', this);
  };

  makeInteractive = () => {
    this.deck.unblockInteractions();
    this.hand.unblockInteractions();
    this.unblockMarketInteractive();
  };

  blockMarketInteractive = () => {
    this.game.eventsController.runEvent('blockMarketInteractive');
  };

  unblockMarketInteractive = () => {
    this.game.eventsController.runEvent('unblockMarketInteractive');
  };

  onMarketCardClick = ({ card }) => {
    this.game.marketController.tryBuyCard(card, this.kingdom);
  };

  onTargetButtonClick = () => {
    if (this.currentAction === ACTIONS.IDLE) return this.triggerChangingTarget();
    if (this.currentAction === ACTIONS.CHANGING_TARGET) return this.cancelChangingTarget();
  };

  onTargetClick = () => {
    if (this.currentAction === ACTIONS.IDLE) return this.triggerChangingTarget();
    if (this.currentAction === ACTIONS.CHANGING_TARGET) return this.cancelChangingTarget();
  };

  onTargetOverlayClick = (params) => {
    if (this.currentAction !== ACTIONS.CHANGING_TARGET) return;

    this.finishChangingTarget(params);
  };

  onCancelButtonClick = () => {
    this.cancelCurrentAction();
  };

  onEscKeyDown = () => {
    return this.cancelCurrentAction();
  };

  cancelCurrentAction() {
    if (this.currentAction === ACTIONS.CHANGING_TARGET) {
      this.cancelChangingTarget();
      return true;
    }

    if (this.currentAction === ACTIONS.PICKING_FIELD) {
      this.stopPickingField();
      this.cancelPlayingCard();
      return true;
    }

    if (this.currentAction === ACTIONS.PICKING_HAND_CARD) {
      this.stopPickingHandCard();
      this.cancelPlayingCard();
      return true;
    }
  }

  onOpenMarket = () => {
    this.headquarterButton.disable();
    this.targetButton.disable();
  };

  onCloseMarket = () => {
    this.headquarterButton.enable();
    this.targetButton.enable();
  };

  triggerChangingTarget() {
    this.currentAction = ACTIONS.CHANGING_TARGET;

    this.target.makeMoveable();
    this.cancelButton.show();
    this.game.interfaceController.fixedCardsButton.disable();
    this.game.interfaceController.randomCardsButton.disable();

    this.game.interfaceController.infoUnderBar.showMessage(this.game.textsController.getInterfaceText(TEXT_TYPES.CHOOSE_NEW_TARGET), 0);

    return true;
  }

  finishChangingTarget({ point }) {
    this.currentAction = ACTIONS.IDLE;

    this.changeTarget(point);

    this.target.makeInmoveable();
    this.cancelButton.fadeOut();
    this.game.interfaceController.fixedCardsButton.enable();
    this.game.interfaceController.randomCardsButton.enable();

    this.game.interfaceController.infoUnderBar.fadeOutMessage();

    return true;
  }

  cancelChangingTarget() {
    this.currentAction = ACTIONS.IDLE;

    this.target.makeInmoveable();
    this.cancelButton.fadeOut();
    this.game.interfaceController.fixedCardsButton.enable();
    this.game.interfaceController.randomCardsButton.enable();

    this.game.interfaceController.infoUnderBar.fadeOutMessage();

    return true;
  }

  onHandChange = () => {
    this.hand.onChange();
  };

  onDiscardChange = () => {
    this.discard.onChange();
  };

  onDeckChange = () => {
    this.deck.onChange();
  };

  onActiveChange = () => {
    this.active.onChange();
  };

  onReceiveChange = () => {
    this.receive.onChange();
  };

  onEnergyChange = () => {
    this.resources.onEnergyChange();
  };

  onRedrawChange = () => {
    this.deck.onRedrawChange();
    this.redrawIcons.onRedrawChange();
  };

  onShufflingStart = () => {
    this.deck.onShufflingStart();
  };

  onDrawingStart = () => {
    this.deck.blockInteractions();
    this.hand.blockInteractions();
    this.blockMarketInteractive();
  };

  onDrawingCard = () => {
    this.hand.onDrawingCard();
    this.game.soundsController.playSound(SOUNDS.DRAWING);
  };

  onDrawingEnd = () => {
    this.deck.unblockInteractions();
    this.hand.unblockInteractions();
    this.unblockMarketInteractive();
  };

  onCardInHandClick = (card) => {
    if (this.currentAction === ACTIONS.IDLE) return this.tryUseCard(card);
    if (this.currentAction === ACTIONS.PICKING_HAND_CARD) return this.onHandCardSelected(card);
  };

  onDeckClick = () => {
    if (this.game.shouldConfirmWithHost()) {
      this.game.connectionController.sendTryRedraw();
    } else {
      this.kingdom.tryRedraw();
    }
  };

  onTargetChange = () => {
    this.target.updatePosition();
  };

  onEliminate = () => {
    this.destroy();
  };

  destroy() {
    this.unregisterEventListeners();

    this.hand.destroy();
    this.discard.destroy();
    this.deck.destroy();
    this.active.destroy();

    this.cancelButton.destroy();
    this.fieldSelector.destroy();
    this.redrawIcons.destroy();
    this.resources.destroy();
    this.target.destroy();

    this.targetButton.destroy();
    this.headquarterButton.destroy();

    this.blockMarketInteractive();
  }

  tryUseCard = async (card) => {
    const checkResult = this.game.cardEffectsManager.canBePlayed(card, this.kingdom);

    if (!checkResult.can) {
      this.game.interfaceController.infoUnderBar.showMessage(checkResult.message);
      return false;
    }

    this.kingdom.moveCardToActive(card);

    this.currentlyPlayingCard = card;

    this.game.cardEffectsManager.pickTargetsForCard(card, this);

    this.doNextPicking();
  };

  playCardOnTargets = () => {
    const card = this.currentlyPlayingCard;

    this.game.cardEffectsManager.playCard(card.id, this.cardTargets, this.kingdom);

    card.startDiscardFromPlayTimer();

    this.kingdom.moveCardToActive(card);

    this.resetPlayingCardDate();

    this.currentAction = ACTIONS.IDLE;
  };

  cancelPlayingCard = () => {
    const card = this.currentlyPlayingCard;

    this.kingdom.moveCardBackToHand(card);

    this.resetPlayingCardDate();

    this.currentAction = ACTIONS.IDLE;
  };

  doNextPicking = () => {
    const picking = this.pickingOrders.shift();

    if (!picking) return this.playCardOnTargets();

    if (picking.type === ACTIONS.PICKING_FIELD) return this.pickField(picking.conditions);
    if (picking.type === ACTIONS.PICKING_HAND_CARD) return this.pickHandCard(picking.conditions);
  };

  tryUseCardMultiplayer = async (card) => {
    const checkResult = this.game.cardEffectsManager.canBePlayed(card, this.kingdom);

    if (!checkResult.can) {
      this.game.interfaceController.infoUnderBar.showMessage(checkResult.message);
      return false;
    }

    this.kingdom.moveCardToActive(card);

    // TODO - return ids, not objects - for socket communication
    const targets = await this.game.cardEffectsManager.pickTargetsForCard(card, this);

    if (targets.destroyed) return false;

    if (targets.cancel) {
      this.kingdom.moveCardBackToHand(card);
      return false;
    }

    card.showSpinner();

    this.game.connectionController.sendPlayCard(card.id, targets);
  };

  orderPickingShotTargetField() {
    return this.orderPickingField();
  }

  orderPickingFieldForBuilding() {
    return this.orderPickingField({ own: true, empty: true });
  }

  orderPickingOwnBuildingToDestroy() {
    return this.orderPickingField({ own: true, withBuilding: true, withoutHeadquarter: true });
  }

  orderPickingOwnFieldToFortificate() {
    return this.orderPickingField({ own: true });
  }

  orderPickingField = (conditions) => {
    this.pickingOrders.push({ type: ACTIONS.PICKING_FIELD, conditions });
  };

  pickField = (conditions) => {
    this.currentAction = ACTIONS.PICKING_FIELD;

    this.deck.blockInteractions();
    this.hand.blockInteractions();

    this.targetButton.disable();
    this.game.interfaceController.fixedCardsButton.disable();
    this.game.interfaceController.randomCardsButton.disable();

    this.cancelButton.show();

    this.setFocusedOnBoard(true);

    this.game.interfaceController.infoUnderBar.showMessage(this.game.textsController.getInterfaceText(TEXT_TYPES.PICK_FIELD), 0);

    this.fieldSelector.pickField(conditions);
  };

  stopPickingField() {
    this.currentAction = ACTIONS.IDLE;

    this.deck.unblockInteractions();
    this.hand.unblockInteractions();

    this.targetButton.enable();
    this.game.interfaceController.fixedCardsButton.enable();
    this.game.interfaceController.randomCardsButton.enable();

    this.cancelButton.fadeOut();

    this.setFocusedOnBoard(false);

    this.fieldSelector.hide();

    this.game.interfaceController.infoUnderBar.fadeOutMessage();
  }

  resetPlayingCardDate() {
    this.currentlyPlayingCard = null;
    this.pickingOrders = [];
    this.cardTargets = {};
  }

  onFieldSelect = ({ fieldId }) => {
    this.cardTargets.fieldId = fieldId;

    this.stopPickingField();

    this.doNextPicking();
  };

  changeTarget(newTarget) {
    if (this.advancedLogicPart) this.kingdom.changeTarget(newTarget);
    if (this.game.shouldConfirmWithHost()) this.game.connectionController.sendChangeTarget(newTarget);
  }

  setPickedField(field) {
    this.pickedField = field;
  }

  orderPickingHandCardToTrash() {
    return this.orderPickingHandCard();
  }

  orderPickingHandCardToTrashAndCopy() {
    return this.orderPickingHandCard({ persistent: true });
  }

  orderPickingHandCardToImprove(conditions = {}) {
    return this.orderPickingHandCard(conditions);
  }

  orderPickingHandCard = (conditions) => {
    this.pickingOrders.push({ type: ACTIONS.PICKING_HAND_CARD, conditions });
  };

  pickHandCard = (conditions = {}) => {
    this.currentAction = ACTIONS.PICKING_HAND_CARD;

    this.deck.blockInteractions();

    this.target.blockInteractions();

    this.targetButton.disable();
    this.headquarterButton.disable();
    this.game.interfaceController.fixedCardsButton.disable();
    this.game.interfaceController.randomCardsButton.disable();

    this.cancelButton.show();

    this.game.interfaceController.infoUnderBar.showMessage(this.game.textsController.getInterfaceText(TEXT_TYPES.PICK_CARD), 0);

    this.hand.lockCards(conditions);
  };

  stopPickingHandCard() {
    this.currentAction = ACTIONS.IDLE;

    this.deck.unblockInteractions();

    this.target.unblockInteractions();

    this.targetButton.enable();
    this.headquarterButton.enable();
    this.game.interfaceController.fixedCardsButton.enable();
    this.game.interfaceController.randomCardsButton.enable();

    this.cancelButton.fadeOut();

    this.game.interfaceController.infoUnderBar.fadeOutMessage();

    this.hand.unlockCards();
  }

  onHandCardSelected = (card) => {
    this.cardTargets.cardId = card.id;

    this.stopPickingHandCard();

    this.doNextPicking();
  };

  startListeningToCancel() {
    this.cancelButton.setVisible(true);
    this.cancel = false;
  }

  stopListeningToCancel() {
    this.cancelButton.setVisible(false);
  }

  onClickCancel() {
    this.cancel = true;
  }

  onOverdraw() {
    this.game.interfaceController.infoUnderBar.showMessage(this.game.textsController.getInterfaceText(TEXT_TYPES.OVER_HARD_CARD_LIMIT), 1);
  }
}
