import { CANCEL_BUTTON_HEIGHT, CANCEL_BUTTON_TOP } from 'game/consts/layout.js';
import { IMAGES } from 'game/consts/types/images.js';
import { Z_INDEX } from 'game/consts/z-index.js';
import { perFrameTimer } from 'game/helpers/converters.js';
import { normalizeFraction } from 'game/helpers/number.js';
import { withLeftClick } from 'game/utils/with-left-click.js';
import * as PIXI from 'pixi.js';

import { InterfaceBase } from '../interface-base.js';

const FADE_OUT_TIME = perFrameTimer(0.5);

export class CancelButton extends InterfaceBase {
  constructor(params) {
    super(params);

    this.parent = params.parent;

    this.visible = false;
    this.fadeOutTimer = 0;
    this.interactive = false;

    this.size = CANCEL_BUTTON_HEIGHT;

    this.createSprites();
    this.updatePosition();
  }

  createSprites() {
    this.createSpritesContainer();
    this.createButtonSprites();
  }

  createSpritesContainer() {
    this.sprites.container = new PIXI.Container();
    this.sprites.container.zIndex = Z_INDEX.CANCEL_BUTTON;
    this.sprites.container.visible = false;

    this.sprites.container.cursor = 'pointer';

    this.sprites.container.on('pointerdown', withLeftClick(this.onClick));
    this.sprites.container.on('pointerover', this.onMouseEnter);
    this.sprites.container.on('pointerout', this.onMouseLeave);

    this.registerSprite(this.sprites.container);
  }

  createButtonSprites() {
    this.sprites.button = this.game.texturesManager.createStandardSprite(IMAGES.INTERFACE.BUTTONS.SMALL_CANCEL);
    this.sprites.button.anchor.set(0.5, 0);

    this.sprites.buttonHovered = this.game.texturesManager.createStandardSprite(IMAGES.INTERFACE.BUTTONS.SMALL_CANCEL_HOVERED);
    this.sprites.buttonHovered.anchor.set(0.5, 0);
    this.sprites.buttonHovered.visible = false;

    this.sprites.container.addChild(this.sprites.button);
    this.sprites.container.addChild(this.sprites.buttonHovered);
  }

  activate(delta) {
    this.calculateFadeOutTimer(delta);
  }

  show() {
    this.setVisible(true);
    this.fadeOutTimer = 0;
    this.setOpacity(1);
    this.setInteractive(true);
  }

  hide() {
    this.setVisible(false);
    this.setInteractive(false);
  }

  fadeOut() {
    this.fadeOutTimer = FADE_OUT_TIME;
    this.setInteractive(false);
  }

  setInteractive(interactive) {
    if (!interactive) {
      this.onMouseLeave();
    }

    this.interactive = interactive;
    this.sprites.container.eventMode = interactive ? 'static' : 'passive';
  }

  calculateFadeOutTimer(delta) {
    if (this.fadeOutTimer <= 0) return;

    this.fadeOutTimer -= delta;

    this.setOpacity(normalizeFraction(this.fadeOutTimer / FADE_OUT_TIME));

    if (this.fadeOutTimer > 0) return;

    this.hide();
  }

  setOpacity(opacity) {
    this.sprites.container.alpha = opacity;
  }

  setVisible(value) {
    if (value === this.visible) return false;

    this.visible = value;
    this.sprites.container.visible = value;
  }

  updatePosition() {
    this.sprites.container.x = window.innerWidth / 2;
    this.sprites.container.y = CANCEL_BUTTON_TOP;
  }

  onFocusedOnBoardChange = () => {
    this.updatePosition();
  };

  afterResize() {
    this.updatePosition();
  }

  onClick = () => {
    if (!this.interactive) return;

    this.parent.onCancelButtonClick();
  };

  isFullyVisible() {
    return this.visible && this.fadeOutTimer <= 0;
  }

  onMouseEnter = () => {
    if (!this.interactive) return;

    this.sprites.button.visible = false;
    this.sprites.buttonHovered.visible = true;
  };

  onMouseLeave = () => {
    if (!this.interactive) return;

    this.sprites.button.visible = true;
    this.sprites.buttonHovered.visible = false;
  };
}
