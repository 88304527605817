import { TOOLTIP } from 'game/consts/tooltip.js';
import { CARD_EFFECT_TYPES } from 'game/consts/types/card-effect.js';

import { BaseCardEffect } from '../base.js';

export class FairShotCardEffect extends BaseCardEffect {
  constructor(params) {
    super(params);

    this.type = CARD_EFFECT_TYPES.FAIR_SHOT;
  }

  getTooltipTypes() {
    return [TOOLTIP.TYPES.SNIPER];
  }

  execute({ kingdom, card }) {
    const { shots } = card.getStats();

    const enemyKingdoms = this.game.kingdomsController.getOtherActiveThan(kingdom);
    const targets = enemyKingdoms.map((kindom) => kindom.getHeadquarter().getField().getPosition());

    kingdom.getHeadquarter().fireBulletsTask({ shots, inaccuracy: 0, targets });
  }
}
