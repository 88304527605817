import { STRUCTURES } from 'game/consts/types/structures.js';

import { BasicCannon } from '../entities/structures/buildings/cannons/basic.js';
import { DefenderCannon } from '../entities/structures/buildings/cannons/defender.js';
import { RandomCannon } from '../entities/structures/buildings/cannons/random.js';
import { ShortRangeCannon } from '../entities/structures/buildings/cannons/short-range.js';
import { SniperCannon } from '../entities/structures/buildings/cannons/sniper.js';
import { PowerPlantBuilding } from '../entities/structures/buildings/economy/power-plant.js';
import { WarehouseBuilding } from '../entities/structures/buildings/economy/warehouse.js';
import { ForgeBuilding } from '../entities/structures/buildings/forge.js';
import { HeadquarterBuilding } from '../entities/structures/buildings/headquarter.js';
import { ReinforcementBuilding } from '../entities/structures/buildings/reinforcement.js';
import { MineStructure } from '../entities/structures/mine.js';

export class StructuresCreator {
  constructor(game) {
    this.structures = {
      [STRUCTURES.BASIC_CANNON]: BasicCannon,
      [STRUCTURES.HEADQUARTER]: HeadquarterBuilding,
      [STRUCTURES.RANDOM_CANNON]: RandomCannon,
      [STRUCTURES.SHORT_RANGE_CANNON]: ShortRangeCannon,
      [STRUCTURES.SNIPER_CANNON]: SniperCannon,
      [STRUCTURES.DEFENDER]: DefenderCannon,
      [STRUCTURES.WAREHOUSE]: WarehouseBuilding,
      [STRUCTURES.POWER_STATION]: PowerPlantBuilding,
      [STRUCTURES.FORGE]: ForgeBuilding,
      [STRUCTURES.MINE]: MineStructure,
      [STRUCTURES.REINFORCEMENT]: ReinforcementBuilding,
    };

    this.game = game;
    this.serverPart = game.serverPart;
  }

  setStructureFromSD(mapField, socketData) {
    const { type, id, hostId } = socketData;

    const structure = new this.structures[type]({
      mapField,
      game: this.game,
      id,
    });

    mapField.setStructure(structure);

    if (type === STRUCTURES.HEADQUARTER) {
      const ownerKingdom = this.game.kingdomsController.getKingdomById(hostId);
      if (ownerKingdom) ownerKingdom.setHeadquarter(structure);
    }

    return structure;
  }

  createStructureFromSD(mapField, socketData) {
    const structure = this.setStructureFromSD(mapField, socketData);
    this.game.eventsController.runEvent('createStructure', { structure });
  }

  setStructure(mapField, type, params) {
    const structure = new this.structures[type]({
      mapField,
      game: this.game,
      ...params,
    });

    mapField.setStructure(structure);

    if (this.game.shouldSendEvent()) this.game.connectionController.onFieldUpdate(mapField);

    return structure;
  }

  createStructure(mapField, type, params) {
    const structure = this.setStructure(mapField, type, params);
    this.game.eventsController.runEvent('createStructure', { structure });
  }
}
