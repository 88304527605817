import { CARD_EFFECT_TYPES } from 'game/consts/types/card-effect.js';

import { BaseCardEffect } from '../base.js';

export class IncreaseOwnShotsCardEffect extends BaseCardEffect {
  constructor(params) {
    super(params);

    this.type = CARD_EFFECT_TYPES.INCREASE_OWN_SHOTS;
  }

  execute({ card }) {
    const { shotsIncrease } = card.getStats();
    card.increaseStat('shots', shotsIncrease);
  }
}
