import { CARD_WIDTH, HAND_BOTTOM, HAND_HEIGHT, HAND_MARGINS, HIDDEN_HAND_OFFSET, MARGIN } from 'game/consts/layout.js';
import { SOUNDS } from 'game/consts/sounds.js';
import { Z_INDEX } from 'game/consts/z-index.js';
import { Point } from 'game/data-types/point.js';

import { InterfaceBase } from '../interface-base.js';

export class Hand extends InterfaceBase {
  constructor(params) {
    super(params);

    this.kingdom = params.kingdom;
    this.parent = params.parent;

    this.focusedCardId = null;

    this.interactive = false;
    this.interactiveBlockades = 1;

    this.updatePosition();
  }

  onFocusedOnBoardChange = () => {
    this.updatePosition();
    this.recalculateCardsPositions(this.kingdom.getHand());
  };

  updatePosition() {
    const hiddenMode = this.parent.getFocusedOnBoard();
    const hiddenOffset = hiddenMode ? HIDDEN_HAND_OFFSET : 0;

    this.position = new Point(window.innerWidth / 2, window.innerHeight - HAND_HEIGHT / 2 - HAND_BOTTOM + hiddenOffset);
  }

  onInitialize() {
    const cards = this.kingdom.getHand();

    this.updateCardsInterfaces(cards);
    this.recalculateCardsPositions(cards, { onInitialize: true });
    this.recalculateZIndex(cards);
  }

  onChange() {
    const cards = this.kingdom.getHand();

    this.updateCardsInterfaces(cards);
    this.recalculateCardsPositions(cards);
    this.recalculateZIndex(cards);
  }

  onDrawingCard() {
    this.focusedCardId = null;
  }

  updateCardsInterfaces(cards) {
    cards.forEach((card) => {
      card.setContainer(this);
      card.setInteractive(this.interactive);
      card.showFront();
    });
  }

  recalculateCardsPositions(cards, params = {}) {
    const cardsQuantity = cards.length;

    if (cardsQuantity < 1) return false;

    let width = window.innerWidth - HAND_MARGINS;
    width = Math.min(width, cardsQuantity * CARD_WIDTH + ((cardsQuantity - 1) * MARGIN) / 3);
    width -= CARD_WIDTH; // cards positions should be centred

    const offset = width / (cardsQuantity - 1) || 0;

    for (let i = cardsQuantity - 1; i >= 0; i--) {
      const card = cards[i];
      const newPosition = this.position.clone().addX(i * offset - width / 2);

      if (params.onInitialize) {
        card.setPosition(newPosition);
      } else if (params.onResize) {
        card.dynamicSetPosition(newPosition);
      } else {
        card.setDestination(newPosition);
      }
    }
  }

  recalculateZIndex(cards) {
    const focusedIndex = this.returnFocusedCardIndex(cards);

    const zIndexOffset = 1 / cards.length;

    let zIndex = Z_INDEX.HAND;

    cards.forEach((card, index) => {
      card.setZIndex(zIndex);
      zIndex += index < focusedIndex ? zIndexOffset : -zIndexOffset;
    });
  }

  onCardMouseEnter(card) {
    this.setFocusedCard(card);
    this.game.soundsController.playSound(SOUNDS.SELECTING_CARD);
  }

  onCardClick(card) {
    this.parent.onCardInHandClick(card);
  }

  setFocusedCard(card) {
    const cards = this.kingdom.getHand();

    this.focusedCardId = card.id;
    this.recalculateZIndex(cards);
  }

  returnFocusedCardIndex(cards) {
    if (!this.focusedCardId) return -1;
    return cards.findIndex((card) => card.id === this.focusedCardId);
  }

  afterResize() {
    const cards = this.kingdom.getHand();

    this.updatePosition();
    this.recalculateCardsPositions(cards, { onResize: true });
  }

  blockInteractions() {
    this.interactiveBlockades++;
    if (this.interactiveBlockades === 1) this.setInteractive(false);
  }

  unblockInteractions() {
    this.interactiveBlockades--;
    if (this.interactiveBlockades === 0) this.setInteractive(true);
  }

  setInteractive(value) {
    this.interactive = value;

    const cards = this.kingdom.getHand();
    cards.forEach((card) => card.setInteractive(value));
  }

  lockCards(conditions) {
    const cards = this.kingdom.getHand();
    const { stat, persistent } = conditions;

    if (stat) {
      cards.forEach((card) => !card.haveStat(stat) && card.setDisabled(true));
    }

    if (persistent) {
      cards.forEach((card) => !card.isPersistent() && card.setDisabled(true));
    }
  }

  unlockCards() {
    const cards = this.kingdom.getHand();
    cards.forEach((card) => card.setDisabled(false));
  }
}
