import { TOOLTIP } from 'game/consts/tooltip.js';
import { CARD_EFFECT_TYPES } from 'game/consts/types/card-effect.js';
import { ERROR_TYPES } from 'game/consts/types/errors.js';

import { BaseCardEffect } from './base.js';

export class DrainCardEffect extends BaseCardEffect {
  constructor(params) {
    super(params);

    this.type = CARD_EFFECT_TYPES.DRAIN;
  }

  getTooltipTypes() {
    return [TOOLTIP.TYPES.DRAIN];
  }

  execute({ kingdom, card }) {
    const { drain } = card.getStats();
    kingdom.changeEnergy(-drain);
  }

  canBePlayed({ kingdom, card }) {
    const { drain } = card.getStats();

    if (kingdom.getEnergy() < drain) {
      return this.getCant(this.game.textsController.getErrorText(ERROR_TYPES.NOT_ENOUGH_ENERGY));
    }

    return this.getCan();
  }
}
