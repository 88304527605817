import { HEXAGON_SIZE } from 'game/consts/layout.js';
import { DEFENDER_NUMBER_OF_BULLETS, DEFENDER_RANGE } from 'game/consts/structures.js';
import { ENTITIES } from 'game/consts/types/entities.js';
import { IMAGES } from 'game/consts/types/images.js';
import { STRUCTURES } from 'game/consts/types/structures.js';
import { degreesToRadians, perFrameSpeed, perFrameTimer } from 'game/helpers/converters.js';
import { rotationToSpeeds } from 'game/helpers/rotation.js';

import { BaseCannon } from './base.js';

export class DefenderCannon extends BaseCannon {
  constructor(params) {
    super(params);

    this.range = HEXAGON_SIZE * DEFENDER_RANGE;
    this.rotate = false;

    this.type = STRUCTURES.DEFENDER;
  }

  getSpriteName() {
    return IMAGES.STRUCTURES.DEFENDER;
  }

  onCycleEnd() {
    if (this.owner && this.advancedLogicPart) this.fireBullets();
  }

  fireBullets() {
    let rotation = degreesToRadians(30);

    for (let i = DEFENDER_NUMBER_OF_BULLETS; i > 0; i--) {
      this.game.entitiesController.create(ENTITIES.BULLET, {
        x: this.position.x,
        y: this.position.y,
        ...rotationToSpeeds(rotation, perFrameSpeed(this.bulletSpeed)),
        lifeTime: perFrameTimer(this.range / this.bulletSpeed),
        owner: this.owner,
      });

      rotation += degreesToRadians(360 / DEFENDER_NUMBER_OF_BULLETS);
    }
  }
}
