import { useDispatch } from 'react-redux';

import { BackButton } from 'components/common/back-button';
import { Button } from 'components/common/button';
import { CentredContainer } from 'components/common/centred-container';
import { Subtitle } from 'components/common/subtitle';
import { Title } from 'components/common/title';

import { openView } from 'store/temporary/slice';

import styles from './styles.module.css';

export function SingleplayerSelectionView() {
  const dispatch = useDispatch();

  const onEasyClick = () => {
    dispatch(openView({ viewName: 'game', viewParams: { difficulty: 1 } }));
  };

  const onNormalClick = () => {
    dispatch(openView({ viewName: 'game', viewParams: { difficulty: 2 } }));
  };

  const onHardClick = () => {
    dispatch(openView({ viewName: 'game', viewParams: { difficulty: 3 } }));
  };

  return (
    <CentredContainer>
      <Title>Singleplayer</Title>
      <Subtitle>Select difficulty</Subtitle>

      <div className={styles.section}>
        <div className={styles.buttonGroupWrapper}>
          <Button onClick={onEasyClick}>Easy</Button>
        </div>

        <div className={styles.buttonGroupWrapper}>
          <Button onClick={onNormalClick}>Normal</Button>
        </div>

        <div className={styles.buttonGroupWrapper}>
          <Button onClick={onHardClick}>Hard</Button>
        </div>
      </div>

      <div className={styles.backButtonWrapper}>
        <BackButton />
      </div>
    </CentredContainer>
  );
}
