import { TOOLTIP_TEXTS } from 'game/consts/tooltip.js';
import { Z_INDEX } from 'game/consts/z-index.js';
import { Point } from 'game/data-types/point.js';
import { CentredInterfaceGroup } from 'game/interface/groups/centred-interface-group.js';
import * as PIXI from 'pixi.js';

import { TextBox } from '../elements/text-box.js';

const GAP = 10;

const MARGIN = 10;

const WIDTH = 300;

const ELEMENTS = 4;

export class Tooltip extends CentredInterfaceGroup {
  constructor(params) {
    super(params);

    this.width = WIDTH;

    this.gap = GAP;

    this.infoMode = false;
    this.source = null;

    this.elements = [];

    this.createContainer();
    this.createElements();

    this.registerEventListeners();
  }

  createContainer() {
    this.sprites.container = new PIXI.Container();
    this.sprites.container.zIndex = Z_INDEX.TOOLTIP;
    this.sprites.container.sortableChildren = true;

    this.registerSprite(this.sprites.container);
  }

  createElements() {
    for (let i = 0; i < ELEMENTS; i++) {
      const element = new TextBox({
        game: this.game,
        parentContainer: this.sprites.container,
      });

      this.elements.push(element);
    }
  }

  updateTexts(texts) {
    for (let i = 0; i < ELEMENTS; i++) {
      const currentText = texts[i];
      const elements = this.elements[i];

      if (currentText) elements.updateText(currentText);
      elements.setVisible(currentText);
    }
  }

  registerEventListeners() {
    this.game.eventsController.addListener('tooltipableMouseEnter', this);
    this.game.eventsController.addListener('tooltipableMouseLeave', this);
    this.game.eventsController.addListener('infoKeyDown', this);
    this.game.eventsController.addListener('infoKeyUp', this);
  }

  unregisterEventListeners() {
    this.game.eventsController.removeListener('tooltipableMouseEnter', this);
    this.game.eventsController.removeListener('tooltipableMouseLeave', this);
    this.game.eventsController.removeListener('infoKeyDown', this);
    this.game.eventsController.removeListener('infoKeyUp', this);
  }

  onTooltipableMouseEnter = ({ source }) => {
    this.setSource(source);
  };

  onTooltipableMouseLeave = ({ source }) => {
    this.unsetSource(source);
  };

  onInfoKeyDown = () => {
    this.enableInfoMode();
  };

  onInfoKeyUp = () => {
    this.disableInfoMode();
  };

  setSource(source) {
    if (this.source?.id === source.id) return;
    this.source = source;
    this.updateStatus();
  }

  unsetSource(source) {
    if (this.source?.id !== source.id) return;
    this.source = null;
    this.updateStatus();
  }

  enableInfoMode() {
    this.infoMode = true;
    this.updateStatus();
  }

  disableInfoMode() {
    this.infoMode = false;
    this.updateStatus();
  }

  updateStatus() {
    if (this.visible) {
      if (!this.source || !this.infoMode) {
        this.setVisibility(false);
      }
    } else {
      if (this.source && this.infoMode) {
        this.generateContent();
        this.setVisibility(true);
      }
    }
  }

  setVisibility(visible) {
    this.visible = visible;
    this.sprites.container.visible = visible;
  }

  generateContent() {
    const textTypes = this.source.getTooltipTypes();
    const tooltipTexts = this.getTooltipTexts(textTypes);

    this.updateTexts(tooltipTexts);
    this.setElementsPositions();
    this.setPosition();
  }

  getTooltipTexts(types) {
    return types.map((type) => this.game.textsController.getTooltipText(type));
  }

  afterResize() {
    if (!this.visible) return false;

    this.setElementsPositions();
    this.setPosition();
  }

  setPosition() {
    const position = this.getPosition();

    this.sprites.container.x = position.x;
    this.sprites.container.y = position.y;
  }

  getPosition() {
    const bounds = this.source.getBounds();

    const { height, width } = this.sprites.container;

    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;

    if (bounds.bottom + MARGIN + height < windowHeight) {
      return new Point(bounds.x + bounds.width / 2 - width / 2, bounds.bottom + MARGIN);
    }

    if (bounds.top - MARGIN - height > 0) {
      return new Point(bounds.x + bounds.width / 2 - width / 2, bounds.top - height - MARGIN);
    }

    return new Point(MARGIN, MARGIN);
  }

  setElementsPositions() {
    let yOffset = 0;

    const max = this.elements.length;

    for (let i = 0; i < max; i++) {
      const element = this.elements[i];

      element.setPosition(0, yOffset);
      yOffset += element.getContainerHeight() + GAP;
    }
  }

  destroyElements() {
    this.elements.forEach((element) => element.destroy());
    this.elements = [];
  }

  destroy() {
    this.unregisterEventListeners();
    super.destroy();
  }
}
