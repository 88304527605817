import { TOOLTIP } from 'game/consts/tooltip.js';
import { CARD_EFFECT_TYPES } from 'game/consts/types/card-effect.js';

import { BaseCardEffect } from '../base.js';

export class DestroyWeakestFieldsCardEffect extends BaseCardEffect {
  constructor(params) {
    super(params);

    this.type = CARD_EFFECT_TYPES.DESTROY_WEAKEST_FIELDS;
  }

  getTooltipTypes() {
    return [TOOLTIP.TYPES.WEAKEST_FIELD];
  }

  execute({ kingdom, card }) {
    const { numberOfFields } = card.getStats();

    const enemyKingdoms = this.game.kingdomsController.getOtherActiveThan(kingdom);
    enemyKingdoms.forEach((kingdom) => {
      kingdom.voidWeakestFields(numberOfFields);
    });
  }
}
