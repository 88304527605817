import { TOOLTIP } from 'game/consts/tooltip.js';
import { CARD_EFFECT_TYPES } from 'game/consts/types/card-effect.js';

import { BaseCardEffect } from '../base.js';

export class FragileCardEffect extends BaseCardEffect {
  constructor(params) {
    super(params);

    this.type = CARD_EFFECT_TYPES.FRAGILE;
  }

  getTooltipTypes() {
    return [TOOLTIP.TYPES.FRAGILE];
  }

  afterExecute({ card }) {
    card.orderTrashing();
  }
}
