import { HEXAGON_HEIGHT, HEXAGON_RADIUS, HEXAGON_SIZE } from 'game/consts/layout.js';
import { Z_INDEX } from 'game/consts/z-index.js';
import { Point } from 'game/data-types/point.js';
import { ExpandingRing } from 'game/entities/particles/expanding-ring.js';
import { perFrameSpeed } from 'game/helpers/converters.js';
import { Background } from 'game/interface/background.js';
import { ControlledKingdomInterface } from 'game/interface/controlled-kingdom.js';
import { CycleBar } from 'game/interface/cycle-bar.js';
import { EndMessage } from 'game/interface/groups/end-message.js';
import { PauseMenu } from 'game/interface/groups/pause-menu.js';
import { SettingsMenu } from 'game/interface/groups/settings-menu.js';
import { InfoUnderBar } from 'game/interface/info-under-bar.js';
import { MapScrolling } from 'game/interface/map-scrolling.js';
import { StatusInfo } from 'game/interface/status-info.js';
import * as PIXI from 'pixi.js';

import { FadingOutCircle } from '../entities/particles/fading-out-circle.js';
import { HexagonalParticle } from '../entities/particles/hexagonal-particle.js';
import { Sprinkle } from '../entities/particles/sprinkle.js';
import { FixedCardsButton } from '../interface/buttons/fixed-cards.js';
import { RandomCardsButton } from '../interface/buttons/random-cards.js';
import { FocusArea } from '../interface/elements/focus-area.js';
import { FixedMarketBoard } from '../interface/fixed-market-board.js';
import { MessageModal } from '../interface/groups/message-modal.js';
import { Tooltip } from '../interface/groups/tooltip.js';
import { RandomMarketBoard } from '../interface/random-market-board.js';

const MOVE_SPEED = perFrameSpeed(1000);
const ZOOMIG_CHANGE = 1.03;

export class InterfaceController {
  constructor(game) {
    this.game = game;
    this.stage = this.game.pixiApp.stage;

    this.xViewport = 0;
    this.yViewport = 0;

    this.zoom = 0.5;

    this.movingLeft = false;
    this.movingRight = false;
    this.movingTop = false;
    this.movingBottom = false;

    this.zoomingIn = false;
    this.zoomingOut = false;

    this.interactive = true;

    this.fixedMarketOpen = false;
    this.randomMarketOpen = false;

    this.mousePositionFromCenter = null;
  }

  createInterface() {
    this.background = new Background({ game: this.game });
    this.cycleBar = new CycleBar({ game: this.game });
    this.infoUnderBar = new InfoUnderBar({ game: this.game });

    this.mapScrolling = new MapScrolling({ game: this.game });
    this.statusInfo = new StatusInfo({ game: this.game });

    this.fixedCardsButton = new FixedCardsButton({ game: this.game });
    this.randomCardsButton = new RandomCardsButton({ game: this.game });

    this.fixedMarketBoard = new FixedMarketBoard({ game: this.game });
    this.randomMarketBoard = new RandomMarketBoard({ game: this.game });

    this.endMessage = new EndMessage({ game: this.game });
    this.pauseMenu = new PauseMenu({ game: this.game });
    this.settingsMenu = new SettingsMenu({ game: this.game });

    this.tooltip = new Tooltip({ game: this.game });

    /*
    this.focusArea = new FocusArea({ game: this.game, visible: true, x: 300, y: 300 });
    this.messageModal = new MessageModal({ game: this.game });
    this.messageModal.showMessage('tetst');*/
  }

  createMainKingdomInterface() {
    this.controlledKingdomInterface = new ControlledKingdomInterface({
      game: this.game,
      kingdom: this.game.kingdomsController.getMainKingdom(),
    });
  }

  makeMaingKingdomInterfaceInteractive() {
    this.controlledKingdomInterface.makeInteractive();
  }

  setInteractive(value) {
    this.interactive = value;
  }

  leftOffset() {
    return this.game.mapController.sideSize * HEXAGON_HEIGHT * 2;
  }

  topOffset() {
    return this.game.mapController.sideSize * HEXAGON_RADIUS * 1.5;
  }

  getZoom() {
    return this.zoom;
  }

  setMovingLeft(value) {
    this.movingLeft = value;
  }

  setMovingRight(value) {
    this.movingRight = value;
  }

  setMovingUp(value) {
    this.movingUp = value;
  }

  setMovingDown(value) {
    this.movingDown = value;
  }

  setZoomingOut(value) {
    this.zoomingOut = value;
  }

  setZoomingIn(value) {
    this.zoomingIn = value;
  }

  scaleViewPoint(scale) {
    this.xViewport *= scale;
    this.yViewport *= scale;

    this.xViewport += (window.innerWidth / 2) * (1 - scale);
    this.yViewport += (window.innerHeight / 2) * (1 - scale);

    this.setSpriteContainerPosition();
  }

  onMouseWheelUp = () => {
    if (!this.interactive) return false;

    const change = this.changeZoom(1.1);

    if (!this.mousePositionFromCenter || change == 1) return;

    this.xViewport -= this.mousePositionFromCenter.x * (change - 1);
    this.yViewport -= this.mousePositionFromCenter.y * (change - 1);

    this.minMaxScreen();
    this.setSpriteContainerPosition();
  };

  onMouseWheelDown = () => {
    if (!this.interactive) return false;

    const change = this.changeZoom(1 / 1.1);

    if (!this.mousePositionFromCenter || change == 1) return;

    this.xViewport += this.mousePositionFromCenter.x * (1 - change);
    this.yViewport += this.mousePositionFromCenter.y * (1 - change);

    this.minMaxScreen();
    this.setSpriteContainerPosition();
  };

  onMouseMove = ({ position }) => {
    this.mousePositionFromCenter = position
      .clone()
      .addX(-this.game.pixiApp.screen.width / 2)
      .addY(-this.game.pixiApp.screen.height / 2);
  };

  changeZoom = (change) => {
    if (change === 1) return;

    const startingZoom = this.zoom;

    this.zoom *= change;

    if (this.zoom > 1) this.zoom = 1;
    if (this.zoom < 0.1) this.zoom = 0.1;

    const difference = this.zoom / startingZoom;

    if (difference === 1) return 1;

    this.mapContainer.scale.set(this.zoom);
    this.mapInterfaceContainer.scale.set(this.zoom);
    this.scaleViewPoint(difference);

    this.game.entitiesController.onZoom(this.zoom);

    return difference;
  };

  setZoom = (value) => {
    this.changeZoom(value / this.zoom);
  };

  configure() {
    this.createContainers();
    this.setViewportToCenter();
    this.registerEventListeners();
  }

  setViewportToCenter() {
    this.xViewport = this.game.pixiApp.screen.width / 2 - this.leftOffset() * this.zoom;
    this.yViewport = this.game.pixiApp.screen.height / 2 - this.topOffset() * this.zoom;

    this.setSpriteContainerPosition();
  }

  createContainers() {
    this.mainContainer = new PIXI.Container();
    this.mainContainer.sortableChildren = true;

    this.mapContainer = new PIXI.Container();
    this.mapContainer.scale.set(this.zoom);
    this.mapContainer.sortableChildren = true;
    this.mapContainer.zIndex = Z_INDEX.MAP;

    this.mapInterfaceContainer = new PIXI.Container();
    this.mapInterfaceContainer.scale.set(this.zoom);
    this.mapInterfaceContainer.sortableChildren = true;
    this.mapInterfaceContainer.zIndex = Z_INDEX.MAP_INTERFACE;

    this.stage.addChild(this.mainContainer);

    this.mainContainer.addChild(this.mapContainer);
    this.mainContainer.addChild(this.mapInterfaceContainer);

    this.setSpriteContainerPosition();
  }

  setSpriteContainerPosition() {
    this.mapContainer.x = this.xViewport;
    this.mapContainer.y = this.yViewport;

    this.mapInterfaceContainer.x = this.xViewport;
    this.mapInterfaceContainer.y = this.yViewport;
  }

  registerEventListeners() {
    this.game.eventsController.addListener('escKeyDown', this, { priority: -1 });
    this.game.eventsController.addListener('mouseWheelUp', this);
    this.game.eventsController.addListener('mouseWheelDown', this);
    this.game.eventsController.addListener('mouseMove', this);

    this.game.eventsController.addListener('fixedCardsButtonClick', this);
    this.game.eventsController.addListener('randomCardsButtonClick', this);

    this.game.eventsController.addListener('createStructure', this);
    this.game.eventsController.addListener('mineExplosion', this);
    this.game.eventsController.addListener('playerPlayCardOnField', this);
    this.game.eventsController.addListener('beforeBuildingDestroy', this);

    this.game.eventsController.addListener('focusOnMainHeadquarter', this);
  }

  onEscKeyDown = () => {
    if (this.isAnyMarketOpen()) return this.closeMarkets();

    if (this.game.isPaused()) {
      this.unpauseGame();
    } else {
      this.pauseGame();
    }
  };

  pauseGame = () => {
    this.setInteractive(false);
    this.pauseMenu.open();
    if (this.game.advancedLogicPart && !this.game.isMultiplayer) this.game.pause();
  };

  unpauseGame = () => {
    this.setInteractive(true);
    this.pauseMenu.close();
    if (this.game.advancedLogicPart && !this.game.isMultiplayer) this.game.resume();
  };

  onEliminate = () => {
    this.endMessage.showYouLost();
  };

  onWin = () => {
    this.endMessage.showYouWon();
  };

  onEnd = (message) => {
    this.pauseGame();

    this.endMessage.showCustom(message);
  };

  createImageSprite(name) {
    const sprite = this.game.texturesManager.createStandardSprite(name);

    this.mapContainer.addChild(sprite);

    return sprite;
  }

  registerSprite(sprite) {
    this.mainContainer.addChild(sprite);
  }

  removeSprite(sprite) {
    this.mainContainer.removeChild(sprite);
  }

  registerMapInterfaceSprite(sprite) {
    this.mapInterfaceContainer.addChild(sprite);
  }

  removeMapInterfaceSprite(sprite) {
    this.mapInterfaceContainer.removeChild(sprite);
  }

  registerMapSprite(sprite) {
    this.mapContainer.addChild(sprite);
  }

  removeMapSprite(sprite) {
    this.mapContainer.removeChild(sprite);
  }

  activate(delta) {
    if (!this.interactive) return false;

    this.moveScreen(delta);
    this.updateZoom(delta);
  }

  moveScreenByPoint(point) {
    this.xViewport += point.x;
    this.yViewport += point.y;

    this.minMaxScreen();

    this.setSpriteContainerPosition();
  }

  minMaxScreen() {
    const minX = this.game.pixiApp.screen.width / 2 - 2 * this.leftOffset() * this.zoom;
    const maxX = this.game.pixiApp.screen.width / 2;
    const minY = this.game.pixiApp.screen.height / 2 - 2 * this.topOffset() * this.zoom;
    const maxY = this.game.pixiApp.screen.height / 2;

    if (this.xViewport < minX) this.xViewport = minX;
    if (this.xViewport > maxX) this.xViewport = maxX;
    if (this.yViewport < minY) this.yViewport = minY;
    if (this.yViewport > maxY) this.yViewport = maxY;
  }

  moveScreen(delta) {
    const positionChange = MOVE_SPEED * this.zoom * delta;

    if (this.movingLeft) this.xViewport += positionChange;
    if (this.movingRight) this.xViewport -= positionChange;
    if (this.movingUp) this.yViewport += positionChange;
    if (this.movingDown) this.yViewport -= positionChange;

    this.minMaxScreen();

    this.setSpriteContainerPosition();
  }

  onFocusOnMainHeadquarter = () => {
    this.focusOnMainHeadquarter();
  };

  focusOnMainHeadquarter() {
    const mainKingdom = this.game.kingdomsController.getMainKingdom();
    if (!mainKingdom) return;

    const headquarter = mainKingdom.getHeadquarter();
    if (!headquarter) return;

    this.moveScreenToPoint(headquarter.getPosition());
  }

  moveScreenToPoint(point) {
    this.xViewport = this.game.pixiApp.screen.width / 2 - point.x * this.zoom;
    this.yViewport = this.game.pixiApp.screen.height / 2 - point.y * this.zoom;

    this.setSpriteContainerPosition();
  }

  updateZoom(delta) {
    let change = 1;

    if (this.zoomingIn) change *= Math.pow(ZOOMIG_CHANGE, delta);
    if (this.zoomingOut) change *= Math.pow(1 / ZOOMIG_CHANGE, delta);

    this.changeZoom(change);
  }

  globalPointToMapPoint(point) {
    const x = (point.x - this.xViewport) / this.zoom;
    const y = (point.y - this.yViewport) / this.zoom;

    return new Point(x, y);
  }

  onCreateStructure = (params) => {
    const {
      structure: { mapField, owner },
    } = params;

    new ExpandingRing({ game: this.game, position: mapField.getPosition().clone(), kingdom: owner });
  };

  onMineExplosion = (params) => {
    const {
      mine: { range, mapField, owner },
    } = params;

    new FadingOutCircle({ game: this.game, radius: range * HEXAGON_SIZE, position: mapField.getPosition().clone(), color: owner?.color });
  };

  onPlayerPlayCardOnField = (params) => {
    const { field, color } = params;

    for (let i = 0; i < 10; i++) {
      new Sprinkle({ game: this.game, position: field.getPosition().clone(), offset: HEXAGON_RADIUS / 2, color });
    }
  };

  onBeforeBuildingDestroy = (params) => {
    const {
      structure: { mapField, owner },
    } = params;

    for (let i = 0; i < 6; i++) {
      new HexagonalParticle({
        game: this.game,
        position: mapField.getPosition().clone(),
        offset: HEXAGON_RADIUS / 10,
        color: owner?.color,
      });
    }
  };

  onFixedCardsButtonClick = () => {
    this.fixedMarketOpen = !this.fixedMarketOpen;
    this.fixedMarketBoard.setVisibility(this.fixedMarketOpen);

    if (this.fixedMarketOpen && this.randomMarketOpen) {
      this.randomMarketBoard.setVisibility(false);
      this.randomMarketOpen = false;
    } else {
      this.launchOpenCloseMarketEvent();
    }
  };

  onRandomCardsButtonClick = () => {
    this.randomMarketOpen = !this.randomMarketOpen;
    this.randomMarketBoard.setVisibility(this.randomMarketOpen);

    if (this.fixedMarketOpen && this.randomMarketOpen) {
      this.fixedMarketBoard.setVisibility(false);
      this.fixedMarketOpen = false;
    } else {
      this.launchOpenCloseMarketEvent();
    }
  };

  launchOpenCloseMarketEvent() {
    this.game.eventsController.runEvent(this.isAnyMarketOpen() ? 'openMarket' : 'closeMarket');
  }

  closeMarkets() {
    const wasOpen = this.isAnyMarketOpen();

    this.randomMarketOpen = false;
    this.randomMarketBoard.setVisibility(false);

    this.fixedMarketOpen = false;
    this.fixedMarketBoard.setVisibility(false);

    if (wasOpen) this.game.eventsController.runEvent('closeMarket');
  }

  isAnyMarketOpen() {
    return this.randomMarketOpen || this.fixedMarketOpen;
  }
}
