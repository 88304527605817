import { CLIENT_DELAY, FRAMES_DELAY } from 'game/consts/game.js';
import { Timer } from 'game/data-types/timer.js';
import { sleep } from 'game/helpers/sleep.js';
import Config from 'helpers/config.js';
import { Peer } from 'peerjs';

export class ClientConnectionController {
  constructor(game) {
    this.game = game;

    this.packets = [];

    this.updates = [];

    this.timer = new Timer();
  }

  createPeer() {
    this.peer = new Peer(undefined, { host: Config.peerHost, port: Config.peerPort, path: '/peerjs' });
  }

  connectToHost = async (id) => {
    await sleep(3000);

    this.hostConnection = this.peer.connect(id, { metadata: { playerId: this.game.playerId } });

    if (!this.hostConnection) return console.log('connection error');

    this.hostConnection.on('open', () => {
      this.hostConnection.send({ type: 'client:getCurrentState' });
    });

    this.hostConnection.on('data', this.handleDataReceive);

    this.hostConnection.on('error', () => {
      console.log('error');
      this.game.destroy({ title: 'Connection Error', message: 'Something went wrong, sorry.' });
    });

    this.hostConnection.on('close', () => {
      console.log('close');
      this.game.destroy({ title: 'Connection closed', message: 'Host disconnected.' });
    });
  };

  handleDataReceive = (response) => {
    switch (response.type) {
      case 'host:currentState':
        return this.receiveCurrentState(response);
      case 'host:update':
        return this.saveUpdate(response);
      case 'host:cancelCardPlay':
        return this.onCancelCardPlay(response);
      case 'host:afterCardBuy':
        return this.afterCardBuyRequest(response);
    }
  };

  receiveCurrentState = (response) => {
    const {
      data,
      metadata: { timestap },
    } = response;

    this.timer.setOffsetByDiff(timestap);

    this.game.loadGameFromHost(data);
  };

  saveUpdate(update) {
    this.updates.push(update);
    this.updates.sort((a, b) => a.metadata.timestap - b.metadata.timestap);
  }

  activate(delta) {
    this.runUpdates();
  }

  runUpdates = () => {
    const currenTimestap = this.timer.getTimestamp() - CLIENT_DELAY;

    while (true) {
      const update = this.updates[0];

      if (!update || update.metadata.timestap > currenTimestap) return;

      const delta = (currenTimestap - update.metadata.timestap) / FRAMES_DELAY;

      this.runUpdate(update, delta);

      this.updates.shift();
    }
  };

  runUpdate = (update, delta) => {
    this.game.updateGameFromHost(update, delta);
  };

  onCancelCardPlay = (data) => {
    this.game.cancelCardPlay(data);
  };

  afterCardBuyRequest = (data) => {
    this.game.marketController.afterCardBuyRequest(data);
  };

  destroy() {
    if (this.peer) this.peer.destroy();
  }

  sendPlayCard(cardId, targets) {
    this.hostConnection.send({ type: 'client:playCard', data: { cardId, targets } });
  }

  sendTryRedraw() {
    this.hostConnection.send({ type: 'client:tryRedraw' });
  }

  sendChangeTarget(newTarget) {
    this.hostConnection.send({ type: 'client:changeTarget', data: { x: newTarget.x, y: newTarget.y } });
  }

  sendBuyCard(data, callbackData) {
    this.hostConnection.send({ type: 'client:buyCard', data, callbackData });
  }

  clearPackets() {
    this.packets = [];
  }

  ///

  pingServer() {
    const timer = new Timer();

    this.socket.emit('ping', () => {
      this.ping = timer.passed();
    });
  }

  getPing() {
    return this.ping;
  }
}
