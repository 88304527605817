import { HARD_CARD_LIMIT } from 'game/consts/gameplay.js';
import {
  BASE_CANNONS_INACCURACY,
  DEFENDER_NUMBER_OF_BULLETS,
  DEFENDER_RANGE,
  FORGE_FORTIFICATION,
  HEADQUARTER_INACCURACY_REDUCTION,
  MINE_DAMAGE,
  MINE_RANGE,
  POWER_PLANT_PRODUCTION,
  RANDOM_CANNON_INACCURACY,
  REINFORCEMENET_HITS,
  SHORT_RANGE_CANNON_RANGE,
} from 'game/consts/structures.js';
import { CARD_EFFECT_TYPES } from 'game/consts/types/card-effect.js';
import { CARD_TYPES } from 'game/consts/types/card.js';
import { joinAdjectives, pluralize } from 'game/helpers/text.js';

import { TOOLTIP } from '../consts/tooltip.js';
import { ERROR_TYPES } from '../consts/types/errors.js';

import { TEXT_TYPES } from './text-types.js';

export const ENGLISH_NAME = 'English';

export const ENGLISH_TEXTS = {
  CARD_TITLES: {
    [CARD_TYPES.SPARK]: 'Spark',
    [CARD_TYPES.SPREAD_SHOT]: 'Spread shot',
    [CARD_TYPES.SNIPER_FIRE]: 'Sniper shot',
    [CARD_TYPES.FORTIFICATE_OWN_FIELD]: 'Instant Wall',
    [CARD_TYPES.RANDOM_FORTIFICATIONS]: 'Fortificate what you can',
    [CARD_TYPES.JUNK]: 'Junk',
    [CARD_TYPES.PLACE_POWER_STATION]: 'Power station',
    [CARD_TYPES.ADD_ENERGY]: 'Power up',
    [CARD_TYPES.PLACE_BASIC_CANNON]: 'Basic cannon',
    [CARD_TYPES.PLACE_SNIPER_CANNON]: 'Sniper cannon',
    [CARD_TYPES.FORTIFICATE_AND_PLACE_REINFORCEMENT]: 'Reinforce',
    [CARD_TYPES.PLACE_FORGE]: 'Forge',
    [CARD_TYPES.IMPROVE_HEADQUARTER]: 'Slowly creeping',
    [CARD_TYPES.INVESTMENT]: 'Investment',
    [CARD_TYPES.FORTIFICATE_AROUND_HEADQUARTER]: 'Free fortifications',
    [CARD_TYPES.PLACE_MINE]: 'Mine',
    [CARD_TYPES.PLACE_MINES_RANDOMLY]: 'Minefield',
    [CARD_TYPES.PLACE_RANDOM_CANNONS_RANDOMLY]: 'Random random cannon',
    [CARD_TYPES.SINGLE_FIRE]: 'Single fire',
    [CARD_TYPES.DESTROY_BUILDING]: 'Demolition',
    [CARD_TYPES.VOID]: 'Void',
    [CARD_TYPES.VOID_TO_ALL]: 'Justice till the end',
    [CARD_TYPES.DESTROY_FIELD]: 'Decay',
    [CARD_TYPES.REDUCE_DRAIN_RANDOMLY]: 'Discount',
    [CARD_TYPES.PLACE_DEFENDER]: 'Defender',
    [CARD_TYPES.INCREASE_ENERGY_PRODUCTION]: 'Safe energy',
    [CARD_TYPES.SPREAD_SHOT_IMPROVING]: 'Suppressive fire',
    [CARD_TYPES.DRAW_CARDS]: 'Draw cards',
    [CARD_TYPES.TRASH_CARD]: 'Slimming',
    [CARD_TYPES.FAIR_SHOT]: 'Fair shots',
    [CARD_TYPES.FORTIFICATE_BORDERS]: 'Isolation',
    [CARD_TYPES.FORTIFICATE_STRUCTURES]: 'Trenches',
    [CARD_TYPES.POWER_SHOT]: 'Bulldozer',
    [CARD_TYPES.ENERGY_TO_BULLETS]: 'Blast',
    [CARD_TYPES.PLACE_SHORT_RANGE_CANNON]: 'Short-range cannon',
    [CARD_TYPES.FORTIFICATE_HEADQUARTER_AND_ADJACENTS]: 'Defence packet',
    [CARD_TYPES.INCREASE_CARD_LIMIT]: 'Overwhelming',
    [CARD_TYPES.SNIPER_SHOT_IMPROVING]: 'Slow finisher',
    [CARD_TYPES.TRASH_AND_COPY_CARD]: 'Last breath',
    [CARD_TYPES.ACTIVATE_CANNONS]: 'Overloading',
    [CARD_TYPES.REPLACE_HAND]: 'Replacement',
    [CARD_TYPES.ADD_JUNK_AND_ENERGY]: 'Not so free power',
  },
  CARD_EFFECT_DESCRIPTIONS: {
    [CARD_EFFECT_TYPES.DESTROY]: 'Destroy.',
    [CARD_EFFECT_TYPES.FRAGILE]: 'Fragile.',
    [CARD_EFFECT_TYPES.INCREASE_CARD_LIMIT]: 'Increase your cards limit by 1.',
    [CARD_EFFECT_TYPES.LIGHTWEIGHT]: 'Lightweight.',
    [CARD_EFFECT_TYPES.REFILL]: 'Refill.',
    [CARD_EFFECT_TYPES.REPLACE_HAND]: 'Discard all cands from your hand. Draw that many cards plus 1.',
    [CARD_EFFECT_TYPES.TRASH_CARD]: 'Trash a card from your hand.',
    [CARD_EFFECT_TYPES.UNPLAYABLE]: 'Unplayable.',
    [CARD_EFFECT_TYPES.PLACE_BASIC_CANNON]: 'Place a basic cannon on your empty tile',
    [CARD_EFFECT_TYPES.PLACE_DEFENDER]: 'Place a defender on your empty tile.',
    [CARD_EFFECT_TYPES.PLACE_FORGE]: 'Place a forge on your empty tile.',
    [CARD_EFFECT_TYPES.PLACE_MINE]: 'Place a mine on your empty tile.',
    [CARD_EFFECT_TYPES.PLACE_POWER_STATION]: 'Place a power station on your empty tile.',
    [CARD_EFFECT_TYPES.PLACE_SHORT_RANGE_CANNON]: 'Place a short-range cannon on your empty tile.',
    [CARD_EFFECT_TYPES.PLACE_SNIPER_CANNON]: 'Place a sniper cannon on your empty tile.',
    [CARD_EFFECT_TYPES.PLACE_WAREHOUSE]: 'Place a warehouse on your empty tile.',
    [CARD_EFFECT_TYPES.DESTROY_OWN_BUILDING]: 'Destroy your building.',
    [CARD_EFFECT_TYPES.DRAIN]: ({ drain }) => `Drain ${drain}.`,
    [CARD_EFFECT_TYPES.ADD_ENERGY]: ({ energy }) => `Add ${energy} energy.`,
    [CARD_EFFECT_TYPES.DRAW_CARDS]: ({ cards }) => `Draw ${cards} cards`,
    [CARD_EFFECT_TYPES.INCREASE_OWN_SHOTS]: ({ shotsIncrease }) => `Increase the number of shots of this card by ${shotsIncrease}.`,
    [CARD_EFFECT_TYPES.INCREASE_OWN_SPREAD]: ({ spreadIncrease }) => `Increase the spread of this card by ${spreadIncrease}.`,
    [CARD_EFFECT_TYPES.LIMITED_USAGE]: ({ usage }) => `Usage: ${usage}.`,
    [CARD_EFFECT_TYPES.ADD_JUNK]: ({ quantity }) => `Gain ${quantity} "Junk" ${pluralize('card', quantity)} to your discard.`,
    [CARD_EFFECT_TYPES.REDUCE_DRAIN_RANDOMLY]: ({ drainReduction }) =>
      `Reduce drain of a differently named random card in your deck by ${drainReduction}.`,
    [CARD_EFFECT_TYPES.TRASH_AND_COPY_CARD]: ({ copies }) =>
      `Trash a persistent card from your hand. Gain ${copies} fragile copies of this card.`,
    [CARD_EFFECT_TYPES.FORTIFICATE_AROUND_HEADQUARTER]: ({ fortifications }) =>
      `Add ${fortifications} fortifications to all fields adjacent to the headquarter.`,
    [CARD_EFFECT_TYPES.FORTIFICATE_BORDERS]: ({ fortifications }) => `Add ${fortifications} fortifications to all border fields.`,
    [CARD_EFFECT_TYPES.FORTIFICATE_HEADQUARTER_AND_ADJACENTS]: ({ fortifications }) =>
      `Add ${fortifications} fortifications to the headquarter's field and all own adjacent to it fields.`,
    [CARD_EFFECT_TYPES.FORTIFICATE_OWN_FIELD]: ({ fortifications }) => `Add ${fortifications} fortifications to your field.`,
    [CARD_EFFECT_TYPES.FORTIFICATE_STRUCTURES]: ({ fortifications }) =>
      `Add ${fortifications} fortifications to all your fields with buildings.`,
    [CARD_EFFECT_TYPES.RANDOM_FORTIFICATIONS]: ({ fortifications, fields }) =>
      `Add ${fortifications} fortifications to up to ${fields} your random empty fields.`,
    [CARD_EFFECT_TYPES.DESTROY_WEAKEST_FIELDS]: ({ numberOfFields }) => `Destroy ${numberOfFields} weakest fields of every enemy.`,
    [CARD_EFFECT_TYPES.POISON_TO_ALL]: ({ poison }) => `Apply poison ${poison} to all players.`,
    [CARD_EFFECT_TYPES.VOID_TO_ALL]: ({ voidPower }) => `Apply void ${voidPower} to all players.`,
    [CARD_EFFECT_TYPES.VOID_TO_OTHERS]: ({ voidPower }) => `Apply void ${voidPower} to other players.`,
    [CARD_EFFECT_TYPES.FORTIFICATE_AND_PLACE_REINFORCEMENT]: ({ fortifications }) =>
      `Place an reinforcement on your empty field and add ${fortifications} fortifications to that field.`,
    [CARD_EFFECT_TYPES.PLACE_MINES_RANDOMLY]: ({ numberOfMines }) => `Place ${numberOfMines} mines on your random empty tiles.`,
    [CARD_EFFECT_TYPES.PLACE_RANDOM_CANNONS_RANDOMLY]: ({ numberOfCannons }) =>
      `Place ${numberOfCannons} random cannons on your random empty tiles.`,
    [CARD_EFFECT_TYPES.ENERGY_TO_BULLETS]: ({ spread }) => `Spend all energy, fire a shot with spread ${spread} for every energy spent.`,
    [CARD_EFFECT_TYPES.FAIR_SHOT]: ({ shots }) => `Fire ${shots} sniper shots at every enemy headquarter.`,
    [CARD_EFFECT_TYPES.ACTIVATE_CANNONS]: ({ times }) => `Activate your cannons ${times} times.`,
    [CARD_EFFECT_TYPES.IMPROVE_HEADQUARTER]: ({ level }) =>
      `Increase spread, power, and piercing of the headquarter by ${level}. Descrease the inaccuracy by ${
        HEADQUARTER_INACCURACY_REDUCTION * 100
      }%.`,
    [CARD_EFFECT_TYPES.INCREASE_ENERGY_PRODUCTION]: ({ energyProduction }) => `Increase energy production by ${energyProduction}.`,
    [CARD_EFFECT_TYPES.MULTISHOT]: ({ power, spread }) => {
      const powerText = power ? ` and power ${power}` : '';
      return `Fire a multishot with spread ${spread}${powerText}.`;
    },
    [CARD_EFFECT_TYPES.SHOT]: ({ spread, power, piercing, inaccuracy, shots }) => {
      const sniperPart = inaccuracy === 0 ? 'sniper ' : '';
      const numberPart = shots > 1 ? `${shots} ` : `a `;
      const nounPart = pluralize('shot', shots);

      const adjectives = [];

      if (spread > 1) adjectives.push(`spread ${spread}`);
      if (power > 1) adjectives.push(`power ${power}`);
      if (piercing > 1) adjectives.push(`piercing ${piercing}`);
      if (inaccuracy !== 0 && inaccuracy !== BASE_CANNONS_INACCURACY) adjectives.push(`inaccuracy ${inaccuracy}`);

      const adjectivesPrefix = adjectives.length > 0 ? ' with ' : '';
      const adjectivesPart = joinAdjectives(adjectives);

      return `Fire ${numberPart}${sniperPart}${nounPart}${adjectivesPrefix}${adjectivesPart}.`;
    },
  },
  TOOLTIP_TEXTS: {
    [TOOLTIP.TYPES
      .VOID]: `<b>Void X</b> - every cycle, this effect inflicts damage on fields, beginning with the one farthest from the base, continuing until it has dealt a total of X damage.`,
    [TOOLTIP.TYPES.POISON]: `<b>Poison X</b> - XXX.`,
    [TOOLTIP.TYPES.LIGHTWEIGHT]: '<b>Lightweight</b> - this card is not counted when you draw any number of cards.',
    [TOOLTIP.TYPES.SPREAD]: '<b>Spread X</b> - fire X bullets a the same time.',
    [TOOLTIP.TYPES.REFILL]: '<b>Refill</b> - draw a card after playing this card.',
    [TOOLTIP.TYPES.SNIPER]: '<b>Sniper</b> - shot without any inaccuracy.',
    [TOOLTIP.TYPES.FORTIFICATIONS]: '<b>Fortifications X</b> - protect a field from X damage.',
    [TOOLTIP.TYPES.POWER_STATION]: `<b>Power station</b> - a building which produces ${POWER_PLANT_PRODUCTION} energy every redraw.`,
    [TOOLTIP.TYPES.WAREHOUSE]: `<b>Warehouse</b> - XXXX`,
    [TOOLTIP.TYPES.DRAIN]: '<b>Drain X</b> - card with this ability can be played only after paying X energy.',
    [TOOLTIP.TYPES
      .BASIC_CANNON]: `<b>Basic Cannon</b> - a cannon building that shots 1 bullet with ${BASE_CANNONS_INACCURACY} inaccuracy every cycle.`,
    [TOOLTIP.TYPES.SNIPER_CANNON]: '<b>Sniper Cannon</b> - a cannon building that shots 1 bullet without inaccuracy every cycle.',
    [TOOLTIP.TYPES.FORGE]: `<b>Forge</b> - a building that generates ${FORGE_FORTIFICATION} ${pluralize(
      'fortification',
      FORGE_FORTIFICATION
    )} to a random own field every cycle.`,
    [TOOLTIP.TYPES
      .MINE]: `<b>Mine</b> - a structure that detonates when its field is captured. On detonation it inflicts ${MINE_DAMAGE} damage to all fields up to ${MINE_RANGE} spaces away.`,
    [TOOLTIP.TYPES
      .RANDOM_CANNON]: `<b>Random Cannon</b> - a cannon building that shots 1 bullet with ${RANDOM_CANNON_INACCURACY} inaccuracy every cycle.`,
    [TOOLTIP.TYPES.PIERCING]: '<b>Piercing X</b> - allows bullet to go through up to X enemy fields.',
    [TOOLTIP.TYPES.MULTISHOT]: '<b>Multishot</b> - XXXX',
    [TOOLTIP.TYPES.POWER]: '<b>Power X</b> - allows bullet to deal up to X damage in total.',
    [TOOLTIP.TYPES
      .DEFENDER]: `<b>Defender</b> - a cannon building that shots ${DEFENDER_NUMBER_OF_BULLETS} bullets - one towarts every adjacent field, every cycle. The range is ${DEFENDER_RANGE} spaces.`,
    [TOOLTIP.TYPES
      .SHORT_RANGE_CANNON]: `<b>Short Range Cannon</b> - a cannon building that shots 1 bullet with ${BASE_CANNONS_INACCURACY} inaccuracy and ${SHORT_RANGE_CANNON_RANGE} spaces range every cycle.`,
    [TOOLTIP.TYPES.FRAGILE]: '<b>Temporary</b> - this card is destroyed after playing it or discarding it.',
    [TOOLTIP.TYPES.REINFORCEMENT]: `<b>Reinforcemenet</b> - a building that can take ${REINFORCEMENET_HITS} damage.`,
    [TOOLTIP.TYPES
      .WEAKEST_FIELD]: `<b>Weakest field</b> - a field selected starting with the fewest fortifications, and the farthest from the headquarter. Empty fields are picked first.`,
    [TOOLTIP.TYPES
      .INACCURACY]: `<b>Inacurracy</b> - the angle within which bullets may stray from the point of aim. The basic inaccuracy of cannons is ${BASE_CANNONS_INACCURACY}°.`,
    [TOOLTIP.TYPES
      .PERSISTENT]: `<b>Persistent</b> - card without limited usage (without <b>destroy</b>, <b>fragile</b> or <b>limited usage</b>).`,
    [TOOLTIP.TYPES.CANNONS]: `<b>Cannons</b> - a group of buildings that shot bullets.`,
    [TOOLTIP.TYPES
      .ACTIVATE]: `<b>Activate</b> - every cycle each building activates and do their main purpose (cannons shot, forges add fortification). Additional activation can be also forced by some effects.`,
  },
  INTERFACE_TEXTS: {
    [TEXT_TYPES.CANCEL]: 'Cancel',
    [TEXT_TYPES.PICK_FIELD]: 'Pick a field.',
    [TEXT_TYPES.PICK_CARD]: 'Pick a card.',
    [TEXT_TYPES.CHOOSE_NEW_TARGET]: 'Pick a new target.',
    [TEXT_TYPES.OVER_HARD_CARD_LIMIT]: `You can't have more than ${HARD_CARD_LIMIT} cards in hand.`,
    [TEXT_TYPES.YOU_WON]: 'You won.',
    [TEXT_TYPES.WELL_DONE]: 'Well done!',
    [TEXT_TYPES.GAME_OVER]: 'Game over.',
    [TEXT_TYPES.ELIMINATED]: 'You were eliminated.',
    [TEXT_TYPES.WATCH]: 'Watch',
    [TEXT_TYPES.QUIT]: 'Quit',
    [TEXT_TYPES.PAUSE]: 'Pause',
    [TEXT_TYPES.RESUME]: 'Resume',
    [TEXT_TYPES.SETTINGS]: 'Settings',
    [TEXT_TYPES.SOUND_VOLUME]: 'Sound volume',
    [TEXT_TYPES.BACK]: 'Back',
    [TEXT_TYPES.LANGUAGE]: 'Language',
  },
  ERROR_TEXTS: {
    [ERROR_TYPES.NOT_ENOUGH_ENERGY]: 'Not enought energy!',
    [ERROR_TYPES.NO_HEADQUARTER]: 'No headquarter!',
    [ERROR_TYPES.NO_EMPTY_FIELDS]: 'No empty field on the map!',
    [ERROR_TYPES.NOT_YOUR_FIELD]: 'This field is not yours!',
    [ERROR_TYPES.FIELD_NOT_EMPTY]: 'This field is not empty!',
  },
};
