import { HARD_CARD_LIMIT } from 'game/consts/gameplay.js';
import {
  BASE_CANNONS_INACCURACY,
  DEFENDER_NUMBER_OF_BULLETS,
  DEFENDER_RANGE,
  FORGE_FORTIFICATION,
  HEADQUARTER_INACCURACY_REDUCTION,
  MINE_DAMAGE,
  MINE_RANGE,
  POWER_PLANT_PRODUCTION,
  RANDOM_CANNON_INACCURACY,
  REINFORCEMENET_HITS,
  SHORT_RANGE_CANNON_RANGE,
} from 'game/consts/structures.js';
import { CARD_EFFECT_TYPES } from 'game/consts/types/card-effect.js';
import { CARD_TYPES } from 'game/consts/types/card.js';
import { joinAdjectives } from 'game/helpers/text.js';

import { TOOLTIP } from '../consts/tooltip.js';
import { ERROR_TYPES } from '../consts/types/errors.js';

import { TEXT_TYPES } from './text-types.js';

export const POLISH_NAME = 'Polski';

export const POLISH_TEXTS = {
  CARD_TITLES: {
    [CARD_TYPES.SPARK]: 'Iskra',
    [CARD_TYPES.SPREAD_SHOT]: 'Strzał z rozsiewem',
    [CARD_TYPES.SNIPER_FIRE]: 'Strzał snajperski',
    [CARD_TYPES.FORTIFICATE_OWN_FIELD]: 'Natychmiastowy mur',
    [CARD_TYPES.RANDOM_FORTIFICATIONS]: 'Fortifikuj co się da',
    [CARD_TYPES.JUNK]: 'Śmieć',
    [CARD_TYPES.PLACE_POWER_STATION]: 'Elektrownia',
    [CARD_TYPES.ADD_ENERGY]: 'Doładowanie',
    [CARD_TYPES.PLACE_BASIC_CANNON]: 'Podstawowe działo',
    [CARD_TYPES.PLACE_SNIPER_CANNON]: 'Działo snajperskie',
    [CARD_TYPES.FORTIFICATE_AND_PLACE_REINFORCEMENT]: 'Wzmocnienie',
    [CARD_TYPES.PLACE_FORGE]: 'Kuźnia',
    [CARD_TYPES.IMPROVE_HEADQUARTER]: 'Powolne zbrojenie',
    [CARD_TYPES.INVESTMENT]: 'Inwestycja',
    [CARD_TYPES.FORTIFICATE_AROUND_HEADQUARTER]: 'Darmowe fortyfikacje',
    [CARD_TYPES.PLACE_MINE]: 'Mina',
    [CARD_TYPES.PLACE_MINES_RANDOMLY]: 'Pole minowe',
    [CARD_TYPES.PLACE_RANDOM_CANNONS_RANDOMLY]: 'Losowe chaotyczne działo',
    [CARD_TYPES.SINGLE_FIRE]: 'Pojedyńczy strzał',
    [CARD_TYPES.DESTROY_BUILDING]: 'Wyburzenie',
    [CARD_TYPES.VOID]: 'Nicość',
    [CARD_TYPES.VOID_TO_ALL]: 'Sprawiedliwość do końca',
    [CARD_TYPES.DESTROY_FIELD]: 'Rozpad',
    [CARD_TYPES.REDUCE_DRAIN_RANDOMLY]: 'Zniżka',
    [CARD_TYPES.PLACE_DEFENDER]: 'Działo obronne',
    [CARD_TYPES.INCREASE_ENERGY_PRODUCTION]: 'Bezpieczna energia',
    [CARD_TYPES.SPREAD_SHOT_IMPROVING]: 'Ogień zaporowy',
    [CARD_TYPES.DRAW_CARDS]: 'Dobieranie kart',
    [CARD_TYPES.TRASH_CARD]: 'Odchudzenie',
    [CARD_TYPES.FAIR_SHOT]: 'Sprawiedliwy ostrzał',
    [CARD_TYPES.FORTIFICATE_BORDERS]: 'Izolacja',
    [CARD_TYPES.FORTIFICATE_STRUCTURES]: 'Okopy',
    [CARD_TYPES.POWER_SHOT]: 'Buldożer',
    [CARD_TYPES.ENERGY_TO_BULLETS]: 'Grad pocisków',
    [CARD_TYPES.PLACE_SHORT_RANGE_CANNON]: 'Działo krótkiego zasięgu',
    [CARD_TYPES.FORTIFICATE_HEADQUARTER_AND_ADJACENTS]: 'Pakiet obronny',
    [CARD_TYPES.INCREASE_CARD_LIMIT]: 'Przytłoczenie',
    [CARD_TYPES.SNIPER_SHOT_IMPROVING]: 'Powolne wykańczanie',
    [CARD_TYPES.TRASH_AND_COPY_CARD]: 'Ostatni oddech',
    [CARD_TYPES.ACTIVATE_CANNONS]: 'Przegrzanie',
    [CARD_TYPES.REPLACE_HAND]: 'Zastępstwo',
    [CARD_TYPES.ADD_JUNK_AND_ENERGY]: 'Niedarmowa energia',
  },
  CARD_EFFECT_DESCRIPTIONS: {
    [CARD_EFFECT_TYPES.DESTROY]: 'Zniszcz.',
    [CARD_EFFECT_TYPES.FRAGILE]: 'Kruchy.',
    [CARD_EFFECT_TYPES.INCREASE_CARD_LIMIT]: 'Zwiększ limit kart na ręce o 1.',
    [CARD_EFFECT_TYPES.LIGHTWEIGHT]: 'Lekki.',
    [CARD_EFFECT_TYPES.REFILL]: 'Uzupełenienie.',
    [CARD_EFFECT_TYPES.REPLACE_HAND]: 'Odrzuć wszystkie karty z ręki i dobierz tyle kart co odrzuconych plus 1.',
    [CARD_EFFECT_TYPES.TRASH_CARD]: 'Zniszcz wybraną kartę z ręki.',
    [CARD_EFFECT_TYPES.UNPLAYABLE]: 'Niegrywalne.',
    [CARD_EFFECT_TYPES.PLACE_BASIC_CANNON]: 'Zbuduj podstawowe działo na wolnym, własnym polu.',
    [CARD_EFFECT_TYPES.PLACE_DEFENDER]: 'Zbuduj działo obronne na wolnym, własnym polu.',
    [CARD_EFFECT_TYPES.PLACE_FORGE]: 'Zbuduj kuźnię na wolnym, własnym polu.',
    [CARD_EFFECT_TYPES.PLACE_MINE]: 'Umieść minę na wolnym, własnym polu.',
    [CARD_EFFECT_TYPES.PLACE_POWER_STATION]: 'Zbuduj elektrownię na wolnym, własnym polu.',
    [CARD_EFFECT_TYPES.PLACE_SHORT_RANGE_CANNON]: 'Zbuduj działo krótkiego zasięgu na wolnym, własnym polu.',
    [CARD_EFFECT_TYPES.PLACE_SNIPER_CANNON]: 'Zbuduj działo snajperskie na wolnym, własnym polu.',
    [CARD_EFFECT_TYPES.PLACE_WAREHOUSE]: 'Zbuduj magazyn na wolnym, własnym polu.',
    [CARD_EFFECT_TYPES.DESTROY_OWN_BUILDING]: 'Zniszcz swój wybrany budynek.',
    [CARD_EFFECT_TYPES.DRAIN]: ({ drain }) => `Zużycie ${drain}.`,
    [CARD_EFFECT_TYPES.ADD_ENERGY]: ({ energy }) => `Zyskaj ${energy} energii.`,
    [CARD_EFFECT_TYPES.DRAW_CARDS]: ({ cards }) => `Dobierz ${cards} ${pluralizeCards(cards)}`,
    [CARD_EFFECT_TYPES.INCREASE_OWN_SHOTS]: ({ shotsIncrease }) => `Zwiększ ilość strzałów tej karty o ${shotsIncrease}.`,
    [CARD_EFFECT_TYPES.INCREASE_OWN_SPREAD]: ({ spreadIncrease }) => `Zwiększ rozsiew tej karty o ${spreadIncrease}.`,
    [CARD_EFFECT_TYPES.LIMITED_USAGE]: ({ usage }) => `Użycia: ${usage}.`,
    [CARD_EFFECT_TYPES.ADD_JUNK]: ({ quantity }) => `Dodaj ${quantity} ${pluralizeCards(quantity)} "Śmieć" do stosu kart odrzuconych.`,
    [CARD_EFFECT_TYPES.REDUCE_DRAIN_RANDOMLY]: ({ drainReduction }) =>
      `Zmiejsz 'zużycie' losowej, inaczej nazwanej karty w talii o ${drainReduction}.`,
    [CARD_EFFECT_TYPES.TRASH_AND_COPY_CARD]: ({ copies }) =>
      `Zniszcz trwałą kartę z ręki. Zyskaj ${copies} ${pluralizeCopies(copies)} tej karty z dodaną cechą 'Kruchy'.`,
    [CARD_EFFECT_TYPES.FORTIFICATE_AROUND_HEADQUARTER]: ({ fortifications }) =>
      `Dodaj ${fortifications} ${pluralizeFortifications(fortifications)} do wszystkich pól sąsiadujących z centralą.`,
    [CARD_EFFECT_TYPES.FORTIFICATE_BORDERS]: ({ fortifications }) =>
      `Dodaj ${fortifications} ${pluralizeFortifications(fortifications)} do każdego granicznego pola.`,
    [CARD_EFFECT_TYPES.FORTIFICATE_HEADQUARTER_AND_ADJACENTS]: ({ fortifications }) =>
      `Dodaj ${fortifications} ${pluralizeFortifications(fortifications)} do pola z centralą i każdego włanego sąsiadującego z nią pola.`,
    [CARD_EFFECT_TYPES.FORTIFICATE_OWN_FIELD]: ({ fortifications }) =>
      `Dodaj ${fortifications} ${pluralizeFortifications(fortifications)} do wybranego pola.`,
    [CARD_EFFECT_TYPES.FORTIFICATE_STRUCTURES]: ({ fortifications }) =>
      `Dodaj ${fortifications} ${pluralizeFortifications(fortifications)} do każdego pola z budynkiem.`,
    [CARD_EFFECT_TYPES.RANDOM_FORTIFICATIONS]: ({ fortifications, fields }) =>
      `Dodaj ${fortifications} ${pluralizeFortifications(
        fortifications
      )} do maksymalnie ${fields} losowych, własnych, pustych ${pluralizeFields(fields)}.`,
    [CARD_EFFECT_TYPES.DESTROY_WEAKEST_FIELDS]: ({ numberOfFields }) =>
      `Zniszcz ${numberOfFields} najsłabszych ${pluralizeFields(numberOfFields)} każdego przeciwnika.`,
    [CARD_EFFECT_TYPES.POISON_TO_ALL]: ({ poison }) => `Nałoż truciznę ${poison} na każdego gracza.`,
    [CARD_EFFECT_TYPES.VOID_TO_ALL]: ({ voidPower }) => `Nałoż nicość ${voidPower} na każdego gracza.`,
    [CARD_EFFECT_TYPES.VOID_TO_OTHERS]: ({ voidPower }) => `Nałoż nicość ${voidPower} na każdego przeciwnika.`,
    [CARD_EFFECT_TYPES.FORTIFICATE_AND_PLACE_REINFORCEMENT]: ({ fortifications }) =>
      `Zbuduj podstawowe działo na wolnym, własnym polu i dodaj ${fortifications} ${pluralizeFortifications(fortifications)} do tego pola.`,
    [CARD_EFFECT_TYPES.PLACE_MINES_RANDOMLY]: ({ numberOfMines }) =>
      `Umieść ${numberOfMines} ${pluralizeMines(numberOfMines)} na własnych, losowych, wolnych polach.`,
    [CARD_EFFECT_TYPES.PLACE_RANDOM_CANNONS_RANDOMLY]: ({ numberOfCannons }) =>
      `Zbuduj ${numberOfCannons} ${pluralizeRandomCannons(numberOfCannons)} na własnych, losowych, wolnych polach.`,
    [CARD_EFFECT_TYPES.ENERGY_TO_BULLETS]: ({ spread }) =>
      `Wydaj całą energię, wystrzel pociski z rozsiewem ${spread} za każdą wydaną energię.`,
    [CARD_EFFECT_TYPES.FAIR_SHOT]: ({ shots }) => `Wystrzel ${shots} ${pluralizeSniperShots(shots)} każdą centralę przeciwników.`,
    [CARD_EFFECT_TYPES.ACTIVATE_CANNONS]: ({ times }) => `Aktywuj swoje działa ${times} ${pluralizeTimes(times)}.`,
    [CARD_EFFECT_TYPES.IMPROVE_HEADQUARTER]: ({ level }) =>
      `Zwiększ rozsiew, moc i przeszycie centrali o ${level} ${pluralizeLevels(level)}. Zmniejsz niedokładność o ${
        HEADQUARTER_INACCURACY_REDUCTION * 100
      }%.`,
    [CARD_EFFECT_TYPES.INCREASE_ENERGY_PRODUCTION]: ({ energyProduction }) => `Zwiększ produkcję energii o ${energyProduction}.`,
    [CARD_EFFECT_TYPES.MULTISHOT]: ({ power, spread }) => {
      const powerText = power ? ` i mocą ${power}` : '';
      return `Wystrzel symetryczną salwę z rozsiewem ${spread}${powerText}.`;
    },
    [CARD_EFFECT_TYPES.SHOT]: ({ spread, power, piercing, inaccuracy, shots }) => {
      const sniperPart = inaccuracy === 0 ? `${pluralizeSniper(shots)} ` : '';
      const numberPart = shots > 1 ? `${shots} ` : '';
      const nounPart = pluralizeShots(shots);

      const adjectives = [];

      if (spread > 1) adjectives.push(`rozsiewem ${spread}`);
      if (power > 1) adjectives.push(`mocą ${power}`);
      if (piercing > 1) adjectives.push(`przeszyciem ${piercing}`);
      if (inaccuracy !== 0 && inaccuracy !== BASE_CANNONS_INACCURACY) adjectives.push(`niedokładnością ${inaccuracy}`);

      const adjectivesPrefix = adjectives.length > 0 ? ' z ' : '';
      const adjectivesPart = joinAdjectives(adjectives, 'i');

      return `Wystrzel ${numberPart}${sniperPart}${nounPart}${adjectivesPrefix}${adjectivesPart}.`;
    },
  },
  TOOLTIP_TEXTS: {
    [TOOLTIP.TYPES
      .VOID]: `<b>Nicość X</b> - w każdym cyklu, zdaje obrażenia polom, zaczynająć od tych najdalej od centrali, do momentu aż zada X obrażeń.`,
    [TOOLTIP.TYPES.POISON]: `<b>Trucizna X</b> - XXX.`,
    [TOOLTIP.TYPES.LIGHTWEIGHT]: '<b>Lekki</b> - ta karta nie jest liczona podczas dobierania kart.',
    [TOOLTIP.TYPES.SPREAD]: '<b>Rozsiew X</b> - powoduje że strzał ma X pocisków na raz',
    [TOOLTIP.TYPES.REFILL]: '<b>Uzupełenienie</b> - po zagraniu karty z tą cechą, dobierz nową kartę.',
    [TOOLTIP.TYPES.SNIPER]: '<b>Snajperski</b> - strzał bez żadnej niedokładności.',
    [TOOLTIP.TYPES.FORTIFICATIONS]: '<b>Fortyfikacje X</b> - chronią pole przed X obrażeniami.',
    [TOOLTIP.TYPES.POWER_STATION]: `<b>Elektrownia</b> - budynek, który produkuje ${POWER_PLANT_PRODUCTION} ${pluralizeEnergy(
      POWER_PLANT_PRODUCTION
    )} przy każdym odnowieniu ręki.`,
    [TOOLTIP.TYPES.WAREHOUSE]: `<b>Magazyn</b> - XXXX`,
    [TOOLTIP.TYPES.DRAIN]: '<b>Zużycie X</b> - aby zagrać kartę z tą cechą musisz najpierw wydać X energii.',
    [TOOLTIP.TYPES
      .BASIC_CANNON]: `<b>Podstawowe działo</b> - działo, które strzela raz z niedokładnością ${BASE_CANNONS_INACCURACY}° co cykl.`,
    [TOOLTIP.TYPES.SNIPER_CANNON]: '<b>Działo snajperskie</b> - działo, które strzela bez niedokładności (0°) co cykl.',
    [TOOLTIP.TYPES.FORGE]: `<b>Kuźnia</b> - budynek który, co cykl, dodaje ${FORGE_FORTIFICATION} ${pluralizeFortifications(
      FORGE_FORTIFICATION
    )} do losowego, własnego pola.`,
    [TOOLTIP.TYPES
      .MINE]: `<b>Mina</b> - struktura, która detonuje się kiedy jej pole jest przejęte. Podczas detonacji zadaje ${MINE_DAMAGE} ${pluralizeDamage(
      MINE_DAMAGE
    )} w zasięgu ${MINE_RANGE} ${pluralizeFields2(MINE_RANGE)}.`,
    [TOOLTIP.TYPES
      .RANDOM_CANNON]: `<b>Chatyczne działo</b> - działo, które strzela raz co cykl z niedokładnością ${RANDOM_CANNON_INACCURACY}°`,
    [TOOLTIP.TYPES.PIERCING]: '<b>Przeszycie X</b> - pozwala pociskom przejść przez maksymalnie X wrogich pól.',
    [TOOLTIP.TYPES.MULTISHOT]: '<b>Symetryczna salwa</b> - XXXX',
    [TOOLTIP.TYPES.POWER]: '<b>Moc X</b> - pozwala pociskom zadać do X obrażeń.',
    [TOOLTIP.TYPES.DEFENDER]: `<b>Działo obronne</b> - działo, które strzela co cykl ${DEFENDER_NUMBER_OF_BULLETS} ${pluralizeTimes(
      DEFENDER_NUMBER_OF_BULLETS
    )} - raz w kierunku każdego sąsiedniego pola. Jego zasięg wynosi ${DEFENDER_RANGE} ${pluralizeFields2(DEFENDER_RANGE)}.`,
    [TOOLTIP.TYPES
      .SHORT_RANGE_CANNON]: `<b>Działo krótkiego zasięgu</b> - działo, które strzela co cykl z niedokładnością ${BASE_CANNONS_INACCURACY}° i zasięgiem ${SHORT_RANGE_CANNON_RANGE} ${pluralizeTimes(
      SHORT_RANGE_CANNON_RANGE
    )}.`,
    [TOOLTIP.TYPES.FRAGILE]: '<b>Kruchy</b> - karta z tą cechą jest niszczona po zagraniu lub odrzuceniu.',
    [TOOLTIP.TYPES.REINFORCEMENT]: `<b>Wzmocnienie</b> - budynek, który posiada ${REINFORCEMENET_HITS} ${pluralizeHealth(
      REINFORCEMENET_HITS
    )}.`,
    [TOOLTIP.TYPES
      .WEAKEST_FIELD]: `<b>Najsłabsze pole</b> - pole, które posiada najmniej fortyfikacji, jest najdalej od centrali. W pierszej kolejności wybierane są puste pola.`,
    [TOOLTIP.TYPES
      .INACCURACY]: `<b>Niedokładność</b> - kąt, w obrębie którego pociski mogą odchylać się od punktu celowania. Bazowa niedokładność dział to ${BASE_CANNONS_INACCURACY}° arc.`,
    [TOOLTIP.TYPES
      .PERSISTENT]: `<b>Trwała</b> - karta bez żadnego limitu użyć (bez '<b>zniszczenie</b>', '<b>kruchy</b>' or '<b>użycia: X</b>').`,
    [TOOLTIP.TYPES.CANNONS]: `<b>Działa</b> - grupa budynków, która co cykl wystrzeliwuje pociski.`,
    [TOOLTIP.TYPES
      .ACTIVATE]: `<b>Aktywuj</b> - co cykl każdy budynek jest aktywowany i wykonuje swoje standrowe działe (strzela, fortyfikuje itp.). Dodatkowe aktywowanie może być wymuszone przez efekt kart.`,
  },
  INTERFACE_TEXTS: {
    [TEXT_TYPES.CANCEL]: 'Anuluj',
    [TEXT_TYPES.PICK_FIELD]: 'Wybierz pole.',
    [TEXT_TYPES.PICK_CARD]: 'Wybierz kartę.',
    [TEXT_TYPES.CHOOSE_NEW_TARGET]: 'Wybierz nowy cel.',
    [TEXT_TYPES.OVER_HARD_CARD_LIMIT]: `Nie możesz mieć więcej niż ${HARD_CARD_LIMIT} kart na ręce.`,
    [TEXT_TYPES.YOU_WON]: 'Wygrałeś.',
    [TEXT_TYPES.WELL_DONE]: 'Dobra robota!',
    [TEXT_TYPES.GAME_OVER]: 'Koniec gry.',
    [TEXT_TYPES.ELIMINATED]: 'Zostałeś wyeliminowany.',
    [TEXT_TYPES.WATCH]: 'Oglądaj',
    [TEXT_TYPES.QUIT]: 'Wyjdź',
    [TEXT_TYPES.PAUSE]: 'Pauza',
    [TEXT_TYPES.RESUME]: 'Wznów',
    [TEXT_TYPES.SETTINGS]: 'Ustawienia',
    [TEXT_TYPES.SOUND_VOLUME]: 'Głośność dzwięków',
    [TEXT_TYPES.BACK]: 'Powrót',
    [TEXT_TYPES.LANGUAGE]: 'Język',
  },
  ERROR_TEXTS: {
    [ERROR_TYPES.NOT_ENOUGH_ENERGY]: 'Brak energii!',
    [ERROR_TYPES.NO_HEADQUARTER]: 'Brak centrali!',
    [ERROR_TYPES.NO_EMPTY_FIELDS]: 'Brak pustych pól na mapie!',
    [ERROR_TYPES.NOT_YOUR_FIELD]: 'To pole nie jest twoje!',
    [ERROR_TYPES.FIELD_NOT_EMPTY]: 'To pole nie jest puste!',
  },
};

function pluralizeTimes(quantity) {
  if (quantity === 1) return 'raz';
  return 'razy';
}

function pluralizeHealth(quantity) {
  if (quantity === 1) return 'punkt zdrowia';
  if (quantity >= 2 && quantity <= 4) return 'punkty zdrowia';

  return 'punktów zdrowia';
}

function pluralizeDamage(quantity) {
  if (quantity === 1) return 'obrażenie';
  if (quantity >= 2 && quantity <= 4) return 'obrażenia';

  return 'obrażeń';
}

function pluralizeFields2(quantity) {
  if (quantity === 1) return 'pola';
  return 'pól';
}

function pluralizeCards(quantity) {
  if (quantity === 1) return 'kartę';
  if (quantity >= 2 && quantity <= 4) return 'karty';

  return 'kart';
}

function pluralizeEnergy(quantity) {
  if (quantity === 1) return 'energię';
  if (quantity >= 2 && quantity <= 4) return 'energie';

  return 'energii';
}

function pluralizeCopies(quantity) {
  if (quantity === 1) return 'kopię';
  if (quantity >= 2 && quantity <= 4) return 'kopie';

  return 'kopii';
}

function pluralizeFortifications(quantity) {
  if (quantity === 1) return 'fortyfikację';
  if (quantity >= 2 && quantity <= 4) return 'fortyfikacje';

  return 'fortyfikacji';
}

function pluralizeFields(quantity) {
  if (quantity === 1) return 'pole';
  if (quantity >= 2 && quantity <= 4) return 'pola';

  return 'pól';
}

function pluralizeMines(quantity) {
  if (quantity === 1) return 'minę';
  if (quantity >= 2 && quantity <= 4) return 'miny';

  return 'min';
}

function pluralizeRandomCannons(quantity) {
  if (quantity === 1) return 'chaotyczne działo';
  if (quantity >= 2 && quantity <= 4) return 'chaotyczne działa';

  return 'chaotycznych dział';
}

function pluralizeSniper(quantity) {
  if (quantity === 1) return 'snajperski';
  if (quantity >= 2 && quantity <= 4) return 'snajperskie';

  return 'snajperskich';
}

function pluralizeShots(quantity) {
  if (quantity === 1) return 'pocisk';
  if (quantity >= 2 && quantity <= 4) return 'pociski';

  return 'pocisków';
}

function pluralizeSniperShots(quantity) {
  if (quantity === 1) return 'snajperski pocisk';
  if (quantity >= 2 && quantity <= 4) return 'snajperskie pociski';

  return 'snajperskich pocisków';
}

function pluralizeLevels(quantity) {
  if (quantity === 1) return 'poziom';
  if (quantity >= 2 && quantity <= 4) return 'poziomy';

  return 'poziomów';
}
