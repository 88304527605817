import { CARD_DATA } from 'game/card-data/index.js';
import { Card } from 'game/entities/card.js';
import { cloneObject } from 'game/helpers/data.js';

export class CardsCreator {
  constructor(game) {
    this.game = game;
  }

  createCard(params) {
    const { type, id } = params;

    return new Card({
      data: cloneObject(CARD_DATA[type]),
      game: this.game,
      id,
      ...params,
    });
  }

  fromSocketData(socketData, owner) {
    const { id, type, stats, energyCost } = socketData;

    const data = cloneObject(CARD_DATA[type]);

    if (typeof energyCost !== 'undefined') data.energyCost = energyCost;

    data.stats = { ...data.stats, ...stats };

    return new Card({
      id,
      data,
      owner,
      game: this.game,
    });
  }

  createCustomCard(cardData, kingdom, params) {
    const newCard = new Card({
      data: cardData,
      game: this.game,
      owner: kingdom,
      ...params,
    });

    return newCard;
  }

  createCopyOfCard(prototypeCard, kingdom, params) {
    const cardData = prototypeCard.getData();
    const { type } = cardData;

    const newCard = new Card({
      data: cloneObject(CARD_DATA[type]),
      game: this.game,
      owner: kingdom,
      position: prototypeCard.getPosition(),
      ...params,
    });

    newCard.showFront();

    return newCard;
  }

  generateDescription(cardData) {
    const { effectTypes = [], stats } = cardData;

    let description = '';

    const max = effectTypes.length;

    for (let i = 0; i < max; i++) {
      const effectType = effectTypes[i];
      description += this.game.cardEffectsManager.getDescriptionForEffectType(effectType, stats) + '\n';
    }

    return description;
  }
}
