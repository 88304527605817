import { TOOLTIP } from 'game/consts/tooltip.js';
import { CARD_EFFECT_TYPES } from 'game/consts/types/card-effect.js';

import { BaseCardEffect } from '../base.js';

export class FortificateBordersCardEffect extends BaseCardEffect {
  constructor(params) {
    super(params);

    this.type = CARD_EFFECT_TYPES.FORTIFICATE_BORDERS;
  }

  getTooltipTypes() {
    return [TOOLTIP.TYPES.FORTIFICATIONS];
  }

  execute({ kingdom, card }) {
    const { fortifications } = card.getStats();

    const fields = this.game.mapController.getAllFields().filter((field) => field.ownedBy(kingdom) && field.adjacentToEnemy());

    for (let i = 0; i < fields.length; i++) {
      fields[i].changeFortifications(fortifications);
    }
  }
}
