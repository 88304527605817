import { Game } from 'game';
import Config from 'helpers/config';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { LoadingOverview } from 'components/common/loading-overview';

import { resetToView } from 'store/temporary/slice';

import styles from './game.module.css';

export function GameView({ viewParams }) {
  const dispatch = useDispatch();

  const [readyToShow, setReadyToShow] = useState(false);

  const { id: playerId, token: playerToken, nick: playerNick } = useSelector((state) => state.main.player || {});

  const onEnd = (params) => {
    if (params.title || params.message) {
      dispatch(resetToView({ viewName: 'announcement', viewParams: params }));
    } else {
      dispatch(resetToView({ viewName: 'index' }));
    }
  };

  const onReady = () => {
    setReadyToShow(true);
  };

  const { isMultiplayer, isHost, difficulty, preparation = {} } = viewParams;

  useEffect(() => {
    let game;

    const initialize = async () => {
      game = new Game({
        difficulty,
        playerId,
        playerToken,
        playerNick,
        serverUrl: Config.serverUrl,
        isHost,
        isMultiplayer,
        preparation,
        cycleTime: preparation.cycleTime,
        onReady,
        onEnd,
      });

      await game.initialize();
    };

    initialize();

    return () => {
      game && game.destroy();
    };
  }, []);

  return (
    <>
      {!readyToShow && <LoadingOverview />}
      <div className={`${styles.container} ${!readyToShow && styles.opacity}`}>
        <div id="game-container"></div>
      </div>
    </>
  );
}
