import { COLORS } from 'game/consts/colors.js';
import { STRUCTURES } from 'game/consts/types/structures.js';
import { Kingdom } from 'game/entities/kingdom.js';
import { HeadquarterUpdaterKingdom } from 'game/entities/kingdoms/enemy/headquarter-updater.js';
import {
  BasicCannonKingdom,
  DefensiveKingdom,
  EscalatorKingdom,
  RandomCannonKingdom,
  RegularKingdom,
  SniperCannonKingdom,
  SniperKingdom,
  SpreadKingdom,
} from 'game/entities/kingdoms/enemy/index.js';
import { VoiderKingdom } from 'game/entities/kingdoms/enemy/voider.js';
import { HumanKingdom } from 'game/entities/kingdoms/human.js';
import { randomElement, randomize, removeRandom } from 'game/helpers/array.js';

import { Point } from '../data-types/point.js';

export class KingdomsController {
  constructor(game) {
    this.game = game;
    this.serverPart = game.serverPart;

    this.mainKingdom = null;
    this.kingdoms = [];
  }

  setupSingleplayer(params) {
    this.createSingleplayerKingdoms(params);
    this.createHeadquarters();
    this.setTargets();
    this.invokeKingdomsSetup();
  }

  setupHostMultiplayer(preparation, playerId) {
    const { players } = preparation;

    this.createMultiplayerKingdoms(players, playerId);
    this.createHeadquarters();
    this.setTargets();
    this.invokeKingdomsSetup();
  }

  invokeKingdomsSetup() {
    this.eachKingdom((kingdom) => kingdom.setup());
  }

  onStart() {
    this.eachKingdom((kingdom) => kingdom.orderDrawingToLimit());
  }

  createSingleplayerKingdoms(params) {
    const { difficulty } = params;

    const colors = this.getColors();

    this.mainKingdom = new HumanKingdom({ color: removeRandom(colors), difficulty, game: this.game, visible: true });

    this.kingdoms = [
      this.createRandomEnemy({ color: removeRandom(colors), difficulty, game: this.game }),
      this.createRandomEnemy({ color: removeRandom(colors), difficulty, game: this.game }),
      this.createRandomEnemy({ color: removeRandom(colors), difficulty, game: this.game }),
      this.createRandomEnemy({ color: removeRandom(colors), difficulty, game: this.game }),
      this.createRandomEnemy({ color: removeRandom(colors), difficulty, game: this.game }),
      this.mainKingdom,
    ];

    randomize(this.kingdoms);
  }

  randomEnemyClass() {
    return randomElement([
      BasicCannonKingdom,
      EscalatorKingdom,
      DefensiveKingdom,
      RandomCannonKingdom,
      RegularKingdom,
      SniperCannonKingdom,
      SniperKingdom,
      SpreadKingdom,
      VoiderKingdom,
      HeadquarterUpdaterKingdom,
    ]);
  }

  createRandomEnemy(params) {
    const klass = this.randomEnemyClass();
    return new klass(params);
  }

  getColors() {
    return [
      COLORS.KINGDOM.RED,
      COLORS.KINGDOM.BLUE,
      COLORS.KINGDOM.YELLOW,
      COLORS.KINGDOM.PURPLE,
      COLORS.KINGDOM.CYAN,
      COLORS.KINGDOM.GREEN,
      COLORS.KINGDOM.DARK_GRAY,
    ];
  }

  createMultiplayerKingdoms(players, hostPlayerId) {
    this.kingdoms = [];

    const colors = this.getColors();

    for (let i = players.length - 1; i >= 0; i--) {
      const player = players[i];
      const color = removeRandom(colors);
      const isMain = player.id === hostPlayerId;

      const kingdom = new HumanKingdom({ color, game: this.game, playerId: player.id, visible: isMain });

      if (isMain) this.mainKingdom = kingdom;

      this.kingdoms.push(kingdom);
    }

    randomize(this.kingdoms);
  }

  createHeadquarters() {
    const fields = this.game.mapController.getAllStartingFields();

    const numberOfKingdoms = this.kingdoms.length;

    const fieldIndexDiff = 6 / numberOfKingdoms;
    let fieldIndex = 0;

    for (let i = 0; i < numberOfKingdoms; i++) {
      const kingdom = this.kingdoms[i];

      const field = fields[Math.floor(fieldIndex)];

      field.changeOwner(kingdom);
      const headquarter = this.game.structuresCreator.setStructure(field, STRUCTURES.HEADQUARTER);
      kingdom.setHeadquarter(headquarter);

      fieldIndex += fieldIndexDiff;
    }
  }

  findKingdomById(kingdomId) {
    return this.kingdoms.find((kingdom) => kingdom.id === kingdomId);
  }

  findKingdomByPlayerId(playerId) {
    return this.kingdoms.find((kingdom) => kingdom.getPlayerId() === playerId);
  }

  setKingdomsTargets() {
    const centerPoint = this.game.mapController.getCenterHexPosition().toPoint();
    this.eachKingdom((kingdom) => {
      kingdom.setStartingTarget(centerPoint);
    });
  }

  setTargets() {
    const centerPoint = this.game.mapController.getCenterHexPosition().toPoint();
    this.eachKingdom((kingdom) => {
      kingdom.setStartingTarget(centerPoint);
    });
  }

  eachKingdom(callback) {
    this.kingdoms.forEach(callback);
  }

  areAllConnected() {
    for (let i = this.kingdoms.length - 1; i >= 0; i--) {
      if (!this.kingdoms[i].isConnected()) return false;
    }
    return true;
  }

  isAnyConnected() {
    for (let i = this.kingdoms.length - 1; i >= 0; i--) {
      if (this.kingdoms[i].isConnected()) return true;
    }
    return false;
  }

  toSocketData() {
    return this.kingdoms.map((kingdom) => kingdom.toSocketData());
  }

  loadFromSD(kingdomsData) {
    this.loadKingdomsFromSD(kingdomsData);
    this.loadCardsFromSD(kingdomsData);
  }

  loadKingdomsFromSD(kingdomsData) {
    const mainPlayerId = this.game.getPlayerId();
    this.kingdoms = kingdomsData.map((kingdomData) => {
      const { color, target, id, energy, energyProduction, canRedraw, redrawCounter, playerId, cardsToDraw } = kingdomData;
      const inMain = playerId === mainPlayerId;

      const kingdom = new Kingdom({
        game: this.game,
        color,
        id,
        target: Point.fromObject(target),
        energy,
        energyProduction,
        canRedraw,
        redrawCounter,
        playerId,
        cardsToDraw,
        visible: inMain,
      });

      if (inMain) this.mainKingdom = kingdom;

      return kingdom;
    });
  }

  getWinner() {
    const remainingKingomds = this.kingdoms.filter((kingdom) => !kingdom.isEliminated());

    if (remainingKingomds.length === 1) return remainingKingomds[0];

    return false;
  }

  loadCardsFromSD(kingdomsData) {
    if (!this.mainKingdom) return false;

    const kingdomData = kingdomsData.find((kingdomData) => kingdomData.id === this.mainKingdom.id);

    if (!kingdomData) return false;

    this.mainKingdom.cardsFromSocketData(kingdomData.cards);
  }

  getOtherActiveThan(kingdom) {
    return this.kingdoms.filter((searchedKingdom) => searchedKingdom.id !== kingdom.id && searchedKingdom.isActive());
  }

  returnKingdoms() {
    return this.kingdoms;
  }

  getKingdomById(id) {
    return this.returnKingdoms().find((kingdom) => kingdom && kingdom.id === id);
  }

  removeKingdomById(id) {
    this.kingdoms = this.kingdoms.filter((kingdom) => kingdom.id !== id);
    if (this.mainKingdom && this.mainKingdom.id === id) this.mainKingdom = null;
  }

  getMainKingdom() {
    return this.mainKingdom;
  }

  isMainKingdom(kingdom) {
    return kingdom.id === this.mainKingdom.id;
  }

  onGameStart() {
    this.mainKingdom.onGameStart();
  }
}
