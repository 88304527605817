import { CARD_TYPES } from 'game/consts/types/card.js';
import { KINGDOM_EFFECT_TYPES } from 'game/consts/types/kingdom-effect.js';
import { Z_INDEX } from 'game/consts/z-index.js';
import { Point } from 'game/data-types/point.js';
import { InterfaceBase } from 'game/interface/interface-base.js';
import { withLeftClick } from 'game/utils/with-left-click.js';
import * as PIXI from 'pixi.js';

const DISTANCE = 30;

const CURRENT_CARD_TYPE = CARD_TYPES.ADD_JUNK_AND_ENERGY;
const CURRENT_EFFECT_TYPE = KINGDOM_EFFECT_TYPES.VOID;

export class CheatsMenu extends InterfaceBase {
  constructor(params) {
    super(params);

    this.createSprite();
    this.setPosition();
  }

  setPosition() {
    this.position = new Point(window.innerWidth - 10, 10);

    this.sprites.container.x = this.position.x;
    this.sprites.container.y = this.position.y;
  }

  createSprite() {
    this.sprites.container = new PIXI.Container();
    this.sprites.container.zIndex = Z_INDEX.CHEAT_MENU;
    this.sprites.container.visible = false;

    this.yOffset = 0;

    this.addOption('Add 100 energy', this.onAddEnergyClick);
    this.addOption('End cycle', this.onEndCycleClick);
    this.addOption('Redraw', this.onRedrawClick);
    this.addOption('Draw 50', this.onDraw50);
    this.addOption('Add effect', this.onAddEffect);
    this.addOption('Add card', this.onAddCardClick);
    this.addOption('Add 1000 fortification', this.onAddFortification);
    this.addOption('Clear deck', this.onClearDeck);
    this.addOption('Generate card image', this.onGenerateCardImage);
    this.addOption('Testing mode', this.onTestingMode);

    this.registerSprite(this.sprites.container);
  }

  addOption(name, callback) {
    const nameAsKey = name.replaceAll(' ', '');

    const sprite = this.game.texturesManager.createOutlinedText(name);

    sprite.x = 0;
    sprite.y = this.yOffset * DISTANCE;
    sprite.anchor.set(1, 0);
    sprite.on('pointerdown', withLeftClick(callback));
    sprite.eventMode = 'static';
    sprite.cursor = 'pointer';

    this.sprites[nameAsKey] = sprite;
    this.sprites.container.addChild(sprite);

    this.yOffset++;
  }

  onAddEnergyClick = () => {
    this.game.kingdomsController.getMainKingdom().changeEnergy(100);
  };

  onEndCycleClick = () => {
    this.game.onCycleEnd();
  };

  onRedrawClick = () => {
    this.game.kingdomsController.getMainKingdom().orderRedraw();
  };

  onDraw50 = () => {
    this.game.kingdomsController.getMainKingdom().orderDrawingCards(50);
  };

  onAddCardClick = () => {
    const kingdom = this.game.kingdomsController.getMainKingdom();
    const card = kingdom.createCard({ type: CURRENT_CARD_TYPE });

    kingdom.addToHand(card);
  };

  onAddFortification = () => {
    const kingdom = this.game.kingdomsController.getMainKingdom();
    kingdom.getHeadquarter().getField().changeFortifications(1000);
  };

  onClearDeck = () => {
    const kingdom = this.game.kingdomsController.getMainKingdom();
    kingdom.trashDeck();
  };

  onAddEffect = () => {
    const kingdom = this.game.kingdomsController.getMainKingdom();

    kingdom.addEffect({ type: CURRENT_EFFECT_TYPE, power: 1 });
  };

  onGenerateCardImage = async () => {
    const card = this.game.cardsCreator.createCard({ type: CURRENT_CARD_TYPE });
    card.showFront();

    this.game.renderer.extract.canvas(card.frontContainer).toBlob((b) => {
      var a = document.createElement('a');

      document.body.append(a);

      a.download = this.game.textsController.getCardTitle(card.data.type).toLowerCase() + ' - card';
      a.href = URL.createObjectURL(b);
      a.click();
      a.remove();
    }, 'image/png');
  };

  onTestingMode = () => {
    const kingdom = this.game.kingdomsController.getMainKingdom();
    kingdom.trashDeck();
    kingdom.changeEnergy(100);
    kingdom.getHeadquarter().getField().changeFortifications(1000);

    const card = kingdom.createCard({ type: CURRENT_CARD_TYPE, kingdom });

    kingdom.addToHand(card);
  };

  setVisibility(value) {
    this.sprites.container.visible = value;
  }

  destroy() {
    super.destroy();
    this.removeSprite(this.sprites.container);
  }

  afterResize() {
    this.setPosition();
  }
}
