import { Sound, filters, sound } from '@pixi/sound';
import drawing1Sound from 'game/assets/sounds/drawing1.mp3';
import drawing2Sound from 'game/assets/sounds/drawing2.mp3';
import drawing3Sound from 'game/assets/sounds/drawing3.mp3';
import selectingCard1Sound from 'game/assets/sounds/selecting-card1.mp3';
import selectingCard2Sound from 'game/assets/sounds/selecting-card2.mp3';
import selectingCard3Sound from 'game/assets/sounds/selecting-card3.mp3';
import selectingField1Sound from 'game/assets/sounds/selecting-field1.mp3';
import selectingField2Sound from 'game/assets/sounds/selecting-field2.mp3';
import selectingField3Sound from 'game/assets/sounds/selecting-field3.mp3';
import shuffling1Sound from 'game/assets/sounds/shuffling1.mp3';
import shuffling2Sound from 'game/assets/sounds/shuffling2.mp3';
import shuffling3Sound from 'game/assets/sounds/shuffling3.mp3';
import { SOUNDS } from 'game/consts/sounds.js';
import { randomDecimal, randomIn } from 'game/helpers/random.js';

export class SoundsController {
  constructor(game) {
    this.game = game;

    this.sounds = {};

    sound.context.paused = true;
    sound.context.playEmptySound();

    this.loadSounds();
    this.handleEvents();
  }

  loadSounds() {
    this.loadSound(drawing1Sound, SOUNDS.DRAWING);
    this.loadSound(drawing2Sound, SOUNDS.DRAWING);
    this.loadSound(drawing3Sound, SOUNDS.DRAWING);
    this.loadSound(selectingCard1Sound, SOUNDS.SELECTING_CARD);
    this.loadSound(selectingCard2Sound, SOUNDS.SELECTING_CARD);
    this.loadSound(selectingCard3Sound, SOUNDS.SELECTING_CARD);
    this.loadSound(selectingField1Sound, SOUNDS.SELECTING_FIELD);
    this.loadSound(selectingField2Sound, SOUNDS.SELECTING_FIELD);
    this.loadSound(selectingField3Sound, SOUNDS.SELECTING_FIELD);
    this.loadSound(shuffling1Sound, SOUNDS.SHUFFLING);
    this.loadSound(shuffling2Sound, SOUNDS.SHUFFLING);
    this.loadSound(shuffling3Sound, SOUNDS.SHUFFLING);
  }

  loadSound(path, name) {
    if (!this.sounds[name]) this.sounds[name] = [];

    this.sounds[name].push(
      Sound.from({
        url: path,
        preload: true,
        singleInstance: true,
      })
    );
  }

  handleEvents() {
    window.addEventListener('focus', () => {
      const context = sound.context.audioContext.resume();
    });

    window.addEventListener('blur', () => {
      const context = sound.context.audioContext.suspend();
    });
  }

  playSound(name, params = {}) {
    const soundArray = this.sounds[name];

    if (!soundArray) return;
    if (soundArray.length < 1) return;

    const { speedRange = 0.4, equalizerRange = 5, soundIndex } = params;

    const sound = soundIndex ? soundArray[soundIndex] : randomIn(soundArray);

    if (!sound.isLoaded) return;

    sound.speed = speedRange ? randomDecimal(1 - speedRange, 1 + speedRange) : 1;
    sound.volume = this.game.settingsController.getSoundVolume();
    sound.filters = [
      new filters.EqualizerFilter(
        randomDecimal(-equalizerRange, equalizerRange),
        randomDecimal(-equalizerRange, equalizerRange),
        randomDecimal(-equalizerRange, equalizerRange),
        randomDecimal(-equalizerRange, equalizerRange),
        randomDecimal(-equalizerRange, equalizerRange),
        randomDecimal(-equalizerRange, equalizerRange),
        randomDecimal(-equalizerRange, equalizerRange),
        randomDecimal(-equalizerRange, equalizerRange),
        randomDecimal(-equalizerRange, equalizerRange),
        randomDecimal(-equalizerRange, equalizerRange)
      ),
    ];

    sound.play();
  }
}
