import { Z_INDEX } from 'game/consts/z-index.js';
import { EntityBase } from 'game/entities/entity-base.js';
import { perFrameTimer } from 'game/helpers/converters.js';
import { Graphics } from 'pixi.js';

const LIFE_TIME = perFrameTimer(1);

const STARTING_ALPHA = 0.5;

export class FadingOutCircle extends EntityBase {
  constructor(params) {
    super(params);

    this.sprites = {};

    this.lifeTime = params.lifeTime || LIFE_TIME;
    this.lifeTimeTimer = this.lifeTime;

    this.createSprite(params);
  }

  createSprite(params) {
    const { position, radius, color } = params;

    this.sprites.circle = new Graphics().circle(0, 0, radius).fill(color);

    this.sprites.circle.x = position.x;
    this.sprites.circle.y = position.y;

    this.sprites.circle.zIndex = Z_INDEX.PARTICLES;
    if (color) this.sprites.circle.tint = color;

    this.registerMapSprite(this.sprites.circle);
  }

  activate(delta) {
    this.calculateLifeTimeTimer(delta);
    this.calculateAlpha();
  }

  calculateLifeTimeTimer(delta) {
    this.lifeTimeTimer -= delta;
    if (this.lifeTimeTimer <= 0) this.destroy();
  }

  calculateAlpha() {
    this.sprites.circle.alpha = STARTING_ALPHA - (1 - this.lifeTimeTimer / this.lifeTime) * (1 - STARTING_ALPHA);
  }

  destroy() {
    super.destroy();
    this.removeMapSprite(this.sprites.circle);
  }
}
