import { IMAGES } from 'game/consts/types/images.js';
import { InterfaceElement } from 'game/interface/elements/interface-element.js';

export class OptionSwitcher extends InterfaceElement {
  constructor(params) {
    super(params);

    this.onChange = params.onChange;

    this.fontSize = params.fontSize || 20;

    this.width = params.width || 300;
    this.height = 30;
    this.horizontalMargins = params.horizontalMargins || 10;

    this.workingWidth = this.width - this.horizontalMargins * 2;

    this.options = params.options;
    this.currentOptionIndex = this.findOptionIndex(params.startingOption);

    this.createSprites();
    this.updateInterface();
    this.setVisibility(params.visible || false);
  }

  findOptionIndex(option) {
    const index = this.options.findIndex((searchedOption) => searchedOption === option);
    return index >= 0 ? index : 0;
  }

  getCurrentOption() {
    return this.options[this.currentOptionIndex];
  }

  createSprites() {
    this.createContainer();
    this.createButtons();
    this.createCurrentOption();
  }

  createButtons() {
    this.sprites.leftButton = this.game.texturesManager.createSprite(IMAGES.INTERFACE.BUTTONS.LEFT);
    this.sprites.leftButton.anchor.set(0, 0.5);
    this.sprites.leftButton.x = -this.workingWidth / 2;
    this.sprites.leftButton.eventMode = 'static';
    this.sprites.leftButton.cursor = 'pointer';
    this.sprites.leftButton.on('pointerdown', this.onClickLeft);

    this.sprites.rightButton = this.game.texturesManager.createSprite(IMAGES.INTERFACE.BUTTONS.LEFT);
    this.sprites.rightButton.anchor.set(0, 0.5);
    this.sprites.rightButton.x = this.workingWidth / 2;
    this.sprites.rightButton.angle = 180;
    this.sprites.rightButton.eventMode = 'static';
    this.sprites.rightButton.cursor = 'pointer';
    this.sprites.rightButton.on('pointerdown', this.onClickRight);

    this.sprites.container.addChild(this.sprites.leftButton);
    this.sprites.container.addChild(this.sprites.rightButton);
  }

  createCurrentOption() {
    this.sprites.currentOption = this.game.texturesManager.createText('', {
      fontSize: this.fontSize,
      fontWeight: 'bold',
    });
    this.sprites.currentOption.anchor.set(0.5, 0.5);

    this.sprites.container.addChild(this.sprites.currentOption);
  }

  onClickLeft = () => {
    this.currentOptionIndex -= 1;
    this.limitIndex();
    this.onChange(this.getCurrentOption());
    this.updateInterface();
  };

  onClickRight = () => {
    this.currentOptionIndex += 1;
    this.limitIndex();
    this.onChange(this.getCurrentOption());
    this.updateInterface();
  };

  limitIndex() {
    const max = this.options.length - 1;

    if (this.currentOptionIndex > max) this.currentOptionIndex = 0;
    if (this.currentOptionIndex < 0) this.currentOptionIndex = max;
  }

  updateInterface() {
    this.sprites.currentOption.text = this.getCurrentOption();
  }
}
