import { FORGE_FORTIFICATION } from 'game/consts/structures.js';
import { IMAGES } from 'game/consts/types/images.js';
import { STRUCTURES } from 'game/consts/types/structures.js';
import { randomize } from 'game/helpers/array.js';

import { BaseBuilding } from './base.js';

export class ForgeBuilding extends BaseBuilding {
  constructor(params) {
    super({ ...params, uncolorable: true });

    this.type = STRUCTURES.FORGE;
  }

  getSpriteName() {
    return IMAGES.STRUCTURES.FORGE;
  }

  onCycleEnd() {
    if (this.owner && this.advancedLogicPart) this.increaseFortification();
  }

  increaseFortification() {
    const allFields = this.game.mapController.getAllFields().filter((field) => field.ownedBy(this.owner));

    const randomField = randomize(allFields)[0];

    if (!randomField) return;

    randomField.changeFortifications(FORGE_FORTIFICATION);
  }
}
