import { Point } from 'game/data-types/point.js';
import { Timer } from 'game/data-types/timer.js';
import Config from 'helpers/config.js';
import { Peer } from 'peerjs';

export class HostConnectionController {
  constructor(game) {
    this.game = game;

    this.packets = [];

    this.timer = new Timer();

    this.publicEvents = null;
    this.privateEvents = null;

    this.otherPlayersIds = [];

    this.connectedPlayers = [];
  }

  resetEvents() {
    this.publicEvents = {
      changedFields: [],
      newEntities: [],
      destroyedEntities: [],
      kingdomUpdates: [],
      cycleEnd: false,
      status: null,
      countdownTimer: null,
      message: null,
      gameEnd: false,
    };

    this.privateEvents = {};

    this.otherPlayersIds.forEach((playerId) => {
      this.privateEvents[playerId] = {
        drawnCards: [],
        discardedCards: [],
        returnedCards: [],
        trashedCards: [],
        updatedCards: [],
        triggerShuffiling: false,
        kingdomUpdate: false,
        kingdomResearch: false,
        addEffects: [],
        updateEffects: [],
        removeEffects: [],
      };
    });
  }

  isHostId(playerId) {
    return this.game.playerId === playerId;
  }

  prepare(playerId, preparation) {
    const { hostPeerId, players } = preparation;

    this.saveOtherPlayersIds(playerId, players);
    this.resetEvents();
    this.createPeer(hostPeerId);
    this.handleConnections();
  }

  saveOtherPlayersIds(playerId, players) {
    this.otherPlayersIds = players.map((player) => player.id).filter((id) => id != playerId);
  }

  createPeer(id) {
    this.peer = new Peer(id, { host: Config.peerHost, port: Config.peerPort, path: '/peerjs' });
  }

  handleConnections() {
    this.peer.on('connection', async (connection) => {
      const player = { connection, id: connection.metadata.playerId };

      this.connectedPlayers.push(player);

      connection.on('data', (response) => this.handleDataReceive(player, response));
    });
  }

  handleDataReceive = (player, response) => {
    switch (response.type) {
      case 'client:getCurrentState':
        return player.connection.send({
          type: 'host:currentState',
          data: this.generateStartingData(),
          metadata: this.generateStartingMetadata(),
        });
      case 'client:playCard':
        return this.onIncommingPlayCard(player, response.data);
      case 'client:tryRedraw':
        return this.onIncommingTryRedraw(player, response.data);
      case 'client:changeTarget':
        return this.onIncommingChangeTarget(player, response.data);
      case 'client:buyCard':
        return this.onIncommingBuyCard(player, response.data, response.callbackData);
    }
  };

  generateStartingData() {
    return {
      map: this.game.mapController.toSocketData(),
      kingdoms: this.game.kingdomsController.toSocketData(),
      entities: this.game.entitiesController.toSocketData(),
      market: this.game.marketController.toSocketData(),
      cycleTime: this.game.cycleTime,
      toCycleEnd: this.game.toCycleEnd,
      status: this.game.status,
      countdownTimer: this.game.countdownTimer,
    };
  }

  generateStartingMetadata() {
    return {
      timestap: Timer.getTimestamp(),
    };
  }

  onIncommingPlayCard = (player, data) => {
    const kingdom = this.kingdomForPlayer(player);

    const { cardId, targets } = data;

    const permit = this.game.cardEffectsManager.canBePlayedOnTarget(cardId, targets, kingdom);

    if (!permit.can) return this.sendCancelPlayCard(player, { cardId, message: permit.message });

    this.game.cardEffectsManager.playCard(cardId, targets, kingdom);
  };

  onIncommingTryRedraw = (player) => {
    const kingdom = this.kingdomForPlayer(player);
    kingdom.tryRedraw();
  };

  onIncommingChangeTarget = (player, data) => {
    const kingdom = this.kingdomForPlayer(player);
    kingdom.changeTarget(Point.fromObject(data));
  };

  onIncommingBuyCard = (player, data, callbackData) => {
    const kingdom = this.kingdomForPlayer(player);
    const { cardType } = data;

    const valid = this.game.marketController.validatePick(cardType, kingdom);
    if (!valid) return;

    this.game.marketController.payForCardType(cardType, kingdom);

    const card = this.game.cardsCreator.createCard({ type: cardType, owner: kingdom });

    kingdom.addToDiscard(card);

    player.connection.send({ type: 'host:afterCardBuy', cardId: card.id, callbackData });
  };

  destroy() {
    if (!this.peer) return;

    this.peer.destroy();
  }

  activate() {
    this.sendUpdates();
  }

  sendUpdates = () => {
    const publicData = this.filterEmptyEvents(this.publicEvents);
    const anyPublicData = !this.isObjectEmpty(publicData);

    const metadata = { timestap: Timer.getTimestamp() };

    this.connectedPlayers.forEach((player) => {
      const { connection, id } = player;

      const privateData = this.filterEmptyEvents(this.privateEvents[id]);
      const anyPrivateData = !this.isObjectEmpty(privateData);

      if (!anyPublicData && !anyPrivateData) return;

      connection.send({ type: 'host:update', publicData, privateData, metadata });
    });

    this.resetEvents();
  };

  sendCancelPlayCard = (player, data) => {
    const { connection } = player;
    connection.send({ type: 'host:cancelCardPlay', ...data });
  };

  isObjectEmpty(object) {
    return Object.keys(object).length === 0;
  }

  filterEmptyEvents(events) {
    const result = {};

    for (let key in events) {
      const event = events[key];
      if (this.isEventPresent(event)) result[key] = event;
    }

    return result;
  }

  isEventPresent(event) {
    if (!event) return false;
    if (Array.isArray(event) && event.length === 0) return false;

    return true;
  }

  onCreateEntity(entity) {
    if (!entity.socketable) return;
    this.publicEvents.newEntities.push(entity.toSocketData());
  }

  onDestroyEntity(entity) {
    if (!entity.socketable) return;
    this.publicEvents.destroyedEntities.push(entity.toSocketData());
  }

  onCardDraw(card) {
    const kingdom = card.getOwner();
    const playerId = kingdom.getPlayerId();

    if (this.isHostId(playerId)) return;

    this.privateEvents[playerId].drawnCards.push(card.toSocketData());
  }

  onDiscardCards(cards) {
    if (cards.length < 1) return;

    const kingdom = cards[0].getOwner();
    const playerId = kingdom.getPlayerId();

    if (this.isHostId(playerId)) return;

    cards.forEach((card) => {
      this.privateEvents[playerId].discardedCards.push(card.toSocketData());
    });
  }

  onReturnCards(cards) {
    if (cards.length < 1) return;

    const kingdom = cards[0].getOwner();
    const playerId = kingdom.getPlayerId();

    if (this.isHostId(playerId)) return;

    cards.forEach((card) => {
      this.privateEvents[playerId].returnedCards.push(card.toSocketData());
    });
  }

  onTrashCard(card) {
    const kingdom = card.getOwner();
    const playerId = kingdom.getPlayerId();

    if (this.isHostId(playerId)) return;

    this.privateEvents[playerId].trashedCards.push(card.toSocketData());
  }

  onCardUpdate(card) {
    const kingdom = card.getOwner();
    const playerId = kingdom.getPlayerId();

    if (this.isHostId(playerId)) return;

    this.privateEvents[playerId].updatedCards.push(card.toSocketData());
  }

  onShuffiling(kingdom) {
    const playerId = kingdom.getPlayerId();

    if (this.isHostId(playerId)) return;

    this.privateEvents[playerId].triggerShuffiling = true;
  }

  onKingdomUpdate(kingdom, params, isPublic) {
    const playerId = kingdom.getPlayerId();

    const kingdomId = kingdom.getId();

    if (isPublic) {
      this.publicEvents.kingdomUpdates.push({ id: kingdomId, ...params });
    } else {
      if (this.isHostId(playerId)) return;

      const currentUpdates = this.privateEvents[playerId].kingdomUpdate;
      this.privateEvents[playerId].kingdomUpdate = currentUpdates ? { ...currentUpdates, ...params } : params;
    }
  }

  onCycleEnd() {
    this.publicEvents.cycleEnd = true;
  }

  onAddEffect(effect) {
    const kingdom = effect.getKingdom();
    const playerId = kingdom.getPlayerId();

    if (this.isHostId(playerId)) return;

    this.privateEvents[playerId].addEffects.push(effect.toSocketData());
  }

  onUpdateEffect(effect) {
    const kingdom = effect.getKingdom();
    const playerId = kingdom.getPlayerId();

    if (this.isHostId(playerId)) return;

    this.privateEvents[playerId].updateEffects.push(effect.toSocketData());
  }

  onRemoveEffect(effect) {
    const kingdom = effect.getKingdom();
    const playerId = kingdom.getPlayerId();

    if (this.isHostId(playerId)) return;

    this.privateEvents[playerId].removeEffects.push(effect.toSocketData());
  }

  onGameEnd(message) {
    this.publicEvents.gameEnd = true;
    this.publicEvents.message = message;
  }

  onStatusChange(status) {
    this.publicEvents.status = status;
  }

  onFieldUpdate(field) {
    this.publicEvents.changedFields.push(field.toSocketData());
  }

  //

  kingdomForPlayer = (player) => {
    return this.game.kingdomsController.findKingdomByPlayerId(player.id);
  };

  ///

  pingServer() {
    const timer = new Timer();

    this.socket.emit('ping', () => {
      this.ping = timer.passed();
    });
  }

  getPing() {
    return this.ping;
  }
}
