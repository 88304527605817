import { CARD_EFFECT_TYPES } from 'game/consts/types/card-effect.js';

import { BaseCardEffect } from '../base.js';

export class UnplayableCardEffect extends BaseCardEffect {
  constructor(params) {
    super(params);

    this.type = CARD_EFFECT_TYPES.UNPLAYABLE;
  }

  canBePlayed() {
    return this.getCant('Unplayable');
  }
}
