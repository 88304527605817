import { TOOLTIP } from 'game/consts/tooltip.js';
import { CARD_EFFECT_TYPES } from 'game/consts/types/card-effect.js';
import { STRUCTURES } from 'game/consts/types/structures.js';

import { BaseCardEffect } from '../base.js';

export class FortificateAndPlaceReinforcementCardEffect extends BaseCardEffect {
  constructor(params) {
    super(params);

    this.type = CARD_EFFECT_TYPES.FORTIFICATE_AND_PLACE_REINFORCEMENT;
  }

  getTooltipTypes() {
    return [TOOLTIP.TYPES.FORTIFICATIONS, TOOLTIP.TYPES.REINFORCEMENT];
  }

  execute({ card, targets }) {
    const { fortifications } = card.getStats();

    const field = this.game.entitiesController.findById(targets.fieldId);
    this.game.structuresCreator.createStructure(field, STRUCTURES.REINFORCEMENT);
    field.changeFortifications(fortifications);
  }

  pickTarget({ kingdom }) {
    return kingdom.orderPickingFieldForBuilding();
  }

  canBePlayed({ kingdom }) {
    return this.checkForOwnEmptyFields(kingdom) || this.getCan();
  }

  canBePlayedOnTarget({ kingdom, targets }) {
    return this.checkOwnEmptyField(kingdom, targets) || this.getCan();
  }
}
