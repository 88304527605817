import { REINFORCEMENET_HITS } from '../../../consts/structures.js';
import { IMAGES } from 'game/consts/types/images.js';
import { STRUCTURES } from 'game/consts/types/structures.js';

import { BaseBuilding } from './base.js';

export class ReinforcementBuilding extends BaseBuilding {
  constructor(params) {
    super({ ...params, uncolorable: true });

    this.hp = REINFORCEMENET_HITS;

    this.type = STRUCTURES.REINFORCEMENT;
  }

  getSpriteName() {
    return IMAGES.STRUCTURES.REINFORCEMENT;
  }

  onDamage(power) {
    const damage = Math.min(power, this.hp);
    this.hp -= damage;

    if (this.hp <= 0) this.destroy();

    return damage;
  }
}
