import { HEXAGON_SIZE } from 'game/consts/layout.js';
import { HEADQUARTER_INACCURACY_REDUCTION } from 'game/consts/structures.js';
import { ENTITIES } from 'game/consts/types/entities.js';
import { IMAGES } from 'game/consts/types/images.js';
import { STRUCTURES } from 'game/consts/types/structures.js';
import { degreesToRadians, perFrameSpeed, perFrameTimer } from 'game/helpers/converters.js';
import { uniqueString } from 'game/helpers/data.js';
import { rotationToSpeeds } from 'game/helpers/rotation.js';

import { BaseCannon } from './cannons/base.js';

const FIRE = uniqueString();

export class HeadquarterBuilding extends BaseCannon {
  constructor(params) {
    super(params);

    this.tasks = [];

    this.range = HEXAGON_SIZE * 33;
    this.rotate = false;

    this.demolitionable = false;
    this.isCannon = false;

    this.type = STRUCTURES.HEADQUARTER;

    this.level = 1;
  }

  getSpriteName() {
    return IMAGES.STRUCTURES.HEADQUARTER;
  }

  activate(delta) {
    super.activate(delta);

    this.doTasks(delta);
  }

  doTasks(delta) {
    this.tasks.forEach((task) => {
      if (task.type === FIRE) {
        this.fireBulletsTaskDo(task, delta);
      }
    });

    this.tasks = this.tasks.filter((task) => !task.done);
  }

  multishot(params = {}) {
    const { target = this.owner.target, spread = 1 } = params;

    const rotationToTarget = target.clone().subtract(this.position).getRotation();

    const angleDiff = degreesToRadians(this.fireInaccuracy / shots);
    let angle = rotationToTarget + angleDiff / 2 - degreesToRadians(this.fireInaccuracy / 2);

    for (let i = 0; i < shots; i++) {
      this.game.entitiesController.create(ENTITIES.BULLET, {
        x: this.position.x,
        y: this.position.y,
        ...rotationToSpeeds(angle, perFrameSpeed(this.bulletSpeed)),
        lifeTime: perFrameTimer(this.range / this.bulletSpeed),
        owner: this.owner,
        ...params,
      });

      angle += angleDiff;
    }
  }

  fireBulletsTask(params = {}) {
    this.tasks.push({ ...params, type: FIRE, period: perFrameTimer(0.25), timer: 0 });
  }

  fireBulletsTaskDo(task, delta) {
    const { timer, period } = task;

    task.timer -= delta;
    if (timer >= 0) return false;

    this.fireBullets(task);

    task.timer = period;
    task.shots--;

    if (task.shots < 1) task.done = true;
  }

  increaseLevel(level = 1) {
    this.level += level;
  }

  onDestroy() {
    this.owner.onDestroyHeadquarter();
  }

  onCycleEnd() {
    const inaccuracy = this.fireInaccuracy * Math.pow(1 - HEADQUARTER_INACCURACY_REDUCTION, this.level);
    this.fireBullets({ power: this.level, spread: this.level, piercing: this.level, inaccuracy });
  }
}
