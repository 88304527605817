import { ENGLISH_NAME, ENGLISH_TEXTS } from 'game/languages/english.js';
import { POLISH_NAME, POLISH_TEXTS } from 'game/languages/polish.js';

export class TextsController {
  constructor(game) {
    this.game = game;

    this.texts = {
      [ENGLISH_NAME]: ENGLISH_TEXTS,
      [POLISH_NAME]: POLISH_TEXTS,
    };
  }

  getCardEffectDescription(type, stats) {
    return this.getText({ id: type, params: stats, prefix: 'CARD_EFFECT_DESCRIPTIONS' });
  }

  getCardTitle(type) {
    return this.getText({ id: type, prefix: 'CARD_TITLES' });
  }

  getTooltipText(type) {
    return this.getText({ id: type, prefix: 'TOOLTIP_TEXTS' }).replaceAll('<b>', '').replaceAll('</b>', '');
  }

  getInterfaceText(type) {
    return this.getText({ id: type, prefix: 'INTERFACE_TEXTS' });
  }

  getErrorText(type) {
    return this.getText({ id: type, prefix: 'ERROR_TEXTS' });
  }

  getText({ id, prefix, params }) {
    const texts = this.currentTexts();
    const entry = texts[prefix][id] || ENGLISH_TEXTS[prefix][id];

    return this.finalizeEntry(entry, params);
  }

  finalizeEntry(entry, params) {
    if (typeof entry == 'function') return entry(params);
    return entry || 'MISSING TEXT';
  }

  currentTexts() {
    return this.texts[this.game.settingsController.getLanguage()] || ENGLISH_TEXTS;
  }
}
