import { COLORS } from 'game/consts/colors.js';
import { CARD_EFFECT_TYPES } from 'game/consts/types/card-effect.js';
import { CARD_TYPES } from 'game/consts/types/card.js';
import { IMAGES } from 'game/consts/types/images.js';

export const ADDITIONAL_CARDS = {
  [CARD_TYPES.JUNK]: {
    type: CARD_TYPES.JUNK,
    image: IMAGES.CARD_TYPES.IMAGES.JUNK,
    color: COLORS.CARD.GRAY,
    effectTypes: [CARD_EFFECT_TYPES.DESTROY, CARD_EFFECT_TYPES.DRAIN],
    stats: {
      drain: 5,
    },
  },
};
