import { COLORS } from 'game/consts/colors.js';
import { CARD_EFFECT_TYPES } from 'game/consts/types/card-effect.js';
import { CARD_TYPES } from 'game/consts/types/card.js';
import { IMAGES } from 'game/consts/types/images.js';

export const FIXED_CARDS = {
  [CARD_TYPES.PLACE_POWER_STATION]: {
    type: CARD_TYPES.PLACE_POWER_STATION,
    cost: 4,
    image: IMAGES.CARD_TYPES.IMAGES.PLACE_POWER_STATION,
    effectTypes: [CARD_EFFECT_TYPES.DRAIN, CARD_EFFECT_TYPES.PLACE_POWER_STATION],
    stats: {
      drain: 8,
    },
    color: COLORS.CARD.GREEN,
  },
  [CARD_TYPES.ADD_ENERGY]: {
    type: CARD_TYPES.ADD_ENERGY,
    cost: 4,
    image: IMAGES.CARD_TYPES.IMAGES.ADD_ENERGY,
    effectTypes: [CARD_EFFECT_TYPES.ADD_ENERGY],
    stats: {
      energy: 2,
    },
    color: COLORS.CARD.GREEN,
  },
  [CARD_TYPES.TRASH_CARD]: {
    type: CARD_TYPES.TRASH_CARD,
    cost: 8,
    image: IMAGES.CARD_TYPES.IMAGES.TRASH_CARD,
    effectTypes: [CARD_EFFECT_TYPES.DESTROY, CARD_EFFECT_TYPES.TRASH_CARD],
    color: COLORS.CARD.GREEN,
  },
  [CARD_TYPES.PLACE_BASIC_CANNON]: {
    type: CARD_TYPES.PLACE_BASIC_CANNON,
    cost: 1,
    image: IMAGES.CARD_TYPES.IMAGES.PLACE_BASIC_CANNON,
    effectTypes: [CARD_EFFECT_TYPES.DRAIN, CARD_EFFECT_TYPES.PLACE_BASIC_CANNON],
    stats: {
      drain: 3,
    },
    color: COLORS.CARD.RED,
  },
  [CARD_TYPES.PLACE_SNIPER_CANNON]: {
    type: CARD_TYPES.PLACE_SNIPER_CANNON,
    cost: 2,
    image: IMAGES.CARD_TYPES.IMAGES.PLACE_SNIPER_CANNON,
    effectTypes: [CARD_EFFECT_TYPES.DRAIN, CARD_EFFECT_TYPES.PLACE_SNIPER_CANNON],
    stats: {
      drain: 9,
    },
    color: COLORS.CARD.RED,
  },
  [CARD_TYPES.FORTIFICATE_AND_PLACE_REINFORCEMENT]: {
    type: CARD_TYPES.FORTIFICATE_AND_PLACE_REINFORCEMENT,
    cost: 2,
    image: IMAGES.CARD_TYPES.IMAGES.FORTIFICATE_AND_PLACE_REINFORCEMENT,
    effectTypes: [CARD_EFFECT_TYPES.DESTROY, CARD_EFFECT_TYPES.FORTIFICATE_AND_PLACE_REINFORCEMENT],
    stats: {
      fortifications: 12,
    },
    color: COLORS.CARD.BLUE,
  },
  [CARD_TYPES.PLACE_FORGE]: {
    type: CARD_TYPES.PLACE_FORGE,
    cost: 1,
    image: IMAGES.CARD_TYPES.IMAGES.PLACE_FORGE,
    effectTypes: [CARD_EFFECT_TYPES.DRAIN, CARD_EFFECT_TYPES.PLACE_FORGE],
    stats: {
      drain: 4,
    },
    color: COLORS.CARD.BLUE,
  },
  [CARD_TYPES.IMPROVE_HEADQUARTER]: {
    type: CARD_TYPES.IMPROVE_HEADQUARTER,
    cost: 100,
    image: IMAGES.CARD_TYPES.IMAGES.IMPROVE_HEADQUARTER,
    effectTypes: [CARD_EFFECT_TYPES.DESTROY, CARD_EFFECT_TYPES.IMPROVE_HEADQUARTER],
    color: COLORS.CARD.RED,
    stats: {
      level: 1,
    },
  },
};
