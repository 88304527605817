import { TOOLTIP } from 'game/consts/tooltip.js';
import { CARD_EFFECT_TYPES } from 'game/consts/types/card-effect.js';
import { STRUCTURES } from 'game/consts/types/structures.js';

import { BaseCardEffect } from '../base.js';

export class PlacePowerStationCardEffect extends BaseCardEffect {
  constructor(params) {
    super(params);

    this.type = CARD_EFFECT_TYPES.PLACE_POWER_STATION;
  }

  getTooltipTypes() {
    return [TOOLTIP.TYPES.POWER_STATION];
  }

  execute({ targets }) {
    const field = this.game.entitiesController.findById(targets.fieldId);
    this.game.structuresCreator.createStructure(field, STRUCTURES.POWER_STATION);
  }

  pickTarget({ kingdom }) {
    return kingdom.orderPickingFieldForBuilding();
  }

  canBePlayed({ kingdom }) {
    return this.checkForOwnEmptyFields(kingdom) || this.getCan();
  }

  canBePlayedOnTarget({ kingdom, targets }) {
    return this.checkOwnEmptyField(kingdom, targets) || this.getCan();
  }
}
