import { RANDOM_CANNON_INACCURACY } from 'game/consts/structures.js';
import { IMAGES } from 'game/consts/types/images.js';
import { STRUCTURES } from 'game/consts/types/structures.js';

import { BaseCannon } from './base.js';

export class RandomCannon extends BaseCannon {
  constructor(params) {
    super(params);

    this.fireInaccuracy = RANDOM_CANNON_INACCURACY;
    this.rotate = false;

    this.type = STRUCTURES.RANDOM_CANNON;
  }

  getSpriteName() {
    return IMAGES.STRUCTURES.RANDOM_CANNON;
  }
}
