import { TOOLTIP } from 'game/consts/tooltip.js';
import { CARD_EFFECT_TYPES } from 'game/consts/types/card-effect.js';

import { BaseCardEffect } from '../base.js';

export class FortificateHeadquarterAndAdjacentsCardEffect extends BaseCardEffect {
  constructor(params) {
    super(params);

    this.type = CARD_EFFECT_TYPES.FORTIFICATE_HEADQUARTER_AND_ADJACENTS;
  }

  getTooltipTypes() {
    return [TOOLTIP.TYPES.FORTIFICATIONS];
  }

  execute({ kingdom, card }) {
    const { fortifications } = card.getStats();

    const headquarter = kingdom.getHeadquarter();
    const mainField = headquarter.getFieldByHex();

    const fields = mainField.allAdjacentFields().filter((field) => field && field.ownedBy(kingdom));
    fields.push(mainField);

    for (let i = 0; i < fields.length; i++) {
      fields[i].changeFortifications(fortifications);
    }
  }
}
