import { TOP_INFO_SIZE, TOP_INFO_TOP } from 'game/consts/layout.js';

import { InfoText } from './info-text.js';

export class InfoUnderBar extends InfoText {
  constructor(params) {
    super({ ...params, size: TOP_INFO_SIZE });
  }

  setPosition() {
    this.sprites.container.x = window.innerWidth / 2;
    this.sprites.container.y = TOP_INFO_TOP + this.size / 2;
  }
}
