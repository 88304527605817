import { DISCARD_BOTTOM, DISCARD_HEIGHT, DISCARD_RIGHT, DISCARD_WIDTH } from 'game/consts/layout.js';
import { Z_INDEX } from 'game/consts/z-index.js';
import { Point } from 'game/data-types/point.js';

import { InterfaceBase } from '../interface-base.js';

export class Discard extends InterfaceBase {
  constructor(params) {
    super(params);

    this.kingdom = params.kingdom;
    this.parent = params.parent;

    this.updatePosition();
  }

  updatePosition() {
    this.position = new Point(
      window.innerWidth - DISCARD_WIDTH / 2 - DISCARD_RIGHT,
      window.innerHeight - DISCARD_HEIGHT / 2 - DISCARD_BOTTOM
    );
  }

  onInitialize() {
    const cards = this.kingdom.getDiscard();

    this.updateCardsInterfaces(cards);
    this.recalculateCardsPositions(cards, { onInitialize: true });
  }

  onChange() {
    const cards = this.kingdom.getDiscard();

    this.updateCardsInterfaces(cards);
    this.recalculateCardsZIndexes(cards);
    this.recalculateCardsPositions(cards);
  }

  updateCardsInterfaces(cards) {
    cards.forEach((card) => {
      card.showFront();
      card.setContainer(this);
      card.setInteractive(false);
      card.expandIfNecessary();
    });
  }

  recalculateCardsZIndexes(cards) {
    const zIndexOffset = 1 / cards.length;

    cards.forEach((card, index) => {
      card.setZIndex(Z_INDEX.DISCARD + index * zIndexOffset);
    });
  }

  recalculateCardsPositions(cards, params = {}) {
    cards.forEach((card) => {
      const newPosition = this.position.clone();

      if (params.onInitialize) return card.setPosition(newPosition);
      if (params.onResize) return card.dynamicSetPosition(newPosition);

      card.setDestination(newPosition);
    });
  }

  afterResize() {
    const cards = this.kingdom.getDiscard();

    this.updatePosition();
    this.recalculateCardsPositions(cards, { onResize: true });
  }
}
