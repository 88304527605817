import { Z_INDEX } from 'game/consts/z-index.js';
import { Point } from 'game/data-types/point.js';
import { InterfaceBase } from 'game/interface/interface-base.js';
import * as PIXI from 'pixi.js';

export class MapScrolling extends InterfaceBase {
  constructor(params) {
    super(params);

    this.lastMousePosition = null;
    this.isScrolling = false;

    this.createSprite();
    this.setPosition();
    this.registerEventListeners();
  }

  setPosition() {
    this.position = new Point(0, 0);

    this.spritesContainer.x = this.position.x;
    this.spritesContainer.y = this.position.y;
  }

  createSprite() {
    this.spritesContainer = new PIXI.Container();

    this.spritesContainer.hitArea = new PIXI.Rectangle(0, 0, window.innerWidth, window.innerHeight);
    this.spritesContainer.eventMode = 'static';
    this.spritesContainer.zIndex = Z_INDEX.MAP_SCORLLING_INACTIVE;

    this.spritesContainer.on('rightdown', this.onMouseDown);
    this.spritesContainer.on('touchstart', this.onMouseDown);
    this.spritesContainer.on('rightup', this.onMouseUp);
    this.spritesContainer.on('touchend', this.onMouseUp);
    this.spritesContainer.on('pointerout', this.onMouseUp);

    this.registerSprite(this.spritesContainer);
  }

  registerEventListeners() {
    this.game.eventsController.addListener('mouseMove', this);
    this.game.eventsController.addListener('redirectedMouseRightDown', this);
  }

  onRedirectedMouseRightDown = () => {
    this.startMovement();
  };

  onMouseDown = () => {
    this.startMovement();
  };

  onMouseUp = () => {
    this.game.eventsController.runEvent('mouseRightUp');
    this.endMovement();
  };

  onMouseOut = () => {
    this.endMovement();
  };

  startMovement() {
    this.spritesContainer.cursor = 'grab';
    this.isScrolling = true;
    this.spritesContainer.zIndex = Z_INDEX.MAP_SCORLLING_ACTIVE;
  }

  endMovement() {
    this.spritesContainer.cursor = 'default';
    this.isScrolling = false;
    this.spritesContainer.zIndex = Z_INDEX.MAP_SCORLLING_INACTIVE;
  }

  afterResize() {
    this.spritesContainer.hitArea = new PIXI.Rectangle(0, 0, window.innerWidth, window.innerHeight);
  }

  onMouseMove = ({ position }) => {
    if (this.isScrolling) {
      const difference = this.lastMousePosition.difference(position);

      this.game.interfaceController.moveScreenByPoint(difference);
    }

    this.lastMousePosition = position.clone();
  };

  destroy() {
    super.destroy();
    this.removeSprite(this.spriteContainer);
  }
}
