import { ERROR_TYPES } from 'game/consts/types/errors.js';

export class BaseCardEffect {
  constructor(params) {
    this.game = params.game;
  }

  getDescription(stats) {
    return this.game.textsController.getCardEffectDescription(this.type, stats);
  }

  getTooltipTypes() {
    return [];
  }

  canBePlayed() {
    return this.getCan();
  }

  canBePlayedOnTarget() {
    return this.getCan();
  }

  getCan() {
    return { can: true };
  }

  getCant(message) {
    return { can: false, message };
  }

  checkForHeadquarter(kingdom) {
    if (!kingdom.getHeadquarter()) return this.getCant(this.game.textsController.getErrorText(ERROR_TYPES.NO_HEADQUARTER));
    return null;
  }

  checkForOwnEmptyFields(kingdom) {
    const ownEmptyFields = this.game.mapController.getAllFields().filter((field) => field.ownedBy(kingdom) && field.empty());

    if (ownEmptyFields.length === 0) {
      return this.getCant(this.game.textsController.getErrorText(ERROR_TYPES.NO_EMPTY_FIELDS));
    }

    return null;
  }

  checkOwnEmptyField(kingdom, targets) {
    const field = this.game.entitiesController.findById(targets.fieldId);

    if (!field.ownedBy(kingdom)) return this.getCant(this.game.textsController.getErrorText(ERROR_TYPES.NOT_YOUR_FIELD));
    if (!field.empty()) return this.getCant(this.game.textsController.getErrorText(ERROR_TYPES.FIELD_NOT_EMPTY));
  }

  pickTarget() {
    return {};
  }

  execute() {}

  afterExecute() {}
}
