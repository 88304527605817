import { ENTITIES } from 'game/consts/types/entities.js';
import { IMAGES } from 'game/consts/types/images.js';

import { EntityBase } from './entity-base.js';

export class Wall extends EntityBase {
  constructor(params) {
    super(params);

    this.hexPosition = params.hexPosition;
    this.position = this.hexPosition.toPoint();

    this.type = ENTITIES.WALL;

    if (this.interfacePart) this.createSprite();
  }

  createSprite() {
    this.sprite = this.game.texturesManager.createStandardSprite(IMAGES.ENTITIES.WALL);

    this.registerMapSprite(this.sprite);

    this.setSpritePosition();
  }

  setSpritePosition() {
    this.sprite.x = this.position.x;
    this.sprite.y = this.position.y;
  }

  isType(type) {
    return this.type === type;
  }

  ownedBy() {
    return false;
  }

  getDamage() {}

  isCollisionable() {
    return true;
  }

  allAdjacentFields() {
    const { col, row } = this.hexPosition;

    return [
      this.game.mapController.getFieldByCoords(col - 1, row),
      this.game.mapController.getFieldByCoords(col + (row % 2 ? -1 : 1), row - 1),
      this.game.mapController.getFieldByCoords(col, row - 1),
      this.game.mapController.getFieldByCoords(col + 1, row),
      this.game.mapController.getFieldByCoords(col + (row % 2 ? -1 : 1), row + 1),
      this.game.mapController.getFieldByCoords(col, row + 1),
    ];
  }

  getPosition() {
    return this.position.clone();
  }

  activate() {}

  onCycleEnd() {}

  toSocketData() {
    return { id: this.id, type: this.type };
  }
}
