import { CARD_TYPES } from '../../consts/types/card.js';
import { CARD_EFFECT_TYPES } from 'game/consts/types/card-effect.js';

import { BaseCardEffect } from '../base.js';

export class AddJunkCardEffect extends BaseCardEffect {
  constructor(params) {
    super(params);

    this.type = CARD_EFFECT_TYPES.ADD_JUNK;
  }

  execute({ kingdom, card }) {
    const { quantity } = card.getStats();

    for (let i = 0; i < quantity; i++) {
      const newCard = this.game.cardsCreator.createCard({ type: CARD_TYPES.JUNK, owner: kingdom });
      kingdom.addToReceive(newCard);
    }
  }
}
