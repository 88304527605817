import { CARD_EFFECT_TYPES } from 'game/consts/types/card-effect.js';

import { BaseCardEffect } from './base.js';

export class ImproveHeadquarterCardEffect extends BaseCardEffect {
  constructor(params) {
    super(params);

    this.type = CARD_EFFECT_TYPES.IMPROVE_HEADQUARTER;
  }

  execute({ kingdom, card }) {
    const { level } = card.getStats();
    kingdom.getHeadquarter().increaseLevel(level);
  }
}
