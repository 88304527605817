import { CARD_TYPES } from 'game/consts/types/card.js';

import { EnemyKingdom } from '../enemy.js';

export class VoiderKingdom extends EnemyKingdom {
  onPlayTimer() {
    this.tryPlayRandom();
  }

  startingCards() {
    const cards = [
      this.createCard({ type: CARD_TYPES.FORTIFICATE_HEADQUARTER_AND_ADJACENTS }),
      this.createCard({ type: CARD_TYPES.ADD_ENERGY }),
      this.createCard({ type: CARD_TYPES.ADD_ENERGY }),
      this.createCard({ type: CARD_TYPES.ADD_ENERGY }),
      this.createCard({ type: CARD_TYPES.FORTIFICATE_AND_PLACE_REINFORCEMENT }),
      this.createCard({ type: CARD_TYPES.FORTIFICATE_AND_PLACE_REINFORCEMENT }),
      this.createCard({ type: CARD_TYPES.FORTIFICATE_AND_PLACE_REINFORCEMENT }),
      this.createCard({ type: CARD_TYPES.FORTIFICATE_AND_PLACE_REINFORCEMENT }),
      this.createCard({ type: CARD_TYPES.FORTIFICATE_AND_PLACE_REINFORCEMENT }),
      this.createCard({ type: CARD_TYPES.PLACE_FORGE }),
      this.createCard({ type: CARD_TYPES.PLACE_POWER_STATION }),
      this.createCard({ type: CARD_TYPES.SPREAD_SHOT }),
      this.createCard({ type: CARD_TYPES.FORTIFICATE_HEADQUARTER_AND_ADJACENTS }),
      this.createCard({ type: CARD_TYPES.INCREASE_CARD_LIMIT }),
      this.createCard({ type: CARD_TYPES.INCREASE_CARD_LIMIT }),
    ];

    if (this.difficulty >= 2) cards.push(this.createCard({ type: CARD_TYPES.JUNK }));
    if (this.difficulty >= 2) cards.push(this.createCard({ type: CARD_TYPES.JUNK }));
    if (this.difficulty >= 2) cards.push(this.createCard({ type: CARD_TYPES.JUNK }));
    if (this.difficulty >= 2) cards.push(this.createCard({ type: CARD_TYPES.ADD_ENERGY }));
    if (this.difficulty >= 2) cards.push(this.createCard({ type: CARD_TYPES.INCREASE_CARD_LIMIT }));
    if (this.difficulty >= 2) cards.push(this.createCard({ type: CARD_TYPES.INCREASE_CARD_LIMIT }));

    if (this.difficulty >= 3) cards.push(this.createCard({ type: CARD_TYPES.ADD_ENERGY }));
    if (this.difficulty >= 3) cards.push(this.createCard({ type: CARD_TYPES.ADD_ENERGY }));
    if (this.difficulty >= 3) cards.push(this.createCard({ type: CARD_TYPES.INCREASE_CARD_LIMIT }));
    if (this.difficulty >= 3) cards.push(this.createCard({ type: CARD_TYPES.INCREASE_CARD_LIMIT }));

    return cards;
  }

  after60Cycles() {
    this.discard.push(this.createCard({ type: CARD_TYPES.DESTROY_FIELD }));
    this.discard.push(this.createCard({ type: CARD_TYPES.DESTROY_FIELD }));
    this.discard.push(this.createCard({ type: CARD_TYPES.DESTROY_FIELD }));

    if (this.difficulty >= 2) this.discard.push(this.createCard({ type: CARD_TYPES.DESTROY_FIELD }));
    if (this.difficulty >= 2) this.discard.push(this.createCard({ type: CARD_TYPES.DESTROY_FIELD }));

    if (this.difficulty >= 3) this.discard.push(this.createCard({ type: CARD_TYPES.DESTROY_FIELD }));
    if (this.difficulty >= 3) this.discard.push(this.createCard({ type: CARD_TYPES.DESTROY_FIELD }));
  }
}
