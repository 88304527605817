import { TOOLTIP } from 'game/consts/tooltip.js';
import { CARD_EFFECT_TYPES } from 'game/consts/types/card-effect.js';
import { addTemporaryToEffects } from 'game/helpers/effects-helpers.js';

import { BaseCardEffect } from '../base.js';

export class TrashAndCopyCardCardEffect extends BaseCardEffect {
  constructor(params) {
    super(params);

    this.type = CARD_EFFECT_TYPES.TRASH_AND_COPY_CARD;
  }

  getTooltipTypes() {
    return [TOOLTIP.TYPES.PERSISTENT];
  }

  execute = async ({ kingdom, card, targets }) => {
    const { copies } = card.getStats();

    const targetCard = this.game.entitiesController.findById(targets.cardId);

    const newCardData = { effectTypes: [], ...targetCard.getData() };
    addTemporaryToEffects(newCardData.effectTypes);

    for (let i = 0; i < copies; i++) {
      const newCard = this.game.cardsCreator.createCustomCard(newCardData, kingdom);
      kingdom.addToReceive(newCard);
    }

    targetCard.orderTrashing();
  };

  pickTarget({ kingdom }) {
    return kingdom.orderPickingHandCardToTrashAndCopy();
  }

  canBePlayed({ kingdom, card }) {
    const cards = kingdom.getHand();

    const persistentCards = cards.filter((filteredCard) => filteredCard.isPersistent() && filteredCard.getId() !== card.getId());

    if (persistentCards.length === 0) {
      return this.getCant('No cards to pick!');
    }

    return this.getCan();
  }

  canBePlayedOnTarget({ targets }) {
    const targetCard = this.game.entitiesController.findById(targets.cardId);

    if (!targetCard.isPersistent()) return this.getCant('This cart is not persistent.');

    return this.getCan();
  }
}
