import { TOOLTIP } from 'game/consts/tooltip.js';
import { CARD_EFFECT_TYPES } from 'game/consts/types/card-effect.js';
import { randomElement } from 'game/helpers/array.js';

import { BaseCardEffect } from '../base.js';

export class ReduceDrainRandomlyCardEffect extends BaseCardEffect {
  constructor(params) {
    super(params);

    this.type = CARD_EFFECT_TYPES.REDUCE_DRAIN_RANDOMLY;
  }

  getTooltipTypes() {
    return [TOOLTIP.TYPES.DRAIN];
  }

  execute({ kingdom, card }) {
    const { drainReduction } = card.getStats();

    const cards = kingdom.getDeck();

    const potentialTargets = cards.filter((searchedCard) => {
      const searchedCardData = searchedCard.getData();
      if (searchedCardData.type === card.getData().type) return false;
      return searchedCardData.effectTypes.includes(CARD_EFFECT_TYPES.DRAIN);
    });

    const targetCard = randomElement(potentialTargets);

    targetCard.increaseStat('drain', -drainReduction);

    if (!targetCard.getStats().drain > 0) {
      targetCard.removeEffect(CARD_EFFECT_TYPES.DRAIN);
    }
  }

  canBePlayed({ kingdom, card }) {
    const cards = kingdom.getDeck();

    const firstTarget = cards.find((searchedCard) => {
      const searchedCardData = searchedCard.getData();
      if (searchedCardData.type === card.getData().type) return false;
      return searchedCardData.effectTypes.includes(CARD_EFFECT_TYPES.DRAIN);
    });

    if (!firstTarget) return this.getCant('No differently named cards with drain!');

    return this.getCan();
  }
}
