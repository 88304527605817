import { createSlice } from '@reduxjs/toolkit';
import Config from 'helpers/config';
import io from 'socket.io-client';

import { setPlayerData } from 'store/main/slice';

const websocketReducer = createSlice({
  name: 'websocket',
  initialState: {
    connection: null,
    isConnected: false,
  },
  reducers: {
    setConnection(state, action) {
      state.connection = action.payload.connection;
    },
    setIsConnected(state, action) {
      state.isConnected = action.payload.isConnected;
    },
    setAuthorizated(state, action) {
      state.authorizated = action.payload.authorizated;
    },
  },
});

export const { setConnection, setAuthorizated, setIsConnected } = websocketReducer.actions;

export function setupWebsocketConnection() {
  return async (dispatch, getStore) => {
    const connection = io.connect(Config.serverUrl);

    dispatch(setConnection({ connection }));

    const { main } = getStore();
    const { player } = main;
    const { token, id } = player;

    connection.on('connect', () => {
      dispatch(setIsConnected({ isConnected: true }));

      connection.emit('game:authorizatePlayer', { token, id }, (response) => {
        dispatch(setPlayerData({ player: response.player }));
        dispatch(setAuthorizated({ authorizated: true }));
      });
    });

    connection.on('disconnect', () => {
      dispatch(setIsConnected({ isConnected: false }));
    });
  };
}

export function createPreparation(args) {
  return async (dispatch, getStore) => {
    const { connection } = getStore().websocket;

    const { params, onSuccess, onFailure } = args;

    connection.emit('game:createPreparation', params, (response) => {
      if (response.errorMessage) return onFailure && onFailure(response);
      onSuccess(response);
    });
  };
}

export default websocketReducer.reducer;
