import { HEXAGON_RADIUS } from '../../consts/layout.js';
import { IMAGES } from 'game/consts/types/images.js';
import { perFrameSpeed, perFrameTimer } from 'game/helpers/converters.js';
import { random } from 'game/helpers/random.js';

import { BaseParticle } from './base.js';

export class HexagonalParticle extends BaseParticle {
  constructor(params) {
    super(params);

    this.lifetime = perFrameTimer(0.5);
    this.lifetimeTimer = this.lifetime;

    this.speed = perFrameSpeed(HEXAGON_RADIUS);
    this.angle = random(0, 360);
    this.imageAngle = random(0, 360);

    this.color = params.color;
    this.imageType = IMAGES.PARTICLES.HEXAGONAL;

    this.initializator(params);
  }
}
