import { CARD_EFFECT_TYPES } from 'game/consts/types/card-effect.js';

import { TOOLTIP } from '../consts/tooltip.js';

import { BaseCardEffect } from './base.js';

export class ActivateCannonsCardEffect extends BaseCardEffect {
  constructor(params) {
    super(params);

    this.type = CARD_EFFECT_TYPES.ACTIVATE_CANNONS;
  }

  getTooltipTypes() {
    return [TOOLTIP.TYPES.ACTIVATE];
  }

  execute({ kingdom, card }) {
    const { times } = card.getStats();

    const cannons = this.game.mapController
      .getAllFields()
      .filter((field) => field.ownedBy(kingdom) && !field.empty() && field.structure.isCannon)
      .map((field) => field.structure);

    cannons.forEach((cannon) => {
      for (let i = times; i > 0; i--) {
        cannon.onCycleEnd();
      }
    });
  }
}
