import { HEXAGON_SIZE } from 'game/consts/layout.js';
import { IMAGES } from 'game/consts/types/images.js';
import { STRUCTURES } from 'game/consts/types/structures.js';

import { BaseStructure } from './base.js';

export class MineStructure extends BaseStructure {
  constructor(params) {
    super(params);

    this.detonated = false;

    this.power = 3;
    this.range = 3;

    this.type = STRUCTURES.MINE;
  }

  getSpriteName() {
    return IMAGES.STRUCTURES.MINE;
  }

  onDamage() {
    this.explode();
    return 1;
  }

  explode() {
    if (this.detonated) return;
    this.detonated = true;

    this.game.mapController.everyMapField((field) => {
      if (field.position.distance(this.position) <= HEXAGON_SIZE * this.range && !field.ownedBy(this.owner)) {
        field.getDamage(this.power, this.owner);
      }
    });

    this.game.eventsController.runEvent('mineExplosion', { mine: this });

    this.destroy();
  }
}
