import { RECEIVE_BOTTOM, RECEIVE_HEIGHT, RECEIVE_RIGHT, RECEIVE_WIDTH } from 'game/consts/layout.js';
import { Z_INDEX } from 'game/consts/z-index.js';
import { Point } from 'game/data-types/point.js';

import { InterfaceBase } from '../interface-base.js';

export class Receive extends InterfaceBase {
  constructor(params) {
    super(params);

    this.kingdom = params.kingdom;
    this.parent = params.parent;

    this.updatePosition();
  }

  updatePosition() {
    this.position = new Point(
      window.innerWidth - RECEIVE_WIDTH / 2 - RECEIVE_RIGHT,
      window.innerHeight - RECEIVE_HEIGHT / 2 - RECEIVE_BOTTOM
    );
  }

  onInitialize() {
    const cards = this.kingdom.getReceive();

    this.updateCardsInterfaces(cards);
    this.recalculateCardsPositions(cards);
  }

  onChange() {
    const cards = this.kingdom.getReceive();

    this.updateCardsInterfaces(cards);
    this.recalculateZIndex(cards);
    this.recalculateCardsPositions(cards);
  }

  updateCardsInterfaces(cards) {
    cards.forEach((card) => {
      card.hide(this);
      card.setContainer(this);
      card.setInteractive(false);
      card.shrinkInstantly();
    });
  }

  recalculateZIndex(cards) {
    const zIndexOffset = 1 / cards.length;

    cards.forEach((card, index) => {
      card.setZIndex(Z_INDEX.DISCARD + index * zIndexOffset);
    });
  }

  recalculateCardsPositions(cards) {
    cards.forEach((card) => {
      const newPosition = this.position.clone();

      card.setPosition(newPosition);
    });
  }

  afterResize() {
    const cards = this.kingdom.getReceive();

    this.updatePosition();
    this.recalculateZIndex(cards);
    this.recalculateCardsPositions(cards);
  }
}
