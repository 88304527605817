import { EntityBase } from 'game/entities/entity-base.js';
import { Container } from 'pixi.js';

export class InterfaceBase extends EntityBase {
  constructor(params) {
    super(params);

    this.width = 0;
    this.height = 0;

    this.unpauseable = true;

    this.sprites = {};
  }

  setPosition(x, y, params = {}) {
    const { addHalfHeight, addHalfWidth } = params;

    this.sprites.container.x = x + (addHalfWidth ? this.width / 2 : 0);
    this.sprites.container.y = y + (addHalfHeight ? this.height / 2 : 0);
  }

  setParentContainer(container) {
    container.addChild(this.sprites.container);
  }

  createContainer() {
    this.sprites.container = new Container();
    this.sprites.container.zIndex = this.zIndex || 1;
    this.sprites.container.sortableChildren = true;
  }

  setVisibility(value) {
    this.sprites.container.visible = value;
  }

  setZIndex(zIndex) {
    this.zIndex = zIndex;
    this.sprites.container.zIndex = zIndex;
  }

  getContainerHeight() {
    return this.sprites.container.height;
  }

  getContainerWidth() {
    return this.sprites.container.width;
  }

  getHeight() {
    return this.height;
  }

  getWidth() {
    return this.width;
  }

  getContainer() {
    return this.sprites.container;
  }

  destroySprites() {
    for (const [_key, sprite] of Object.entries(this.sprites)) {
      sprite.destroy();
    }
  }

  destroy() {
    this.destroySprites();
    super.destroy();
  }
}
