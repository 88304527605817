import { IMAGES } from 'game/consts/types/images.js';
import { Z_INDEX } from 'game/consts/z-index.js';

import { HexButtonSprite } from './hex.js';

export class FixedCardsButton extends HexButtonSprite {
  constructor(params) {
    super({ ...params, iconType: IMAGES.INTERFACE.BUTTONS.ICONS.FIXED_CARDS, zIndex: Z_INDEX.CARDS_BUTTONS });

    this.setupSprites(params);
  }

  onClick = () => {
    this.game.eventsController.runEvent('fixedCardsButtonClick');
  };

  updatePosition = () => {
    const x = window.innerWidth - this.getWidth() / 2;
    const y = window.innerHeight / 2 - this.getHeight() * 1.35;

    this.setPosition(x, y);
  };
}
