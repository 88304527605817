import { CARD_EFFECT_TYPES } from 'game/consts/types/card-effect.js';

import { BaseCardEffect } from './base.js';

export class AddEnergyCardEffect extends BaseCardEffect {
  constructor(params) {
    super(params);

    this.type = CARD_EFFECT_TYPES.ADD_ENERGY;
  }

  execute({ kingdom, card }) {
    const { energy } = card.getStats();
    kingdom.changeEnergy(energy);
  }
}
