import { HEXAGON_SIZE } from 'game/consts/layout.js';
import { IMAGES } from 'game/consts/types/images.js';
import { STRUCTURES } from 'game/consts/types/structures.js';

import { BaseCannon } from './base.js';

export class SniperCannon extends BaseCannon {
  constructor(params) {
    super(params);

    this.fireInaccuracy = 0;
    this.range = HEXAGON_SIZE * 33;

    this.type = STRUCTURES.SNIPER_CANNON;
  }

  getSpriteName() {
    return IMAGES.STRUCTURES.SNIPER_CANNON;
  }
}
