import { CARD_TYPES } from 'game/consts/types/card.js';

import { EnemyKingdom } from '../enemy.js';

export class SniperKingdom extends EnemyKingdom {
  onPlayTimer() {
    this.tryPlayRandom();
  }

  startingCards() {
    const cards = [
      this.createCard({ type: CARD_TYPES.SNIPER_FIRE }),
      this.createCard({ type: CARD_TYPES.SNIPER_FIRE }),
      this.createCard({ type: CARD_TYPES.SNIPER_FIRE }),
      this.createCard({ type: CARD_TYPES.SNIPER_FIRE }),
      this.createCard({ type: CARD_TYPES.SPREAD_SHOT }),
      this.createCard({ type: CARD_TYPES.FORTIFICATE_HEADQUARTER_AND_ADJACENTS }),
      this.createCard({ type: CARD_TYPES.RANDOM_FORTIFICATIONS }),
      this.createCard({ type: CARD_TYPES.FORTIFICATE_HEADQUARTER_AND_ADJACENTS }),
    ];

    if (this.difficulty >= 2) cards.push(this.createCard({ type: CARD_TYPES.DRAW_CARDS }));
    if (this.difficulty >= 2) cards.push(this.createCard({ type: CARD_TYPES.DRAW_CARDS }));
    if (this.difficulty >= 2) cards.push(this.createCard({ type: CARD_TYPES.FORTIFICATE_HEADQUARTER_AND_ADJACENTS }));
    if (this.difficulty >= 2) cards.push(this.createCard({ type: CARD_TYPES.SNIPER_FIRE }));
    if (this.difficulty >= 2) cards.push(this.createCard({ type: CARD_TYPES.SNIPER_FIRE }));
    if (this.difficulty >= 2) cards.push(this.createCard({ type: CARD_TYPES.SNIPER_FIRE }));
    if (this.difficulty >= 2) cards.push(this.createCard({ type: CARD_TYPES.RANDOM_FORTIFICATIONS }));

    if (this.difficulty >= 3) cards.push(this.createCard({ type: CARD_TYPES.DRAW_CARDS }));
    if (this.difficulty >= 3) cards.push(this.createCard({ type: CARD_TYPES.DRAW_CARDS }));
    if (this.difficulty >= 3) cards.push(this.createCard({ type: CARD_TYPES.SNIPER_FIRE }));
    if (this.difficulty >= 3) cards.push(this.createCard({ type: CARD_TYPES.SNIPER_FIRE }));
    if (this.difficulty >= 3) cards.push(this.createCard({ type: CARD_TYPES.SNIPER_FIRE }));
    if (this.difficulty >= 3) cards.push(this.createCard({ type: CARD_TYPES.SNIPER_FIRE }));
    if (this.difficulty >= 3) cards.push(this.createCard({ type: CARD_TYPES.SNIPER_FIRE }));
    if (this.difficulty >= 3) cards.push(this.createCard({ type: CARD_TYPES.FORTIFICATE_AROUND_HEADQUARTER }));

    return cards;
  }
}
